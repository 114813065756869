import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./RuleItemDisplay.module.css";

type Props = {
  rules: BlockRule[];
  setRules: (rules: BlockRule[]) => void;
  setChangedRule: (cond: boolean) => void;
};

export default function RuleItemDisplay({ rules, setRules, setChangedRule }: Props) {
  const handleDeleteRegle = (index: number) => {
    setRules(rules.filter((_, i) => i !== index));
    setChangedRule(true);
  };
  return (
    <>
      {rules.map((rule, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.containerRegle} key={index}>
          <div className={styles.infoRegle}>
            <h1>Lumière {rule.blockNumber}</h1>
            <h2>{rule.drugItems.map((item) => item.name).join(" | ")}</h2>
            <p>
              <em>{rule.ruleDesc}</em>
            </p>
          </div>
          <IconButton sx={{ color: "red" }} onClick={() => handleDeleteRegle(index)}>
            <Tooltip placement="right" title="Supprimer cette règle">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </div>
      ))}
    </>
  );
}
