import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import styles from "./ListeOralPresciptionsInjectables.module.css";
import AppContext from "../../../AppContext";
import deleteOralPrescriptions from "../../../hooks/deleteOralPrescriptions";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function ListeOralPresciptions({ isOpen, setIsOpen }: Props) {
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(true);
  const [checkboxStates, setCheckboxStates] = useState<boolean[]>([]);

  const { oralPrescriptionsData, token, sessionId, setUpdateOralPrescriptionsData } =
    useContext(AppContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsButtonsDisabled(true);

    if (checkboxStates.length > 0) {
      const isAnyChecked = checkboxStates.some((state) => state);
      setIsButtonsDisabled(!isAnyChecked);
    }
  }, [checkboxStates]);

  useEffect(() => {
    // Mettre à jour l'état initial des cases à cocher lors du changement de prescriptions
    setCheckboxStates(oralPrescriptionsData.map(() => false));
  }, [oralPrescriptionsData]);

  useEffect(() => {
    // Vérifier si toutes les cases à cocher sont cochées
    const allChecked = Object.values(checkboxStates).every((state) => state);
    setIsChecked(allChecked);
  }, [checkboxStates]);

  const handleDelete = () => {
    setOpen(false);
    const selectedPrescriptions: number[] = [];

    checkboxStates.forEach((state, index) => {
      if (state) {
        selectedPrescriptions.push(oralPrescriptionsData[index].id);
      }
    });

    if (oralPrescriptionsData) {
      selectedPrescriptions.forEach((prescriptionId) => {
        deleteOralPrescriptions(prescriptionId, token, sessionId);
      });
      setUpdateOralPrescriptionsData(true);
    }

    setIsButtonsDisabled(true);
  };

  const handleCheckboxChange = (index: number) => {
    const updatedStates = [...checkboxStates];
    updatedStates[index] = !updatedStates[index];
    setCheckboxStates(updatedStates);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    // Mettre à jour l'état de toutes les cases à cocher
    const updatedStates = checkboxStates.map(() => checked);
    setCheckboxStates(updatedStates);

    setIsChecked(checked);

    // Vérifier s'il y a au moins une case à cocher cochée
    setIsButtonsDisabled(!checked || updatedStates.length === 0);
  };

  return (
    <>
      <div className={styles.container__listes}>
        <h2 className={styles.title}>
          {/* Utilisez la balise de traduction pour le titre */}
          {i18n.t("Menu.ListeOralPresciptionsFormesOrales.title")}
        </h2>
        <div className={styles.scroll__section}>
          <div id="no-prescription-oraux" className={styles.liste__prescriptions}>
            {oralPrescriptionsData.length === 0 ? (
              // Utilisez la balise de traduction pour le message en cas d'absence d'ordonnance
              <p>
                {i18n.t("Menu.ListeOralPresciptionsFormesOrales.noPrescriptionMessage")}
              </p>
            ) : (
              oralPrescriptionsData.map(
                (prescription: OralPrescriptions, index: number) => (
                  <div key={`${prescription.id}_${prescription.name}`}>
                    {" "}
                    <input
                      type="checkbox"
                      id={`${prescription.id}`}
                      name={prescription.name}
                      className="prescription-checkbox"
                      checked={checkboxStates[index]}
                      onChange={() => handleCheckboxChange(index)}
                    />
                    <label htmlFor={`${prescription.id}`}>{prescription.name}</label>
                  </div>
                ),
              )
            )}
          </div>
        </div>
        <div className={styles.liste__all__prescriptions}>
          <div>
            <input
              type="checkbox"
              id="selectAllOraux"
              name="select-all"
              checked={isChecked}
              onChange={handleSelectAll}
            />
            <label htmlFor="selectAll">
              {i18n.t("Menu.ListeOralPresciptionsFormesOrales.selectAllLabel1")}{" "}
              {oralPrescriptionsData.length}{" "}
              {i18n.t("Menu.ListeOralPresciptionsFormesOrales.selectAllLabel2")}
            </label>
          </div>
        </div>
      </div>

      <DialogActions
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          id="delete-prescription-oraux"
          sx={{
            border: "2px solid var(--red)",
            width: "120px",
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "var(--red)",
            color: "var(--white)",
            "&:hover": {
              border: "2px solid var(--red)",
              color: "var(--red)",
              backgroundColor: "transparent",
            },
          }}
          disabled={isButtonsDisabled || oralPrescriptionsData.length === 0}
          onClick={handleOpen}
        >
          {/* Utilisez la balise de traduction pour le bouton "Supprimer" */}
          {i18n.t("Menu.ListeOralPresciptionsFormesOrales.deleteButton")}
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {/* Utilisez la balise de traduction pour le titre de la confirmation de suppression */}
            {i18n.t("Menu.ListeOralPresciptionsFormesOrales.deleteConfirmationTitle")}
          </DialogTitle>
          <DialogActions>
            <Button id="confirm-delete" onClick={handleDelete}>
              {/* Utilisez la balise de traduction pour le bouton de confirmation de suppression */}
              {i18n.t("Menu.ListeOralPresciptionsFormesOrales.deleteConfirmationConfirm")}
            </Button>
            <Button onClick={handleClose}>
              {/* Utilisez la balise de traduction pour le bouton d'annulation de suppression */}
              {i18n.t("Menu.ListeOralPresciptionsFormesOrales.deleteConfirmationCancel")}
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          sx={{
            border: "2px solid var(--dark-grey)",
            width: "120px",
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "var(--dark-grey)",
            color: "var(--white)",
            "&:hover": {
              border: "2px solid var(--dark-grey)",
              color: "var(--dark-grey)",
              backgroundColor: "transparent",
            },
          }}
          onClick={() => setIsOpen(false)}
        >
          Annuler
        </Button>
      </DialogActions>
    </>
  );
}
