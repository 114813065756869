import { SOLVENT_NICE_IDS, DEFAULT_SOLVENT_ID } from "../constants";

/**
 * A helper function to get working items from nice_ids.
 * @param niceId the id stored in the database.
 * @returns A DrugOrSynonym item referring to the actual element.
 */
function getItem(niceId: string, allItems: DrugSynonymArray): DrugOrSynonym | null {
  return allItems.find((elem) => elem.nice_id === niceId) || null;
}

export function getManyDrugs(
  niceIds: string[],
  allItems: DrugSynonymArray,
): DrugOrSynonym[] {
  const drugs: DrugOrSynonym[] = [];
  niceIds.forEach((niceId) => {
    const drug = getItem(niceId, allItems);
    if (drug) {
      drugs.push(drug);
    }
  });
  return drugs;
}

export default class Block {
  // eslint-disable-next-line class-methods-use-this
  private _solventId: string = DEFAULT_SOLVENT_ID;
  private _drugs: DrugOrSynonym[] = [];
  private _allSolvents: DrugOrSynonym[] = [];
  private _incompApiData: IncompApiResp | null = null;
  public incompatibilities: BlockIncompatibilities = {};
  toJson: any;

  get drugNiceIds() {
    return this._drugs.map((drug) => drug.nice_id);
  }

  /**
   * Adds drugs to the block
   * @param drugs the list of DrugOrSynonym to add to the block
   */
  addDrugs(drugs: DrugOrSynonym[]) {
    this._drugs = [...this._drugs, ...drugs];
    this.updateIncompatibilities();
  }

  set incompApiData(data: IncompApiResp) {
    this._incompApiData = data;
    this.updateIncompatibilities();
  }

  changeOrderDrug(drug: DrugOrSynonym, order: number) {
    console.assert(
      this.drugNiceIds.includes(drug.nice_id),
      `${drug.name} not in this block !!`,
    );
    // remove niceId
    this._drugs = this._drugs.filter((d) => drug.nice_id !== d.nice_id);

    // Add the element at a specific place
    if (order >= 0) {
      this._drugs.splice(order, 0, drug);
    } else {
      this._drugs.splice(this._drugs.length + 1 + order, 0, drug);
    }

    this.updateIncompatibilities();
  }

  removeDrug(drug: DrugOrSynonym) {
    console.assert(
      this.drugNiceIds.includes(drug.nice_id),
      `${drug.name} not in this block !!`,
    );
    this._drugs = this._drugs.filter((d) => drug.nice_id !== d.nice_id);
    this.updateIncompatibilities();
  }

  hasDrugId(niceId: string): boolean {
    return this.drugNiceIds.includes(niceId);
  }

  set allSolvents(solvents: DrugOrSynonym[]) {
    this._allSolvents = solvents;
  }

  set drugs(drugs: DrugOrSynonym[]) {
    this._drugs = drugs;
    this.updateIncompatibilities();
  }

  get drugs() {
    return this._drugs;
  }

  set solventId(niceId: string | null) {
    if (niceId) {
      Block.verifySolventId(niceId);
      this._solventId = niceId;
    } else {
      this._solventId = DEFAULT_SOLVENT_ID;
    }
    this.updateIncompatibilities();
  }

  get solventId(): string {
    return this._solventId;
  }

  private updateIncompatibilities() {
    this.incompatibilities = this.getIncompatibilities();
  }

  private getIncompatibilities(): BlockIncompatibilities {
    const blockIncomps: BlockIncompatibilities = {};
    const solventData = this._allSolvents.find(
      (solvent) => `d-${solvent.drug_id}` === this._solventId,
    );

    const solventSynonymId = `s-${solventData ? solventData.id : null}`;
    for (const drugNiceId of this.drugNiceIds) {
      if (
        this._incompApiData !== null &&
        drugNiceId in this._incompApiData.incompatibilities
      ) {
        // Get all incompatibilities for this drug
        const allIncomps = this._incompApiData.incompatibilities[drugNiceId];
        // Filter incompatibilities for this drug in this block
        const drugIncomps = allIncomps.filter((item) => {
          return (
            item.nice_id !== solventSynonymId &&
            item.type_incomp !== "drug" &&
            this.drugNiceIds.includes(item.nice_id)
          );
        });

        const solventIncomp = allIncomps.filter(
          (item) => item.nice_id === solventSynonymId,
        );

        blockIncomps[drugNiceId] = {
          // solvent: allIncomps.filter((item) => item.nice_id === `s-${solventId}`),
          solvent: solventIncomp,
          drugs: drugIncomps,
          material: allIncomps.filter((item) => item.material_name),
        };
      } else {
        blockIncomps[drugNiceId] = {
          solvent: [],
          drugs: [],
          material: [],
        };
      }
    }

    return blockIncomps;
  }

  static verifySolventId(solventNiceId: string) {
    if (!SOLVENT_NICE_IDS.includes(solventNiceId)) {
      throw new Error(
        `solventId=${solventNiceId} is not valid, valid solvent ids are the following: ${SOLVENT_NICE_IDS}`,
      );
    }
  }

  toJSON(): object {
    return {
      drugIds: this.drugNiceIds,
      solventId: this._solventId,
      incompatibilities: this.incompatibilities,
    };
  }

  static fromJSON(json: any, allItems: DrugSynonymArray): Block {
    const block = new Block();
    block._drugs = getManyDrugs(json.drugIds, allItems);
    block._solventId = json.solventId;
    block.incompatibilities = json.incompatibilities;
    return block;
  }
}
