import { useState, useEffect } from "react";
import env from "react-dotenv";
import { Route, Routes, useLocation } from "react-router-dom";
import axios, { AxiosError } from "axios";
import styles from "./MainContentScreen.module.css";
import MainMenu from "../../components/General/MainMenu/MainMenu";
import useFetchDrugsSynonym from "../../hooks/useFetchDrugsSynonyms";
import useFetchIncomp from "../../hooks/useFetchIncomp";
import useFetchOralDrugsSynonym from "../../hooks/useFetchOralDrugsSynonym";
import useFetchMedsOrauxInfos from "../../hooks/useFetchMedsOrauxInfos";
import useFetchRcpData from "../../hooks/useFetchRcpData";
import useFetchAdministration from "../../hooks/useFetchAdministration";
import AuthModal from "../../components/General/AuthModal/AuthModal";
import sendTrackSelItem from "../../hooks/sendTrackSelItem";
import LegalNoticeModal from "../../components/General/LegalNoticeModal/LegalNoticeModal";
import sendTrackRestart from "../../hooks/sendTrackRestart";
import OthersScreen from "../OthersScreen/OthersScreen";
import InjectablesRampesScreen from "../InjectablesRampesScreen/InjectablesRampesScreen";
import BlocksSet from "../../classes/BlocksSet";
import fetchBlocksOptimization from "../../hooks/fetchBlocksOptimization";
import {
  INIT_INJ_DRUG_IDS,
  INIT_NUM_BLOCKS,
  INIT_OTHERS_NICE_IDS,
  SOLVENT_NICE_IDS,
  API_URL_USER_RULES,
} from "../../constants";
import AppContext from "../../AppContext";
import SettingSet from "../../classes/SettingSet";
import NewsDialog from "../../components/General/NewsDialog/NewsDialog";
import InternalErrDialog from "../../components/General/InternalErrDialog/InternalErrDialog";
import UnauthorizedDialog from "../../components/General/UnauthorizedDialog/UnauthorizedDialog";
import OfflineDialog from "../../components/General/OfflineDialog/OfflineDialog";
import Settings from "../../classes/Settings";
import ErrorDialog from "../../components/General/ErrorDialog/ErrorDialog";

type Props = {
  legalNoticeOpen: boolean;
  setLegalNoticeOpen: (open: boolean) => void;
  rampShown: boolean;
  recompute: boolean;
  setRecompute: (recompute: boolean) => void;
};

export default function MainContent({
  legalNoticeOpen,
  setLegalNoticeOpen,
  rampShown,
  recompute,
  setRecompute,
}: Props) {
  const location = useLocation();
  const [token, setToken] = useState<string | null>(localStorage.getItem("token"));
  const [niceIdState, setNiceId] = useState<string | "">("");
  const [niceIdAdminGuide, setNiceIdAdminGuide] = useState<string | "">("");
  const [sessionId, setSessionId] = useState<string | null>(
    localStorage.getItem("session_id"),
  );
  const {
    data: allItems,
    isUnauthorizedDrugSynonym,
    isInternalErrDrugSynonym,
  } = useFetchDrugsSynonym(token);

  const solventItems = allItems.filter((item) => item.is_solvent === true);

  const [selDrugIds, setSelDrugIds] = useState<number[]>(INIT_INJ_DRUG_IDS);
  const [selNiceIds, setSelNiceIds] = useState<string[]>([]);

  useEffect(() => {
    // This effect should be called only the first times, when getting default
    // drug values (either only solvents or development preset)
    // Then do not regenerate selNiceIds from selDrugIds
    if (selNiceIds.length === 0) {
      const filteredItems = selDrugIds
        .map((drugId) =>
          allItems.find((item) => item.drug_id === drugId && item.is_default === true),
        )
        .filter((item) => item !== undefined);

      const newSelNiceIds = filteredItems.map((item) => String(item!.nice_id));
      setSelNiceIds(newSelNiceIds);
    }
  }, [allItems, selDrugIds]);

  // const INIT_INJ_NICE_IDS: string[] = []; // selNiceIds;
  // console.log("INIT_INJ_NICE_IDS", selNiceIds, INIT_INJ_DRUG_IDS);

  const [selMedsOraux, setSelMedsOraux] = useState<string[]>(INIT_OTHERS_NICE_IDS);

  const [isAuthModelOpen, setIsAuthModalOpen] = useState(true);
  const [isNewsDialogOpen, setIsNewsDialogOpen] = useState(false);
  const [isInternalErrDialogOpen, setIsInternalErrDialogOpen] = useState(false);
  const [isUnauthorizedDialogOpen, setIsUnauthorizedDialogOpen] = useState(false);
  const [isOfflineDialogOpen, setIsOfflineDialogOpen] = useState(false);
  const [
    isUnauthorizedInjectablePrescriptions,
    setIsUnauthorizedInjectablePrescriptions,
  ] = useState(false);
  const [isUnauthorizedOralPrescriptions, setIsUnauthorizedOralPrescriptions] =
    useState(false);
  const [isInternalInjectablePrescriptions, setIsInternalInjectablePrescriptions] =
    useState(false);
  const [isInternalOralPrescriptions, setIsInternalOralPrescriptions] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filterTextStack, setFilterTextStack] = useState<string[]>([]);
  const [areThumbsDisabled, setAreThumbsDisabled] = useState(false);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const {
    data: allMedsOraux,
    isUnauthorizedOralDrugsSynonym,
    isInternalOralDrugsSynonym,
  } = useFetchOralDrugsSynonym(token);
  const {
    data: medsOrauxInfos,
    isUnauthorizedOrauxInfo,
    isInternalErrMedsOrauxInfo,
  } = useFetchMedsOrauxInfos(selMedsOraux, allMedsOraux || [], token);
  const [numBlocks, setNumBlocks] = useState(INIT_NUM_BLOCKS);
  const [blocksSet, setBlocksSet] = useState<BlocksSet | null>(null);
  const [isOptLoading, setIsOptLoading] = useState(false);
  const [selExactOptVal, setSelExactOptVal] = useState<number>(-1);
  const [exactOptResult, setExactOptResult] = useState<BlockOptimizationData[][] | null>(
    null,
  );
  const [settingSet, setSettingSet] = useState(new SettingSet([], INIT_NUM_BLOCKS));
  const [currentSetting, setCurrentSetting] = useState<Settings>(
    new Settings(numBlocks, []),
  );
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
  const [initFetchIncomp, setInitFetchIncomp] = useState(false);
  const {
    data: incompApiData,
    isLoading: isIncompApiLoading,
    isUnauthorizedIncomp,
    isInternalErrIncomp,
  } = useFetchIncomp(selNiceIds, allItems, numBlocks, token, settingSet, initFetchIncomp);

  const {
    data: rcpDataId,
    isUnauthorizedRcpData,
    isInternalErrRcpData,
  } = useFetchRcpData(niceIdState, token);

  const {
    data: adminGuideId,
    isUnauthorizedAdminGuide,
    isInternalErrAdminGuide,
  } = useFetchAdministration(niceIdAdminGuide, token);

  const [biblioRefsId, setBiblioRefsId] = useState<number[]>([]);

  const [selConfigIdx, setSelConfigIdx] = useState<number | null>(null);
  // Set saved params
  const localCompSettingsString = localStorage.getItem("compSettings");

  const localCompSettings = localCompSettingsString
    ? JSON.parse(localCompSettingsString)
    : {};

  const localOralSettingsString = localStorage.getItem("oralSettings");

  const localOralSettings = localOralSettingsString
    ? JSON.parse(localOralSettingsString)
    : {};

  const [showNoInfoIncomp, setShowNoInfoIncomp] = useState<boolean>(
    localCompSettings.showNoInfoIncomp ?? false,
  );
  const [showCompatibilities, setShowCompatibilities] = useState<boolean>(
    localCompSettings.showCompatibilities ?? false,
  );
  const [showCompMaterial, setShowCompMaterial] = useState<boolean>(
    localCompSettings.showCompMaterial ?? true,
  );
  const [showInformationAlimentaires, setShowInformationAlimentaires] = useState<boolean>(
    localOralSettings.showInformationAlimentaires ?? true,
  );
  const [showInformationEcrasabilites, setShowInformationEcrasabilites] =
    useState<boolean>(localOralSettings.showInformationEcrasabilites ?? true);

  const [showModalitesPrises, setShowModalitesPrises] = useState(true);

  const [isEcrasableInfoFilterActive, setIsEcrasableInfoFilterActive] = useState(true);

  const [isPrescriptionsDialogOpen, setIsPrescriptionsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [applyBigsFirst, setApplyBigsFirst] = useState(true);
  const [ivPrescriptionsData, setIvPrescriptionsData] = useState<
    InjectablePrescriptions[]
  >([]);
  const [updateIvPrescriptionsData, setUpdateIvPrescriptionsData] =
    useState<boolean>(true);

  const [oralPrescriptionsData, setOralPrescriptionsData] = useState<OralPrescriptions[]>(
    [],
  );
  const [updateOralPrescriptionsData, setUpdateOralPrescriptionsData] =
    useState<boolean>(true);

  // Initialization of the settings related objects:
  const [settingList, setSettingList] = useState<any[]>([]);

  // Set selConfigIdx
  useEffect(() => {
    if (selConfigIdx === null && allItems.length > 0 && selNiceIds.length > 0) {
      setSelConfigIdx(0);
    }
  }, [allItems, selNiceIds]);

  const currentBlockSetString = localStorage.getItem("currentBlocksSet");

  // Create blocksSet at loading time
  useEffect(() => {
    if (selConfigIdx === 0) {
      if (currentBlockSetString !== null) {
        // If a blockset is stored, retrieve data from the blockset:
        const currentBlockSet = JSON.parse(currentBlockSetString);
        if (currentBlockSet !== null) {
          let currentDrugs: string[] = currentBlockSet.drugIds;
          currentDrugs = currentDrugs.filter((item: any) => typeof item === "string");
          currentDrugs = currentDrugs.filter((item: string) => !item.startsWith("s"));
          SOLVENT_NICE_IDS.forEach((solvent) => {
            currentDrugs.unshift(solvent);
          });

          setSelNiceIds(currentDrugs);
        }
      }
      setBlocksSet(
        new BlocksSet(
          numBlocks,
          getDrugsFromNiceIds(selNiceIds),
          currentSetting,
          allItems,
        ),
      );
    }
  }, [selConfigIdx]);

  useEffect(() => {
    if (settingList !== null && allItems.length > 0) {
      const list: Settings[] = settingList.map((settingJson: JSON) => {
        return Settings.fromJSON(settingJson, allItems);
      });
      setSettingSet(new SettingSet(list, INIT_NUM_BLOCKS));
      setInitFetchIncomp(true);
    }
  }, [settingList, allItems]);

  useEffect(() => {
    setCurrentSetting(settingSet.selectByRamps(numBlocks) || new Settings(numBlocks, []));
  }, [settingSet, numBlocks]);

  // Function to get user settings.
  // Used only if the user is already authenticated as login request already contains the info
  async function initSettings() {
    try {
      const url = API_URL_USER_RULES;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setSettingList(response.data);
      }
    } catch (e) {
      const error = e as AxiosError<{ detail: string }>;
      console.error(error);
    }
  }

  // Create OtherSet at loading time
  useEffect(() => {
    const lastSelItemsString = localStorage.getItem("lastSelItems");
    const lastSelItems = lastSelItemsString ? JSON.parse(lastSelItemsString) : null;
    if (lastSelItems && lastSelItems.length > 0) {
      setSelMedsOraux(lastSelItems);
    }
  }, []);

  // If user changes opt exact option then display the right one
  useEffect(() => {
    if (selExactOptVal !== -1 && blocksSet && exactOptResult) {
      blocksSet.applyApiOptimization(
        exactOptResult[selExactOptVal],
        incompApiData,
        numBlocks,
        allItems,
      );
      setBlocksSet(Object.create(blocksSet));
    }
  }, [selExactOptVal]);

  // Displaying AuthModal if the token and the session id are not present in the local storage
  useEffect(() => {
    if (token !== null && sessionId !== null) {
      // Make sure to send one single request to the server
      if (settingList.length === 0) {
        initSettings();
      }
      setIsAuthModalOpen(false);
    }
  }, []);

  // Update local storage with current version
  useEffect(() => {
    if (!isAuthModelOpen) {
      const lastVersion = localStorage.getItem("CI_COMMIT_SHA");
      const currVersion = env.CI_COMMIT_SHA;
      let openNews = false;

      if (currVersion === null || currVersion === undefined) {
        openNews = false;
      } else if (lastVersion === null || lastVersion !== currVersion) {
        openNews = true;
      } else {
        openNews = false;
      }

      setIsNewsDialogOpen(openNews);

      localStorage.setItem("CI_COMMIT_SHA", currVersion || null);
    }
  }, [isAuthModelOpen]);

  // Whenever incompApiData changes, apply fast_repartition
  useEffect(() => {
    if (blocksSet && incompApiData && !isIncompApiLoading && recompute) {
      blocksSet.applyApiOptimization(
        incompApiData.fast_repartition,
        incompApiData,
        numBlocks,
        allItems,
      );
    }
  }, [isIncompApiLoading]);

  // Function to optimize blocks
  async function onOptimizeBlocks(algoName: string, setting_id: number | null) {
    setIsOptLoading(true);
    const data = await fetchBlocksOptimization(
      selNiceIds,
      numBlocks,
      token,
      sessionId,
      algoName,
      setting_id,
    );
    if (blocksSet && data && data.length > 0 && recompute) {
      blocksSet.applyApiOptimization(data[0], incompApiData, numBlocks, allItems);
      setBlocksSet(Object.create(blocksSet));

      if (algoName.includes("optimize_exact")) {
        setExactOptResult(data);
        setSelExactOptVal(0);
      }
    }
    setIsOptLoading(false);
  }

  // Update function for BlocksSet
  function updateBlockSet() {
    setBlocksSet(Object.create(blocksSet));
  }

  // Update function for settingSet
  function updateSettingSet() {
    setSettingSet(Object.create(settingSet));
  }

  // Function to remove exact optimize results
  function removeExactOptResults() {
    setExactOptResult(null);
    setSelExactOptVal(-1);
  }

  // Function to reset blocksSet
  function resetBlocksSet() {
    const newBlocks = new BlocksSet(
      numBlocks,
      getDrugsFromNiceIds(selNiceIds),
      currentSetting,
      allItems,
    );
    if (incompApiData) {
      newBlocks.incompApiData = incompApiData;
    }
    setBlocksSet(newBlocks);
    removeExactOptResults();
  }

  /**
   * A helper function to get working drug items from nice_ids.
   * @param niceId the id stored in the database.
   * @returns A DrugOrSynonym item referring to the actual element.
   */
  function getItem(niceId: string): DrugOrSynonym | null {
    return allItems.find((elem) => elem.nice_id === niceId) || null;
  }

  /**
   * A function to get many working drugs from nice_ids
   */
  function getDrugsFromNiceIds(niceIds: string[]): DrugOrSynonym[] {
    const drugs: DrugOrSynonym[] = [];
    niceIds.forEach((niceId) => {
      const drug = getItem(niceId);
      if (drug) {
        drugs.push(drug);
      }
    });
    return drugs;
  }

  function addSelItem(niceId: string) {
    const item = getItem(niceId);
    if (item) {
      const drugId = item.type === "synonym" ? (item as Synonym).drug_id : item.id;

      setSelNiceIds((currSelNiceIds) => {
        const newSelNiceIds = [...currSelNiceIds, niceId];
        sendTrackSelItem(newSelNiceIds, filterTextStack, niceId, token, sessionId);
        return newSelNiceIds;
      });

      setSelDrugIds((currSelDrugIds) => [...currSelDrugIds, drugId]);
    }
    resetFilterText();
    setAreThumbsDisabled(false);
    removeExactOptResults();
  }

  function removeSelItem(niceId: string) {
    setTimeout(() => {
      const item = getItem(niceId);
      if (item) {
        const drugId = item.type === "synonym" ? (item as Synonym).drug_id : item.id;
        setSelNiceIds((currSelNiceIds) => currSelNiceIds.filter((id) => niceId !== id));
        setSelDrugIds((currSelDrugIds) => currSelDrugIds.filter((id) => id !== drugId));
      }
      setAreThumbsDisabled(false);
      removeExactOptResults();
      blocksSet?.removeDrugFromNiceId(niceId);
    }, 300);
  }

  function addSelItemRegle(niceId: string) {
    const item = getItem(niceId);
    if (item) {
      const drugId = item.type === "synonym" ? (item as Synonym).drug_id : item.id;
      setSelNiceIds((currSelNiceIds) => [...currSelNiceIds, niceId]);
      setSelDrugIds((currSelDrugIds) => [...currSelDrugIds, drugId]);
    }
    resetFilterText();
    sendTrackSelItem(selNiceIds, filterTextStack, niceId, token, sessionId);
    setAreThumbsDisabled(false);
    removeExactOptResults();
  }

  // Functions for meds oraux
  function addMedOral(niceId: string) {
    setSelMedsOraux((curr) => [...curr, niceId]);
    resetFilterText();
    setAreThumbsDisabled(false);
  }

  function removeMedOral(niceId: string) {
    setSelMedsOraux((curr) => curr.filter((id) => niceId !== id));
  }

  async function resetSelItems() {
    const currPath = location.pathname;
    if (currPath === "/") {
      const filteredItems = INIT_INJ_DRUG_IDS.map((drugId) =>
        allItems.find((item) => item.drug_id === drugId && item.is_default === true),
      ).filter((item) => item !== undefined);

      const newSelNiceIds = filteredItems.map((item) => String(item!.nice_id));
      setSelNiceIds(newSelNiceIds);
      // setSelNiceIds(INIT_INJ_NICE_IDS);
      setSelDrugIds(INIT_INJ_DRUG_IDS);
    } else if (currPath === "/others") {
      setSelMedsOraux(INIT_OTHERS_NICE_IDS);
    }
    resetFilterText();
    setAreThumbsDisabled(false);
    removeExactOptResults();
    // send data to api
    await sendTrackRestart(selNiceIds, token, sessionId, currPath, selMedsOraux);
  }

  function resetFilterText() {
    setFilterText("");
    setFilterTextStack([]);
  }

  function modifyFilterText(text: string) {
    setFilterText(text);
    setFilterTextStack((stack) => [...stack, text]);
  }
  // Logout function

  function handleLogout() {
    setToken(null);
    setSessionId(null);
    localStorage.removeItem("token");
    localStorage.removeItem("session_id");
    // refresh page
    window.location.reload();
  }

  // Handle server responses
  useEffect(() => {
    if (
      isUnauthorizedDrugSynonym === true ||
      isUnauthorizedIncomp === true ||
      isUnauthorizedOralDrugsSynonym === true ||
      isUnauthorizedOrauxInfo === true ||
      isUnauthorizedInjectablePrescriptions === true ||
      isUnauthorizedOralPrescriptions === true ||
      isUnauthorizedRcpData === true ||
      isUnauthorizedAdminGuide === true
    ) {
      setIsUnauthorizedDialogOpen(true);
    } else if (
      isInternalErrDrugSynonym === true ||
      isInternalErrIncomp === true ||
      isInternalOralDrugsSynonym === true ||
      isInternalErrMedsOrauxInfo === true ||
      isInternalInjectablePrescriptions ||
      isInternalOralPrescriptions === true ||
      isInternalErrRcpData === true ||
      isInternalErrAdminGuide === true
    ) {
      setIsInternalErrDialogOpen(true);
    }
  }, [
    isUnauthorizedDrugSynonym,
    isUnauthorizedIncomp,
    isUnauthorizedOralDrugsSynonym,
    isUnauthorizedOrauxInfo,
    isInternalErrDrugSynonym,
    isInternalErrIncomp,
    isInternalOralDrugsSynonym,
    isInternalErrMedsOrauxInfo,
    isUnauthorizedRcpData,
    isInternalErrRcpData,
  ]);
  // Online status
  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      setIsOfflineDialogOpen(true);
    }
  }, [isOnline]);

  // Update context
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context = {
    biblioRefsId,
    setBiblioRefsId,
    selNiceIds,
    setSelNiceIds,
    selMedsOraux,
    setSelMedsOraux,
    token,
    sessionId,
    niceIdState,
    setNiceId,
    niceIdAdminGuide,
    setNiceIdAdminGuide,
    allItems,
    incompApiData,
    rcpDataId,
    adminGuideId,
    selDrugIds,
    filterText,
    allMedsOraux,
    medsOrauxInfos,
    numBlocks,
    setNumBlocks,
    blocksSet,
    updateBlockSet,
    isOptLoading,
    setIsOptLoading,
    selExactOptVal,
    setSelExactOptVal,
    exactOptResult,
    onOptimizeBlocks,
    addSelItem,
    addSelItemRegle,
    removeSelItem,
    addMedOral,
    removeMedOral,
    modifyFilterText,
    resetBlocksSet,
    setExactOptResult,
    settingSet,
    updateSettingSet,
    currentSetting,
    isHelpDialogOpen,
    setIsHelpDialogOpen,
    showNoInfoIncomp,
    setShowNoInfoIncomp,
    showCompatibilities,
    setShowCompatibilities,
    showCompMaterial,
    setShowCompMaterial,
    rampShown,
    isPrescriptionsDialogOpen,
    setIsPrescriptionsDialogOpen,
    removeExactOptResults,
    isUnauthorizedDrugSynonym,
    isUnauthorizedIncomp,
    isUnauthorizedOralDrugsSynonym,
    isUnauthorizedOrauxInfo,
    isInternalErrDrugSynonym,
    isInternalErrIncomp,
    isInternalOralDrugsSynonym,
    isInternalErrMedsOrauxInfo,
    isUnauthorizedRcpData,
    isInternalErrRcpData,
    getDrugsFromNiceIds,
    setIsErrorDialogOpen,
    setErrorMessage,
    applyBigsFirst,
    setApplyBigsFirst,
    showInformationAlimentaires,
    setShowInformationAlimentaires,
    showInformationEcrasabilites,
    setShowInformationEcrasabilites,
    resetSelItems,
    ivPrescriptionsData,
    setIvPrescriptionsData,
    updateIvPrescriptionsData,
    setUpdateIvPrescriptionsData,
    setIsUnauthorizedInjectablePrescriptions,
    setIsInternalInjectablePrescriptions,
    oralPrescriptionsData,
    setOralPrescriptionsData,
    updateOralPrescriptionsData,
    setUpdateOralPrescriptionsData,
    setIsUnauthorizedOralPrescriptions,
    setIsInternalOralPrescriptions,
    showModalitesPrises,
    setShowModalitesPrises,
    isEcrasableInfoFilterActive,
    setIsEcrasableInfoFilterActive,
    solventItems,
  };
  return (
    <AppContext.Provider value={context}>
      <div className={styles.main}>
        <div className={styles.screen}>
          <Routes>
            <Route path="/others" element={<OthersScreen />} />
            <Route path="/ehpad" element={<OthersScreen />} />
            <Route
              path="/"
              element={<InjectablesRampesScreen setRecompute={setRecompute} />}
            />
          </Routes>
        </div>

        <div className={styles.panel}>
          <MainMenu
            handleLogout={() => handleLogout()}
            areThumbsDisabled={areThumbsDisabled}
            setAreThumbsDisabled={setAreThumbsDisabled}
            setRecompute={setRecompute}
          />
        </div>

        <AuthModal
          isOpen={isAuthModelOpen}
          setIsOpen={setIsAuthModalOpen}
          setToken={setToken}
          setSessionId={setSessionId}
          setSettingList={setSettingList}
        />
        <LegalNoticeModal isOpen={legalNoticeOpen} setIsOpen={setLegalNoticeOpen} />
      </div>
      <NewsDialog isOpen={isNewsDialogOpen} setIsOpen={setIsNewsDialogOpen} />
      <InternalErrDialog
        isOpen={isInternalErrDialogOpen}
        setIsOpen={setIsInternalErrDialogOpen}
      />
      <UnauthorizedDialog
        isOpen={isUnauthorizedDialogOpen}
        setIsOpen={setIsUnauthorizedDialogOpen}
        handleLogout={() => handleLogout()}
      />
      <ErrorDialog
        message={errorMessage}
        isOpen={isErrorDialogOpen}
        onClose={() => {
          setIsErrorDialogOpen(false);
        }}
      />

      <OfflineDialog isOpen={isOfflineDialogOpen} setIsOpen={setIsOfflineDialogOpen} />
    </AppContext.Provider>
  );
}
