import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL_ORAL_DRUGS_INFO } from "../constants";

export default function useFetchMedsOrauxInfos(
  selMedsOraux: string[],
  allMedsOraux: AllMedsOraux,
  token: string | null,
): {
  data: MedOralInfos[];
  isLoading: boolean;
  isError: boolean;
  isUnauthorizedOrauxInfo: boolean;
  isInternalErrMedsOrauxInfo: boolean;
} {
  const [data, setData] = useState<MedOralInfos[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isUnauthorizedOrauxInfo, setIsUnauthorizedOrauxInfo] = useState(false);
  const [isInternalErrMedsOrauxInfo, setIsInternaErrlMedsOrauxInfo] = useState(false);
  const prevIdsStr = useRef("");

  useEffect(() => {
    const url = API_URL_ORAL_DRUGS_INFO;

    if (
      prevIdsStr.current !== selMedsOraux.join("") &&
      allMedsOraux.length > 0 &&
      selMedsOraux.length > 0 // Add this condition to check if any new medications were added
    ) {
      const fetchData = async () => {
        if (!token) return;
        setIsError(false);
        setIsLoading(true);

        try {
          const newMedsOraux = selMedsOraux.filter(
            (id) => !prevIdsStr.current.includes(id),
          ); // Filter out the medications that were already loaded

          const bodyReq = { nice_ids: newMedsOraux }; // Fetch only the newly added medications
          const result = await axios.post(url, bodyReq, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setData((prevData) => [...prevData, ...result.data]); // Append the newly fetched data to the existing data
        } catch (error: any) {
          setIsError(true);
          if (error.response.status === 401) {
            setIsUnauthorizedOrauxInfo(true);
          } else if (error.response.status === 500) {
            setIsInternaErrlMedsOrauxInfo(true);
          }
        }

        setIsLoading(false);
      };

      fetchData();
      prevIdsStr.current = selMedsOraux.join("");
    }
  }, [selMedsOraux.length, allMedsOraux, selMedsOraux, token]);

  return {
    data,
    isLoading,
    isError,
    isUnauthorizedOrauxInfo,
    isInternalErrMedsOrauxInfo,
  };
}
