import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import styles from "./CommentFeedbackModal.module.css";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onSendMessage: (
    comment: string,
    name: string | null,
    email: string | null,
    radioChoice: number,
  ) => Promise<boolean>;
};

export default function CommentFeedbackModel({
  isOpen,
  setIsOpen,
  onSendMessage,
}: Props) {
  const [isErrorRadio, setIsErrorRadio] = useState(false);
  const [isErrorComment, setIsErrorComment] = useState(false);
  const [comment, setComment] = useState("");
  const [radioChoice, setRadioChoice] = useState<number | null>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  async function handleSendData() {
    let isError = false;

    if (comment.length === 0) {
      setIsErrorComment(true);
      isError = true;
    }

    if (radioChoice === null) {
      setIsErrorRadio(true);
      isError = true;
    }

    if (isError) return;

    // Send data and open thanks modal in FeedbackPanel
    const isDataSent = await onSendMessage(comment, name, email, radioChoice as number);

    // Reset state
    if (isDataSent) {
      setName("");
      setRadioChoice(null);
      setComment("");
      setIsErrorRadio(false);
      setIsErrorComment(false);
      setIsOpen(false);
      setEmail("");
    }
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{i18n.t("Menu.CommentFeedbackModel.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {i18n.t("Menu.CommentFeedbackModel.subtitle")}
        </DialogContentText>

        <div className={styles.nameEmail}>
          {/* Name */}
          <FormControl style={{ width: "100%", marginTop: "30px", marginRight: "10px" }}>
            <TextField
              variant="outlined"
              label={i18n.t("Menu.CommentFeedbackModel.nameLabel")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          {/* Email */}
          <FormControl style={{ width: "100%", marginTop: "30px" }}>
            <TextField
              variant="outlined"
              label={i18n.t("Menu.CommentFeedbackModel.emailLabel")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
        </div>

        {/* Evaluation */}
        <FormControl style={{ marginTop: "20px" }} error={isErrorRadio}>
          <FormLabel>{i18n.t("Menu.CommentFeedbackModel.ratingLabel")}</FormLabel>
          <RadioGroup
            row
            name="radio-buttons-group"
            value={radioChoice}
            onChange={(e) => setRadioChoice(Number(e.target.value))}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label={i18n.t("Menu.CommentFeedbackModel.ratingOptions.0")}
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={i18n.t("Menu.CommentFeedbackModel.ratingOptions.1")}
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label={i18n.t("Menu.CommentFeedbackModel.ratingOptions.2")}
            />
            <FormControlLabel
              value={3}
              control={<Radio />}
              label={i18n.t("Menu.CommentFeedbackModel.ratingOptions.3")}
            />
          </RadioGroup>
        </FormControl>

        {/* Comments */}
        <FormControl style={{ width: "100%", marginTop: "10px" }} error={isErrorComment}>
          <FormLabel>{i18n.t("Menu.CommentFeedbackModel.commentLabel")}</FormLabel>
          <TextField
            multiline
            style={{ width: "100%" }}
            minRows={5}
            maxRows={5}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)}>
          {i18n.t("Menu.CommentFeedbackModel.closeButton")}
        </Button>
        <Button onClick={() => handleSendData()}>
          {i18n.t("Menu.CommentFeedbackModel.submitButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
