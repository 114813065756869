import { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import styles from "./SettingsDialog.module.css";
import AddRuleDialog from "../AddRuleDialog/AddRuleDialog";
import RuleItemDisplay from "../RuleItemDisplay/RuleItemDisplay";
import AppContext from "../../../AppContext";
import Settings from "../../../classes/Settings";
import ErrorDialog from "../ErrorDialog/ErrorDialog";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  setting: Settings | null;
  avaliableBlocks: number[];
  rules: BlockRule[];
  setRules: (block: BlockRule[]) => void;
  setHasChanged: (hasChanged: boolean) => void;
};

export default function SettingsDialog({
  isOpen,
  setIsOpen,
  setting,
  avaliableBlocks,
  rules,
  setRules,
  setHasChanged,
}: Props) {
  const [openDialogAddRule, setOpenDialogAddRule] = useState(false);
  const { settingSet, updateSettingSet } = useContext(AppContext);
  const [selNumBlocks, setSelNumBlocks] = useState<number | null>(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorDialogMsg, setErrorDialogMsg] = useState("");
  const [changedRule, setChangedRule] = useState(false);

  // Functions for error dialog
  const openErrorDialog = (msg: string) => {
    setErrorDialogMsg(msg);
    setIsErrorDialogOpen(true);
  };

  const closeErrorDialog = () => {
    setErrorDialogMsg("");
    setIsErrorDialogOpen(false);
  };

  // Called each time the dialog is closed
  const handleClose = () => {
    setSelNumBlocks(null);
    setIsOpen(false);
  };

  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const regleLayout = (
    <div className={styles.regleArea}>
      <RuleItemDisplay
        setRules={setRules}
        rules={rules}
        setChangedRule={setChangedRule}
      />
    </div>
  );

  // Whenever this modal is opened, update rules and numblocks
  useEffect(() => {
    if (isOpen) {
      setSelNumBlocks(setting?.numberBlocks || avaliableBlocks[0]);
      setRules(setting?.rules || []);
    } else {
      setRules([]);
    }
  }, [isOpen]);

  // Whenever the user changes the number of blocks, deletes the rules with a higher block_number
  useEffect(() => {
    if (selNumBlocks) {
      setRules(rules.filter((rule) => rule.blockNumber <= selNumBlocks));
    }
  }, [selNumBlocks]);

  // A function to save the modified setting to the setting set:
  function saveSettings() {
    if (!selNumBlocks) {
      return;
    }
    if (setting) {
      // If the setting already exists, update the existing object:
      setting.updateSetting(rules, selNumBlocks);
    } else {
      // Else, create an object:
      settingSet.addSetting(rules, selNumBlocks);
    }
    // If the setting is empty, delete it:
    if (settingSet.selectByRamps(selNumBlocks)?.isEmpty()) {
      settingSet.deleteByRamps(selNumBlocks);
    }
    updateSettingSet();
    if (changedRule) {
      setHasChanged(true);
    }
    handleClose();
  }

  return (
    <>
      <Dialog
        open={isOpen}
        PaperProps={{
          sx: {
            height: "fit-content",
            width: isSmallScreen ? "100%" : "70%",
            maxWidth: isSmallScreen ? "100vw" : "600px",
            minHeight: "350px",
            overflow: "auto",
            alignItems: "center",
            padding: 2,
            margin: 1,
          },
        }}
        maxWidth={false}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
          }}
        >
          {setting
            ? i18n.t("Menu.RulesDialog.ModifyRules")
            : i18n.t("Menu.RulesDialog.CreateRules")}
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <div className={styles.content}>
            <div className={styles.nombreMoyenLum}>
              <p>{i18n.t("Menu.RulesDialog.NumberOfLights")}</p>
              <Select
                className={styles.select}
                sx={{ height: "2em", width: 120 }}
                variant="outlined"
                value={selNumBlocks || ""}
                onChange={(e) => {
                  setSelNumBlocks(Number(e.target.value));
                  setChangedRule(true);
                }}
              >
                {avaliableBlocks.map((val) => (
                  <MenuItem value={val} key={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={styles.ruleArea}>{regleLayout}</div>
            <Button
              onClick={() => {
                if (selNumBlocks) {
                  setOpenDialogAddRule(true);
                } else {
                  setErrorDialogMsg(i18n.t("Menu.RulesDialog.ErrorMessage"));
                  setIsErrorDialogOpen(true);
                }
              }}
              startIcon={<AddIcon />}
              variant="text"
              sx={{ alignSelf: "center" }}
              disabled={selNumBlocks === null}
              id="add-rule"
            >
              {i18n.t("Menu.RulesDialog.AddRule")}
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="warning">
            {i18n.t("Menu.RulesDialog.Cancel")}
          </Button>
          <Button
            onClick={() => saveSettings()}
            disabled={selNumBlocks === null}
            id="save-rule"
          >
            {i18n.t("Menu.RulesDialog.Save")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Rule Dialog */}
      <AddRuleDialog
        isOpen={openDialogAddRule}
        setIsOpen={setOpenDialogAddRule}
        rules={rules}
        setRules={setRules}
        selNumBlocks={selNumBlocks || 0}
        onError={openErrorDialog}
        setChangedRule={setChangedRule}
      />

      {/* Error Dialog */}
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorDialogMsg || ""}
        onClose={closeErrorDialog}
      />
    </>
  );
}
