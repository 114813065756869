import { useContext, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AppContext from "../../../AppContext";
import styles from "./RuleSwitch.module.css";
import i18n from "../../../i18n";

export default function RuleSwitch() {
  const { settingSet, updateSettingSet } = useContext(AppContext);

  // Init: track changes to minimize requests
  useEffect(() => {
    settingSet.resetChanged();
    updateSettingSet();
    settingSet.saveActiveState();
  }, []);

  // On every change, set the switch if all settings are set or
  // if all settings are unset:
  useEffect(() => {
    if (settingSet.settings.length > 0) {
      const cond = settingSet.settings[0].isActive;
      // Useful to break out of infinite loop
      if (settingSet.isActive === cond) {
        return;
      }
      for (let i = 0; i < settingSet.settings.length; i += 1) {
        if (settingSet.settings[i].isActive !== cond) {
          return;
        }
      }
      settingSet.setActive(cond);
      updateSettingSet();
    }
  }, [settingSet]);

  const handleSwitchChange = () => {
    settingSet.setActive(!settingSet.isActive);
    updateSettingSet();
  };

  return (
    <div className={styles.toggles}>
      <div className={styles.injectable}>
        <h3>{i18n.t("general.setting.rule-switch.title")}</h3>
        <div className={styles.btns}>
          <FormGroup className={styles.switch}>
            <FormControlLabel
              sx={{ display: "flex", justifyContent: "start" }}
              control={
                <Switch checked={settingSet.isActive} onChange={handleSwitchChange} />
              }
              label={i18n.t("general.setting.rule-switch.activate-rule")}
              className={styles.label}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
}
