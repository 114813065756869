import axios from "axios";
import { API_URL_PRESCRIPTIONS_ORAL } from "../constants";

export default async function deleteOralPrescriptions(
  id: number | null,
  token: string | null,
  sessionId: string | null,
): Promise<boolean> {
  if (!token || !sessionId) return false;

  const url = `${API_URL_PRESCRIPTIONS_ORAL}?prescription_id=${id}`;

  try {
    const response = await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.status === 200;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return false;
  }
}
