/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";

import styles from "./DialogSavePrescriptions.module.css";
import i18n from "../../../i18n";

type Props = {
  open: boolean;
  patientFirstName: string;
  soignantName: string;
  newPrescriptionName: string;
  handleCancelButtonClick: () => void;
  handleConfirmSaveClick: () => void;
  handlePatientFirstNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSoignantNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function DialogSavePrescriptions({
  open,
  patientFirstName,
  soignantName,
  newPrescriptionName,
  handleCancelButtonClick,
  handleConfirmSaveClick,
  handlePatientFirstNameChange,
  handleSoignantNameChange,
}: Props) {
  return (
    <Dialog
      open={open}
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center !important",
        justifyContent: "center",
        "& > *:nth-of-type(3)": {
          "& > :first-of-type": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center !important",
            justifyContent: "center",
            padding: "20px !important",
            gap: "20px",
          },
        },
      }}
      id="save-prescription-dialog"
    >
      <DialogTitle
        sx={{
          width: "80%",
          textAlign: "center",
          color: "var(--dark-grey)",
          fontSize: "1.3rem",
          "& span": {
            color: "var(--green)",
            fontWeight: "bold",
          },
        }}
      >
        {i18n.t("formesOrales.PanelOther.popupSavePrescription.titleSavePrescription")}
      </DialogTitle>
      <DialogContent
        sx={{
          paddingTop: "10px !important",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        <TextField
          sx={{ width: "80%" }}
          label={i18n.t(
            "formesOrales.PanelOther.popupSavePrescription.labelInputNamePatient",
          )}
          variant="outlined"
          value={patientFirstName}
          onChange={handlePatientFirstNameChange}
          id="patient-first-name-input"
        />
        <TextField
          sx={{ width: "80%" }}
          label={i18n.t(
            "formesOrales.PanelOther.popupSavePrescription.labelNameSoignant",
          )}
          variant="outlined"
          value={soignantName}
          onChange={handleSoignantNameChange}
          id="soignant-name-input"
        />
        <div className={styles.container__name}>
          <label htmlFor="ordonnance-input">
            {i18n.t(
              "formesOrales.PanelOther.popupSavePrescription.labelNamePrescription",
            )}
          </label>
          <TextField
            sx={{ width: "60%", fontWeight: "bold" }}
            value={newPrescriptionName}
            variant="outlined"
            id="ordonnance-input"
            disabled
          />
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "20px",
          width: "78%",
        }}
      >
        <Button
          sx={{
            width: "200px",
            backgroundColor: "var(--dark-grey)",
            color: "var(--white)",
            border: "3px solid var(--dark-grey)",
            "&:hover": {
              backgroundColor: "var(--white)",
              color: "var(--dark-grey)",
            },
          }}
          color="warning"
          onClick={handleCancelButtonClick}
        >
          {i18n.t("formesOrales.PanelOther.popupSavePrescription.close")}
        </Button>
        <Button
          sx={{
            width: "200px",
            backgroundColor: "var(--green)",
            color: "var(--white)",
            border: "3px solid var(--green)",
            "&:hover": {
              backgroundColor: "var(--white)",
              color: "var(--green)",
            },
          }}
          onClick={handleConfirmSaveClick}
          id="enregistrer-prescription-button"
        >
          {i18n.t("formesOrales.PanelOther.popupSavePrescription.save")}
        </Button>
      </DialogActions>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "0px",
          lineHeight: "0rem",
          width: "100%",
          "& p": {
            color: "var(--dark-grey)",
            fontSize: "1rem",
            fontWeight: "bold",
          },
          "& span": {
            color: "var(--red)",
            fontWeight: "bold",
          },
        }}
      />
    </Dialog>
  );
}
