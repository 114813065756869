import { useContext } from "react";
import AppContext from "../../../AppContext";
import styles from "./IncompatibilitiesRampes.module.css";
import DrugIncompatibilititesRampes from "../DrugIncompatibilitiesRampes/DrugIncompatibilitiesRampes";

type Props = {
  setRecompute: (recompute: boolean) => void;
};

export default function IncompatibilitiesRampes({ setRecompute }: Props) {
  const { selNiceIds, incompApiData, allItems, removeSelItem, showNoInfoIncomp } =
    useContext(AppContext);
  const { incompatibilities: allIncompatibilities } = incompApiData;

  const allIncompsFilter: typeof allIncompatibilities = {};
  for (const key of Object.keys(allIncompatibilities)) {
    allIncompsFilter[key] = allIncompatibilities[key].filter((item) =>
      selNiceIds.includes(item.nice_id),
    );
  }

  const materialIncomps: typeof allIncompatibilities = {};

  for (const key of Object.keys(allIncompatibilities)) {
    const filteredItems = allIncompatibilities[key].filter(
      (item) => item.type_incomp === "incomp_material",
    );

    if (filteredItems.length > 0) {
      materialIncomps[key] = filteredItems;
    }
  }

  const incompatibilities = showNoInfoIncomp
    ? allIncompsFilter
    : Object.fromEntries(
        Object.entries(allIncompsFilter).map(([key, value]) => [
          key,
          value.filter((incompDrug) => incompDrug.type_incomp !== "no_info"),
        ]),
      );

  const selItems = allItems
    .filter((item) => selNiceIds.includes(item.nice_id))
    .sort((a, b) => selNiceIds.indexOf(a.nice_id) - selNiceIds.indexOf(b.nice_id));
  return (
    <div className={styles.main}>
      <div className={styles.incompatibilities}>
        {selItems.map((item) => (
          <DrugIncompatibilititesRampes
            incompatibilities={incompatibilities[item.nice_id] || []}
            materialIncomps={materialIncomps[item.nice_id] || []}
            key={item.nice_id}
            item={item}
            removeSelItem={removeSelItem}
            incompApiData={incompApiData}
            setRecompute={setRecompute}
          />
        ))}
      </div>
    </div>
  );
}
