import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styles from "./OngletInfoOther.module.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--green)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const lookup = {
  G: "Goût désagréable",
  E: "Prise possible avec de l'eau",
  J: "Prise possible avec un jus d'orange",
  L: "Prise possible avec du lait",
  C: "Prise possible avec une compote",
  Y: "Prise possible avec un yaourt",
  V: "A prendre immédiatement après préparation",
};

type Props = {
  medOralInfos: MedOralInfos;
};

export default function OngletInfoOther({ medOralInfos }: Props) {
  const { informations } = medOralInfos;

  const methode: JSX.Element[] = [];
  const gout: JSX.Element[] = [];
  const alternativesGaleniques: JSX.Element[] = [];
  const informationRcpNotice: JSX.Element[] = [];

  if (informations.length > 0) {
    informations.forEach((info) => {
      if (info.methode_d_administration_si_medicament_ecrase_ouvert_ou_delite) {
        const methodeElements =
          info.methode_d_administration_si_medicament_ecrase_ouvert_ou_delite.split(", ");
        methode.push(
          <ul key={`methode-${info.id}`}>
            {methodeElements.map((element) => (
              <li key={`methode-${info.id}-${element}`}>
                {lookup[element as keyof typeof lookup]}
              </li>
            ))}
          </ul>,
        );
      }

      if (info.gout) {
        const goutElements = info.gout.split(", ");
        gout.push(
          <ul key={`gout-${info.id}`}>
            {goutElements.map((element) => (
              <li key={`gout-${info.id}-${element}`}>
                {lookup[element as keyof typeof lookup]}
              </li>
            ))}
          </ul>,
        );
      }

      if (info.alternatives_galeniques) {
        alternativesGaleniques.push(
          <div key={`alternatives-${info.id}`}>{info.alternatives_galeniques}</div>,
        );
      }

      if (info.informations_rcp_notice_patient_et_theriaque) {
        informationRcpNotice.push(
          <div key={`informationRcpNotice-${info.id}`}>
            {info.informations_rcp_notice_patient_et_theriaque}
          </div>,
        );
      }
    });
  }

  return (
    <>
      <TableContainer component={Paper} className={styles.bigScreen}>
        <Table sx={{ minWidth: 800 }} aria-label="customized table">
          <TableHead>
            <TableRow className={styles.tableHead}>
              <StyledTableCell className={styles.tableCell}>Gout</StyledTableCell>
              <StyledTableCell className={styles.tableCell} align="left">
                Methode d&apos;administration si médicament écrasé, ouvert ou délité
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} align="left">
                Alternatives galéniques
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} align="left">
                Information RCP, notice patient et theriaque
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell
                className={styles.tableCell_row}
                component="th"
                scope="row"
              >
                {gout}
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} align="left">
                {methode}
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} align="left">
                {alternativesGaleniques}
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} align="left">
                {informationRcpNotice}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className={styles.smallScreen}>
        {gout.length > 0 && <div className={styles.title}>Gout</div>}
        <ul className={styles.text}>{gout}</ul>

        {methode.length > 0 && (
          <div className={styles.title}>
            Methode d&apos;administration si médicament écrasé, ouvert ou délité
          </div>
        )}
        <ul className={styles.text}>{methode}</ul>

        {alternativesGaleniques.length > 0 && (
          <div className={styles.title}>Alternatives galéniques</div>
        )}
        <div className={styles.text}>{alternativesGaleniques}</div>

        {informationRcpNotice.length > 0 && (
          <div className={styles.title}>Information RCP, notice patient et theriaque</div>
        )}
        <div className={styles.text}>{informationRcpNotice}</div>
      </div>
    </>
  );
}
