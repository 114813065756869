import { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { DialogContentText } from "@mui/material";
import AppContext from "../../../AppContext";
import styles from "./HelpDialog.module.css";
import i18n from "../../../i18n";

export default function HelpDialog() {
  const { isHelpDialogOpen, setIsHelpDialogOpen } = useContext(AppContext);

  return (
    <Dialog
      open={isHelpDialogOpen}
      onClose={() => setIsHelpDialogOpen(false)}
      maxWidth={false}
      className={styles.main}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        {/* Utilisez la balise de traduction pour le titre */}
        {i18n.t("Menu.HelpDialog.InstructionsTitle")}
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <DialogContentText style={{ padding: 10 }}>
          {/* Utilisez la balise de traduction pour le premier contenu */}
          {i18n.t("Menu.HelpDialog.FirstParagraph")}
        </DialogContentText>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          src="https://public-drugoptimal.s3.rbx.io.cloud.ovh.net/Presentation_HARDIS_de_DrugOptimal.mp4"
          controls
          autoPlay
          style={{ width: "80%" }}
        />
        <DialogContentText style={{ padding: 10 }}>
          {/* Utilisez la balise de traduction pour le deuxième contenu */}
          {i18n.t("Menu.HelpDialog.SecondParagraph")}
        </DialogContentText>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          src="https://public-drugoptimal.s3.rbx.io.cloud.ovh.net/Enregistrer_et_telecharger_les_ordonnances.mp4"
          controls
          style={{ width: "80%" }}
        />
      </DialogContent>
      <DialogActions>
        {/* Utilisez la balise de traduction pour le bouton */}
        <Button onClick={() => setIsHelpDialogOpen(false)}>
          {i18n.t("Menu.HelpDialog.CloseButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
