import styles from "./DrugIncompatibilitiesOther.module.css";
import DrugItemIncompatibilitiesOther from "../DrugItemIncompatibilitiesOther/DrugItemIncompatibilitiesOther";
// import EcrasableInfo from "../EcrasableInfo/EcrasableInfo";

type Props = {
  removeMedOral: SetNiceIds;
  medOralInfos: MedOralInfos;
};

export default function DrugIncompatibilititesOther({
  removeMedOral,
  medOralInfos,
}: Props) {
  const modalites = medOralInfos.informations
    .map((info) => info.modalites_de_preparation)
    .join(", ");
  return (
    <div className={styles.main}>
      <DrugItemIncompatibilitiesOther
        medOralInfos={medOralInfos}
        removeMedOral={removeMedOral}
        modalites={modalites}
      />
    </div>
  );
}
