import { useContext, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import styles from "./SettingItemDisplay.module.css";
import AppContext from "../../../AppContext";
import RuleSwitchIndividual from "../RuleSwitchIndividual/RuleSwitchIndividual";

type Props = {
  setModifySetting: (blockNumber: number) => void;
  setIsSettingDialogOpen: (value: boolean) => void;
  setHasChanged: (hasChanged: boolean) => void;
};

export default function SettingItemDisplay({
  setModifySetting,
  setIsSettingDialogOpen,
  setHasChanged,
}: Props) {
  const { settingSet, updateSettingSet } = useContext(AppContext);
  const [reload, setReload] = useState(true);
  // Used to reload the screen to take into account deletion
  useEffect(() => {}, [reload]);

  return (
    <>
      {settingSet.settings.map((setting, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.containerRegle} key={index}>
          <div className={styles.infoRegle}>
            <h1>{setting.numberBlocks} RAMPES</h1>
          </div>
          <div className={styles.btns}>
            <RuleSwitchIndividual setting={setting} />
            <IconButton
              color="primary"
              onClick={() => {
                setModifySetting(setting.numberBlocks);
                setIsSettingDialogOpen(true);
              }}
            >
              <Tooltip placement="top" title="Modifier ce jeu de règles">
                <SettingsIcon />
              </Tooltip>
            </IconButton>

            {/* Delete Button */}
            <IconButton
              sx={{ color: "red" }}
              onClick={() => {
                settingSet.deleteByRamps(setting.numberBlocks);
                updateSettingSet();
                setHasChanged(true);
                setReload(!reload);
              }}
              id="delete-rule"
            >
              <Tooltip placement="right" title="Supprimer ce jeu de règles">
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      ))}
    </>
  );
}
