/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-alert */
import { useContext, useState } from "react";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import TextField from "@mui/material/TextField";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import AppContext from "../../../AppContext";
import styles from "./OcrTextDialog.module.css";
import sendScanPresctiptionText from "../../../hooks/sendScanPresctiptionText";
import i18n from "../../../i18n";

type Props = {
  open: boolean;
  handleOcrTextCancelButtonClick: () => void;
};

export default function OcrTextDialog({ open, handleOcrTextCancelButtonClick }: Props) {
  const { token, sessionId, setSelNiceIds } = useContext(AppContext);
  const [pastedContent, setPastedContent] = useState("");
  const [isTextCaptureLoading, setIsTextCaptureLoading] = useState(false);

  const handlePasteClick = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setPastedContent(text);
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err);
    }
  };
  const [type] = useState<"injectable" | "oral">("injectable");

  const handleSendTextToDatabase = async () => {
    if (token && sessionId) {
      try {
        setIsTextCaptureLoading(true);
        const response = await sendScanPresctiptionText(
          type,
          pastedContent,
          token,
          sessionId,
        );

        if (response && response.length > 0) {
          // Créez un nouveau tableau avec les ID supplémentaires
          const defaultIds = ["d-532", "d-517"];

          const updatedResponse = [...defaultIds, ...response];
          setSelNiceIds(updatedResponse);
          handleOcrTextCancelButtonClick();
        } else {
          alert("Aucun médicament trouvé dans l'ordonnance.");
        }
      } finally {
        setIsTextCaptureLoading(false); // Définit le chargement en cours à false une fois terminé
      }
    } else {
      console.error("Token ou sessionId manquant.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleOcrTextCancelButtonClick}
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center !important",
        justifyContent: "center",
        "& > *:nth-of-type(3)": {
          "& > :first-of-type": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center !important",
            justifyContent: "center",
            padding: "20px !important",
            gap: "20px",
          },
        },
      }}
      id="ocr-text-dialog"
    >
      {isTextCaptureLoading ? (
        <div className={styles.container__loader}>
          <div className={styles.loader}>
            <div>{/* Contenu vide */}</div>
            <div>{/* Contenu vide */}</div>
            <div>{/* Contenu vide */}</div>
            <div>{/* Contenu vide */}</div>
          </div>
          <p> {i18n.t("general.ocr.ocrDialog.preparation")}</p>
        </div>
      ) : null}

      <DialogTitle
        sx={{
          width: "80%",
          textAlign: "center",
          color: "var(--dark-grey)",
          fontSize: "1.3rem",
          "& span": {
            color: "var(--green)",
            fontWeight: "bold",
          },
        }}
      >
        {i18n.t("general.ocr.ocrDialog.title")}
      </DialogTitle>
      <DialogContent
        sx={{
          width: "85%",
          textAlign: "center",
        }}
      >
        {i18n.t("general.ocr.ocrDialog.description")}
      </DialogContent>
      <div className={styles.container__scan__text__pc}>
        <div className={styles.textfield__container}>
          <Button onClick={handlePasteClick}>
            <ContentPasteIcon
              sx={{
                marginRight: "5px",
                width: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
            {i18n.t("general.ocr.ocrDialog.paste")}
          </Button>
          <TextField
            placeholder={i18n.t("general.ocr.ocrDialog.placeholder")}
            multiline
            rows={15}
            value={pastedContent} // Utilisez la valeur de l'état pour afficher le contenu collé
            onChange={(e) => setPastedContent(e.target.value)} // Met à jour l'état lorsque le texte change
            sx={{
              width: "100%",
              textAlign: "center",
            }}
            id="ocr-textfield"
          />
        </div>

        <DialogActions>
          <Button
            sx={{
              border: "2px solid var(--green)",
              width: "110px",
              height: "33px",
              fontSize: "0.9rem",
              display: "flex",
              marginTop: "12px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "var(--green)",
              color: "var(--white)",
              borderRadius: "2px",
              "&:hover": {
                border: "2px solid var(--green)",
                color: "var(--green)",
                backgroundColor: "transparent",
              },
            }}
            onClick={handleSendTextToDatabase}
            id="ocr-text-add-button"
          >
            {i18n.t("general.ocr.ocrDialog.add")}
          </Button>
        </DialogActions>
      </div>
      <DialogActions>
        <Button
          sx={{
            border: "2px solid var(--red)",
            width: "110px",
            height: "33px",
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "var(--red)",
            color: "var(--white)",
            borderRadius: "2px",

            "&:hover": {
              border: "2px solid var(--red)",
              color: "var(--red)",
              backgroundColor: "transparent",
            },
          }}
          onClick={handleOcrTextCancelButtonClick}
        >
          {i18n.t("general.ocr.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
