import React, { useState, useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DialogItemOther from "../DialogInfoOther/DialogInfoOther";
import styles from "./DrugItemIncompatibilitiesOther.module.css";
import EcrasableInfo from "../EcrasableInfo/EcrasableInfo";
import AlternativeInfo from "../AlternativeInfo/AlternativeInfo";
import AppContext from "../../../AppContext";
import { DISPLAY_ALL_ORAL_MOLECULES } from "../../../constants";

type Props = {
  removeMedOral: SetNiceIds;
  medOralInfos: MedOralInfos;
  modalites: string | null;
};

export default function DrugItemIncompatibilitiesOther({
  medOralInfos,
  removeMedOral,
  modalites,
}: Props) {
  const {
    showInformationAlimentaires,
    showInformationEcrasabilites,
    showModalitesPrises,
    allMedsOraux,
    isEcrasableInfoFilterActive,
  } = useContext(AppContext);

  const showInfoIncomp = DISPLAY_ALL_ORAL_MOLECULES;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  let infoIncompLayout;
  if (showInfoIncomp) {
    infoIncompLayout = (
      <>
        {medOralInfos.molecules.map((molecule) => (
          <React.Fragment key={molecule.name}>
            {molecule.incompatibilities.map(
              (incompatibility) =>
                incompatibility.food_name.toLowerCase() !== "nourriture" && (
                  <div
                    className={
                      showInformationAlimentaires ? styles.condition : styles.noCondition
                    }
                    key={incompatibility.food_name}
                    id={`incompatibility-food-${medOralInfos.nice_id}`}
                  >
                    {`${incompatibility.adjective}: ${incompatibility.food_name}`}
                  </div>
                ),
            )}
          </React.Fragment>
        ))}
      </>
    );
  } else {
    infoIncompLayout = (
      <>
        {medOralInfos.molecules
          .filter((molecule) => molecule.incompatibilities.length > 0)
          .map((molecule) => (
            <React.Fragment key={molecule.name}>
              {molecule.incompatibilities.map(
                (incompatibility) =>
                  incompatibility.food_name.toLowerCase() !== "nourriture" && (
                    <div
                      className={
                        showInformationAlimentaires
                          ? styles.condition
                          : styles.noCondition
                      }
                      key={incompatibility.food_name}
                      id={`incompatibility-food-${medOralInfos.nice_id}`}
                    >
                      {`${incompatibility.adjective}: ${incompatibility.food_name}`}
                    </div>
                  ),
              )}
            </React.Fragment>
          ))}
        {medOralInfos.molecules.every(
          (molecule) => molecule.incompatibilities.length === 0,
        ) && (
          <div className={styles.noIncomp}>
            Aucune incompatibilité trouvée dans ce médicament.
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className={`${styles.main} ${styles.selected}`} item-id={medOralInfos.nice_id}>
        <div className={styles.informations} item-id={medOralInfos.nice_id}>
          {medOralInfos.informations.map((info) => (
            <div
              className={styles.principe_actif}
              key={`${medOralInfos.nice_id}-${info.id}`}
            >
              {info.principe_actif}
            </div>
          ))}

          <div className={styles.container__informations}>
            {showInformationEcrasabilites &&
              modalites &&
              modalites.split(", ").map((ecCode) => {
                if (ecCode !== "?") {
                  const isEcrasableInfoRendered = medOralInfos.informations.some(
                    (info) => {
                      const alternative = info.alternatives_galeniques ?? "";
                      return !(
                        isEcrasableInfoFilterActive &&
                        ecCode === "NE" &&
                        (alternative === "Non" || alternative === "Pas d'information")
                      );
                    },
                  );

                  if (isEcrasableInfoRendered) {
                    return (
                      <div
                        key={ecCode}
                        className={styles.container_ecrasabilite}
                        id={`incompatibility-ecrasable-${medOralInfos.nice_id}`}
                      >
                        {medOralInfos.informations.map((info) => {
                          const alternative = info.alternatives_galeniques ?? "";
                          if (
                            !isEcrasableInfoFilterActive ||
                            !(
                              ecCode === "NE" &&
                              (alternative === "Non" ||
                                alternative === "Pas d'information")
                            )
                          ) {
                            return (
                              <EcrasableInfo
                                key={`${medOralInfos.nice_id}-${ecCode}-${info.id}`}
                                ecrasableCode={ecCode}
                                sourceModalites={info.source_des_modalites ?? ""}
                              />
                            );
                          }
                          return null;
                        })}
                      </div>
                    );
                  }
                }
                return null;
              })}

            <div
              className={styles.container_ecrasabilite}
              id={`incompatibility-alternative-${medOralInfos.nice_id}`}
            >
              {medOralInfos.informations.map((info) =>
                // Ajoutez une vérification ici avant le rendu de AlternativeInfo
                (info.alternatives_galeniques ?? "") !== "Non" &&
                (info.alternatives_galeniques ?? "") !== "Pas d'information" ? (
                  <AlternativeInfo
                    key={`${medOralInfos.nice_id}-${info.id}`}
                    alternative={info.alternatives_galeniques ?? ""}
                    sourceModalites={info.source_des_modalites ?? ""}
                  />
                ) : null,
              )}
            </div>

            {infoIncompLayout}
          </div>
        </div>

        {medOralInfos.informations.some(
          (info) => info.informations_rcp_notice_patient_et_theriaque,
        ) && (
          <div
            className={
              showModalitesPrises
                ? styles.container__modalites
                : styles.noContainer__modalites
            }
          >
            <div>
              {medOralInfos.informations
                .filter((info) => info.informations_rcp_notice_patient_et_theriaque)
                .map((info) => info.informations_rcp_notice_patient_et_theriaque)}
            </div>
          </div>
        )}

        <div className={styles.btns}>
          <IconButton
            className={styles.btn_clear}
            type="button"
            onClick={() => {
              const selectedMed = allMedsOraux.find(
                (item) => item.nice_id === medOralInfos.nice_id,
              );
              if (selectedMed) {
                removeMedOral(selectedMed.nice_id);
              }
            }}
            sx={{
              backgroundColor: "var(--red)",
              borderRadius: "5px",
              height: "40px",
              width: "40px",
              "&:hover": {
                backgroundColor: "#ff6666",
              },
            }}
          >
            <Tooltip title="Enlever Médicament">
              <DeleteIcon />
            </Tooltip>
          </IconButton>

          <DialogItemOther
            isOpen={isDialogOpen}
            handleClose={() => setIsDialogOpen(false)}
            setIsOpen={setIsDialogOpen}
            medOralInfos={medOralInfos}
          />
        </div>
      </div>

      {/* ---------- responsive mobile ----------- */}

      <div
        className={`${styles.main__mobile} ${styles.selected}`}
        item-id={medOralInfos.nice_id}
      >
        <div className={styles.container__top}>
          {medOralInfos.informations.map((info) => (
            <div
              className={styles.principe_actif}
              key={`${medOralInfos.nice_id}-${info.id}`}
            >
              {info.principe_actif}
            </div>
          ))}

          <div className={styles.btns}>
            <IconButton
              className={styles.btn_clear}
              type="button"
              onClick={() => {
                const selectedMed = allMedsOraux.find(
                  (item) => item.nice_id === medOralInfos.nice_id,
                );
                if (selectedMed) {
                  removeMedOral(selectedMed.nice_id);
                }
              }}
              sx={{
                backgroundColor: "var(--red)",
                borderRadius: "5px",
                height: "40px",
                width: "40px",
                "&:hover": {
                  backgroundColor: "#ff6666",
                },
              }}
            >
              <Tooltip title="Enlever Médicament">
                <DeleteIcon />
              </Tooltip>
            </IconButton>

            <DialogItemOther
              isOpen={isDialogOpen}
              handleClose={() => setIsDialogOpen(false)}
              setIsOpen={setIsDialogOpen}
              medOralInfos={medOralInfos}
            />
          </div>
        </div>
        <div className={styles.informations} item-id={medOralInfos.nice_id}>
          <div className={styles.container__informations}>
            {showInformationEcrasabilites &&
              modalites &&
              modalites.split(", ").map((ecCode) => {
                if (ecCode !== "?") {
                  const isEcrasableInfoRendered = medOralInfos.informations.some(
                    (info) => {
                      const alternative = info.alternatives_galeniques ?? "";
                      return !(
                        isEcrasableInfoFilterActive &&
                        ecCode === "NE" &&
                        (alternative === "Non" || alternative === "Pas d'information")
                      );
                    },
                  );

                  if (isEcrasableInfoRendered) {
                    return (
                      <div
                        key={ecCode}
                        className={styles.container_ecrasabilite}
                        id={`incompatibility-ecrasable-${medOralInfos.nice_id}`}
                      >
                        {medOralInfos.informations.map((info) => {
                          const alternative = info.alternatives_galeniques ?? "";
                          if (
                            !isEcrasableInfoFilterActive ||
                            !(
                              ecCode === "NE" &&
                              (alternative === "Non" ||
                                alternative === "Pas d'information")
                            )
                          ) {
                            return (
                              <EcrasableInfo
                                key={`${medOralInfos.nice_id}-${ecCode}-${info.id}`}
                                ecrasableCode={ecCode}
                                sourceModalites={info.source_des_modalites ?? ""}
                              />
                            );
                          }
                          return null;
                        })}
                      </div>
                    );
                  }
                }
                return null;
              })}

            <div
              className={styles.container_ecrasabilite}
              id={`incompatibility-alternative-${medOralInfos.nice_id}`}
            >
              {medOralInfos.informations.map((info) =>
                // Ajoutez une vérification ici avant le rendu de AlternativeInfo
                (info.alternatives_galeniques ?? "") !== "Non" &&
                (info.alternatives_galeniques ?? "") !== "Pas d'information" ? (
                  <AlternativeInfo
                    key={`${medOralInfos.nice_id}-${info.id}`}
                    alternative={info.alternatives_galeniques ?? ""}
                    sourceModalites={info.source_des_modalites ?? ""}
                  />
                ) : null,
              )}
            </div>

            {infoIncompLayout}
          </div>
        </div>

        {medOralInfos.informations.some(
          (info) => info.informations_rcp_notice_patient_et_theriaque,
        ) && (
          <div
            className={
              showModalitesPrises
                ? styles.container__modalites
                : styles.noContainer__modalites
            }
          >
            <div>
              {medOralInfos.informations
                .filter((info) => info.informations_rcp_notice_patient_et_theriaque)
                .map((info) => info.informations_rcp_notice_patient_et_theriaque)}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
