import { useContext } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AppContext from "../../../AppContext";
import styles from "./OralSwitch.module.css";
import i18n from "../../../i18n";

type Props = {
  setRecompute: (recompute: boolean) => void;
};

export default function OralSwitch({ setRecompute }: Props) {
  const {
    showInformationAlimentaires,
    setShowInformationAlimentaires,
    showInformationEcrasabilites,
    setShowInformationEcrasabilites,
    showModalitesPrises,
    setShowModalitesPrises,
    isEcrasableInfoFilterActive,
    setIsEcrasableInfoFilterActive,
  } = useContext(AppContext);

  const handleSwitchChangeInformationAlimentaires = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowInformationAlimentaires(event.target.checked);
    const oralSettings = {
      showInformationAlimentaires: event.target.checked,
      showInformationEcrasabilites,
    };
    localStorage.setItem("oralSettings", JSON.stringify(oralSettings));
  };

  const handleSwitchChangeInformationEcrasabilites = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowInformationEcrasabilites(event.target.checked);
    const oralSettings = {
      showInformationAlimentaires,
      showInformationEcrasabilites: event.target.checked,
    };
    localStorage.setItem("oralSettings", JSON.stringify(oralSettings));
  };

  return (
    <div className={styles.toggles}>
      <hr />
      <div className={styles.oral}>
        <h3>{i18n.t("general.setting.oral-switch.title")}</h3>
        <div className={styles.interdiction}>
          <FormGroup className={styles.switch}>
            <FormControlLabel
              sx={{
                display: "flex",
                justifyContent: "start",
                width: "auto",
              }}
              control={
                <Switch
                  checked={showInformationAlimentaires}
                  onChange={handleSwitchChangeInformationAlimentaires}
                  id="food-switch"
                />
              }
              label={i18n.t("general.setting.oral-switch.food-switch")}
              className={styles.label}
            />
          </FormGroup>
        </div>
        <div className={styles.condition}>
          <FormGroup className={styles.switch}>
            <FormControlLabel
              sx={{
                display: "flex",
                justifyContent: "start",
                width: "auto",
              }}
              control={
                <Switch
                  checked={showInformationEcrasabilites}
                  onChange={handleSwitchChangeInformationEcrasabilites}
                  id="ecrasabilites-switch"
                />
              }
              label={i18n.t("general.setting.oral-switch.ecrasabilites-switch")}
              className={styles.label}
            />
          </FormGroup>
        </div>
        <div className={styles.condition}>
          <FormGroup className={styles.switch}>
            <FormControlLabel
              sx={{
                display: "flex",
                justifyContent: "start",
                width: "auto",
              }}
              control={
                <Switch
                  checked={showModalitesPrises}
                  onChange={() => setShowModalitesPrises(!showModalitesPrises)}
                  id="modalites-switch"
                />
              }
              label={i18n.t("general.setting.oral-switch.modalites-switch")}
              className={styles.label}
            />
          </FormGroup>
        </div>
        <div className={styles.condition}>
          <FormGroup className={styles.switch}>
            <FormControlLabel
              sx={{
                display: "flex",
                justifyContent: "start",
                width: "auto",
              }}
              control={
                <Switch
                  checked={isEcrasableInfoFilterActive}
                  onChange={() =>
                    setIsEcrasableInfoFilterActive(!isEcrasableInfoFilterActive)
                  }
                  id="ecrasable-filter-switch"
                />
              }
              label={i18n.t("general.setting.oral-switch.ecrasable-filter-switch")}
              className={styles.label}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
}
