/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-alert */
import React, { useContext, useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import AppContext from "../../../AppContext";
import styles from "./OcrDialog.module.css";
import sendOcrInjectable from "../../../hooks/sendOcrInjectable";
import OcrTextDialog from "../OcrTextDialog/OcrTextDialog";
import i18n from "../../../i18n";

type Props = {
  open: boolean;
  handleOcrCancelButtonClick: () => void;
  handleCreateOrUpdateOcrCancelButtonClick: () => void;
};

export default function OcrDialog({
  open,
  handleOcrCancelButtonClick,
  handleCreateOrUpdateOcrCancelButtonClick,
}: Props) {
  const [ocrTextDialogIsOpen, setOcrTextDialogIsOpen] = useState(false);

  const handleOcrTextButtonClick = () => {
    setOcrTextDialogIsOpen(true);
  };

  const handleOcrTextCancelButtonClick = () => {
    setOcrTextDialogIsOpen(false);
    handleOcrCancelButtonClick();
  };

  const { token, sessionId, setSelNiceIds } = useContext(AppContext);

  const [pastedContent, setPastedContent] = useState("");
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const webcamRef = useRef<Webcam | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPhotoCaptureLoading, setIsPhotoCaptureLoading] = useState(false);
  const handleOpenCamera = () => {
    setIsCameraOpen(true);
  };
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const detectDevice = () => {
      const mobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        );
      setIsMobile(mobile);
    };

    detectDevice();

    window.addEventListener("resize", detectDevice);

    return () => {
      window.removeEventListener("resize", detectDevice);
    };
  }, []);
  let buttonText;
  if (isMobile) {
    buttonText = i18n.t("general.ocr.ocrDialog.addMobile");
  } else {
    buttonText = i18n.t("general.ocr.ocrDialog.upload");
  }

  // -------------------------- resize -------------------

  const [webcamHeight, setWebcamHeight] = useState(getWebcamHeight(window.innerWidth));

  // Effect for handling the resize event
  useEffect(() => {
    function handleResize() {
      setWebcamHeight(getWebcamHeight(window.innerWidth));
    }

    window.addEventListener("resize", handleResize);
    // Call the handleResize function initially to set the webcam height
    handleResize();

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to calculate the webcam height based on window width
  function getWebcamHeight(width: number): number {
    const desktopBreakpoint = 1024;
    const tabletBreakpoint = 768;
    if (width > desktopBreakpoint) {
      return 500;
    }
    if (width > tabletBreakpoint) {
      return 400;
    }
    return 350;
  }

  // ------------------- Compte à rebours -------------------

  const [countdown, setCountdown] = useState<number | null>(null);
  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";

  const videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT,
  };

  const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);

  const handlePasteClick = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      const promises = [];

      for (const clipboardItem of clipboardItems) {
        for (const type of clipboardItem.types) {
          if (type.startsWith("image/")) {
            const promise = clipboardItem.getType(type).then(async (blob) => {
              const reader = new FileReader();

              reader.onload = async (event) => {
                if (event.target && event.target.result) {
                  setPastedContent(event.target.result.toString());

                  const timestamp = new Date().toISOString();
                  const imageName = `${timestamp}.jpg`;

                  const image = new File([blob], imageName);
                  setIsLoading(true);

                  const response = await sendOcrInjectable(image, token, sessionId);

                  // Gérer la réponse comme vous le faites déjà dans d'autres parties du code
                  if (response && response.length > 0) {
                    const defaultIds = ["d-532", "d-517"];
                    const updatedResponse = [...defaultIds, ...response];
                    setSelNiceIds(updatedResponse);
                  } else {
                    alert("Aucun médicament trouvé dans l'ordonnance.");
                  }

                  setIsLoading(false);
                }
              };

              reader.readAsDataURL(blob);
            });

            promises.push(promise);
          }
        }
      }

      await Promise.all(promises);
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err);
    }
  };

  const handleRemoveImage = () => {
    setPastedContent("");
  };

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_ENVIRONMENT ? FACING_MODE_USER : FACING_MODE_ENVIRONMENT,
    );
  }, []);

  // Définissez la fonction dataURItoBlob
  function dataURItoBlob(dataURI: string): Blob | null {
    const byteString: string = atob(dataURI.split(",")[1]);
    const mimeString: string = dataURI.split(",")[0].split(":")[1].split(";")[0];

    const ab: ArrayBuffer = new ArrayBuffer(byteString.length);
    const ia: Uint8Array = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  const handleCapturePhoto = async () => {
    // Fonction pour gérer le compte à rebours
    const startCountdown = (count: number) => {
      if (count === 0) {
        // Prendre la photo ici
        capturePhoto();
      } else {
        // Afficher le compte à rebours
        setCountdown(count);
        setTimeout(() => startCountdown(count - 1), 1000); // Appel récursif toutes les 1 seconde
      }
    };
    const capturePhoto = async () => {
      setCountdown(null);
      if (webcamRef.current) {
        const screenshot = webcamRef.current.getScreenshot();

        if (screenshot) {
          const blob = dataURItoBlob(screenshot);

          if (blob) {
            const timestamp = new Date().toISOString();
            const imageName = `${timestamp}.jpg`;

            const image = new File([blob], imageName);
            setIsPhotoCaptureLoading(true);

            const response = await sendOcrInjectable(image, token, sessionId);

            if (response && response.length > 0) {
              const defaultIds = ["d-532", "d-517"];

              const updatedResponse = [...defaultIds, ...response];

              setSelNiceIds(updatedResponse);
              handleOcrCancelButtonClick();
              setIsPhotoCaptureLoading(false);
            } else {
              alert("Aucun médicament trouvé dans l'ordonnance.");
              setIsPhotoCaptureLoading(false);
            }
          } else {
            setIsPhotoCaptureLoading(false);
          }
        } else {
          setIsPhotoCaptureLoading(false);
        }

        setIsCameraOpen(false);
      }
    };
    startCountdown(3);
  };

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleOcrCancelButtonClick}
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center !important",
        justifyContent: "center",
        "& > *:nth-of-type(3)": {
          "& > :first-of-type": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center !important",
            justifyContent: "center",
            padding: "20px !important",
            gap: "20px",
          },
        },
      }}
      id="ocr-dialog"
    >
      {isLoading || isPhotoCaptureLoading ? (
        <div className={styles.container__loader}>
          <div className={styles.loader}>
            <div>{/* Contenu vide */}</div>
            <div>{/* Contenu vide */}</div>
            <div>{/* Contenu vide */}</div>
            <div>{/* Contenu vide */}</div>
          </div>
          <p> {i18n.t("general.ocr.ocrDialog.preparation")}</p>
        </div>
      ) : null}

      <DialogTitle
        sx={{
          width: "80%",
          textAlign: "center",
          color: "var(--dark-grey)",
          fontSize: "1.3rem",
          "& span": {
            color: "var(--green)",
            fontWeight: "bold",
          },
        }}
      >
        {i18n.t("general.ocr.ocrDialog.title")}
      </DialogTitle>
      <DialogContent
        sx={{
          width: "85%",
          textAlign: "center",
        }}
      >
        {i18n.t("general.ocr.ocrDialog.description")}
      </DialogContent>
      <div className={styles.container__scan__text__pc}>
        <div className={styles.textfield__container}>
          <Button
            onClick={handlePasteClick}
            sx={{
              width: "100%",
              height: "110px",
              backgroundColor: "var(--green)",
              color: "var(--white)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "var(--dark-green)",
              },
              // media screen
              "@media screen and (max-width: 768px)": {
                width: "250px",
                height: "110px",
              },
            }}
            id="ocr-paste-button"
          >
            <ContentPasteIcon
              sx={{
                marginRight: "5px",
                width: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
            {i18n.t("general.ocr.ocrDialog.copy")}
          </Button>

          {pastedContent.startsWith("data:image/") ? (
            <>
              {" "}
              <div className={styles.imageContainer}>
                <img
                  src={pastedContent}
                  alt="Pasted content"
                  className={styles.pastedImage}
                />
              </div>
              <Button
                onClick={handleRemoveImage}
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  backgroundColor: "var(--red)",
                  color: "var(--white)",
                  "&:hover": {
                    backgroundColor: "#e96868",
                  },
                }}
              >
                <DeleteIcon sx={{ marginRight: "5px" }} />
                {i18n.t("general.ocr.ocrDialog.removeImage")}
              </Button>
            </>
          ) : null}
        </div>
      </div>
      <DialogContent
        className={styles.mozDiv}
        sx={{
          display: isMobile ? "none" : "flex",
          fontSize: "1rem",
        }}
      >
        {i18n.t("general.ocr.ocrDialog.or")}
      </DialogContent>
      <div className={styles.interaction}>
        <Button
          sx={{
            width: "180px",
            height: "110px",
            backgroundColor: "var(--green)",
            color: "var(--white)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: "1rem",
            "&:hover": {
              backgroundColor: "var(--dark-green)",
            },
            // media screen
            "@media screen and (max-width: 768px)": {
              width: "250px",
              height: "110px",
            },
          }}
          onClick={handleFileUpload}
          id="ocr-file-button"
        >
          <FileUploadIcon
            sx={{ width: "2.3rem", height: "2.3rem", marginBottom: "10px" }}
          />
          {buttonText}
        </Button>
        {/* Hidden file input */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          id="ocr-file-input"
          onChange={async (event) => {
            const file = event.target.files?.[0];

            if (file) {
              try {
                setIsLoading(true);

                const response = await sendOcrInjectable(file, token, sessionId);

                if (response && response.length > 0) {
                  const defaultIds = ["d-532", "d-517"];

                  const updatedResponse = [...defaultIds, ...response];

                  setSelNiceIds(updatedResponse);

                  handleOcrCancelButtonClick();
                  handleCreateOrUpdateOcrCancelButtonClick();
                  setIsLoading(false);
                } else {
                  alert("Aucun médicament trouvé dans l'ordonnance.");
                  setIsLoading(false);
                }
              } catch (error) {
                console.error("Erreur lors de la requête :", error);

                // Arrêtez le chargement en cas d'erreur
                setIsLoading(false);
              }
            } else {
              console.error("Aucun fichier sélectionné.");
            }
          }}
        />
        <Button
          sx={{
            display: isMobile ? "none" : "flex",
            width: "180px",
            height: "110px",
            backgroundColor: "var(--green)",
            color: "var(--white)",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: "1rem",

            "&:hover": {
              backgroundColor: "var(--dark-green)",
            },
            // media screen
            "@media screen and (max-width: 768px)": {
              width: "250px",
              height: "110px",
            },
          }}
          onClick={handleOpenCamera}
        >
          <PhotoCameraIcon
            sx={{ width: "2.3rem", height: "2.3rem", marginBottom: "10px" }}
          />
          {i18n.t("general.ocr.ocrDialog.scan")}
        </Button>

        {isCameraOpen && ( // Affichez la caméra uniquement lorsque 'isCameraOpen' est vrai
          <div className={styles.cameraContainer}>
            {countdown !== null && <div className={styles.retardateur}>{countdown}</div>}
            <Button onClick={handleClick}>
              {" "}
              <CameraswitchIcon sx={{ marginRight: "10px" }} />
              {i18n.t("general.ocr.ocrDialog.changeCam")}
            </Button>
            <Webcam
              audio={false}
              ref={webcamRef}
              height={webcamHeight}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                ...videoConstraints,
                facingMode,
              }}
            />
            <div className={styles.interaction__camera}>
              <Button
                sx={{
                  width: "180px",
                  backgroundColor: "var(--red)",
                  color: "var(--white)",
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: "#e96868",
                  },
                }}
                onClick={() => setIsCameraOpen(false)}
              >
                {i18n.t("general.ocr.close")}
              </Button>
              <Button
                sx={{
                  width: "180px",
                  backgroundColor: "var(--green)",
                  color: "var(--white)",
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: "var(--dark-green)",
                  },
                }}
                onClick={handleCapturePhoto} // Capturez la photo lorsque le bouton est cliqué
              >
                {i18n.t("general.ocr.ocrDialog.capture")}
              </Button>
            </div>
          </div>
        )}
      </div>
      <Button
        sx={{
          width: "auto",
          height: "25px",
          fontSize: "0.9rem",
          display: "flex",
          marginTop: "12px",
          justifyContent: "center",
          alignItems: "center",
          color: "var(--green)",
          borderRadius: "2px",
          textTransform: "none",
          textDecoration: "underline",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            color: "var(--dark-green)",
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
        }}
        onClick={handleOcrTextButtonClick}
        id="ocr-text-button"
      >
        {i18n.t("general.ocr.ocrDialog.text")}
      </Button>

      <OcrTextDialog
        open={ocrTextDialogIsOpen}
        handleOcrTextCancelButtonClick={handleOcrTextCancelButtonClick}
      />

      <DialogActions>
        <Button
          sx={{
            border: "2px solid var(--red)",
            width: "110px",
            height: "33px",
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "var(--red)",
            color: "var(--white)",
            borderRadius: "2px",

            "&:hover": {
              border: "2px solid var(--red)",
              color: "var(--red)",
              backgroundColor: "transparent",
            },
          }}
          onClick={handleOcrCancelButtonClick}
        >
          {i18n.t("general.ocr.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
