import { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { defaultRcpData } from "../AppContext";
import { API_URL_IV_RCP_DATA } from "../constants";

const useFetchRcpData = (niceIdState: string | null, token: string | null) => {
  const [data, setData] = useState<RcpDataId>(defaultRcpData);
  const [isError, setIsError] = useState(false);
  const [isUnauthorizedRcpData, setIsUnauthorizedRcpData] = useState(false);
  const [isInternalErrRcpData, setIsInternalErrRcpData] = useState(false);

  const fetchData = async () => {
    setIsError(false);

    try {
      if (!token || !niceIdState) {
        return;
      }

      const result = await axios.get<RcpDataId>(`${API_URL_IV_RCP_DATA}/${niceIdState}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setData(result.data);
    } catch (error) {
      setIsError(true);

      if (axios.isAxiosError(error)) {
        const responseStatus = (error as AxiosError).response?.status;

        if (responseStatus === 401) {
          setIsUnauthorizedRcpData(true);
        } else if (responseStatus === 500) {
          setIsInternalErrRcpData(true);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [token, niceIdState]);

  return {
    data,
    isError,
    isUnauthorizedRcpData,
    isInternalErrRcpData,
  };
};

export default useFetchRcpData;
