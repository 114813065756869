import React, { useRef } from "react";
import styles from "./OthersScreen.module.css";
import PanelOther from "../../components/Others/PanelOther/PanelOther";
import IncompatibilitiesViewOther from "../../components/Others/IncompatibilitiesViewOther/IncompatibilitiesViewOther";

export default function OthersScreen() {
  const printRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.main}>
      <PanelOther printRef={printRef} />
      <IncompatibilitiesViewOther ref={printRef} />
    </div>
  );
}
