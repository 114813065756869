// usePostDataPci.ts

import { useState } from "react";
import axios, { AxiosError } from "axios";
import { API_URL_IV_PCI_BIBLIO_REF } from "../constants";

const usePostDataPci = (biblioRefs: number[], token: string | null) => {
  const [postResult, setPostResult] = useState<any>(null);

  const postDataPci = async () => {
    try {
      if (!token || !biblioRefs.length) {
        return;
      }

      const postData = {
        biblio_ids: biblioRefs,
      };

      const result = await axios.post(API_URL_IV_PCI_BIBLIO_REF, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Stockez la réponse dans l'état
      setPostResult(result.data);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        // console.log(axiosError.response.data);
        // console.log(axiosError.response.status);
        // console.log(axiosError.response.headers);
      } else if (axiosError.request) {
        // console.log(axiosError.request);
      } else {
        // console.log("Error", axiosError.message);
      }
      // console.log(axiosError.config);
    }
  };

  return {
    postDataPci,
    postResult,
  };
};

export default usePostDataPci;
