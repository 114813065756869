import styles from "./InjectablesRampesScreen.module.css";
import DrugsIncompatibilitiesView from "../../components/InjectablesRampes/DrugsIncompatibilitiesView/DrugsIncompatibilitiesView";
import BlocksView from "../../components/InjectablesRampes/BlocksView/BlocksView";

type Props = {
  setRecompute: (recompute: boolean) => void;
};

export default function InjectablesRampesScreen({ setRecompute }: Props) {
  return (
    <div className={styles.main}>
      <DrugsIncompatibilitiesView setRecompute={setRecompute} />
      <BlocksView setRecompute={setRecompute} />
    </div>
  );
}
