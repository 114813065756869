import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import styles from "./BlockDrugIncomp.module.css";

type Props = {
  targetType: "solvent" | "drug" | "material";
  targetName: string;
  targetIncomp: string;
  niceId: string;
};

export default function BlockDrugIncomp({
  targetName,
  targetType,
  targetIncomp,
  niceId,
}: Props) {
  let classType = targetType === "solvent" ? styles.solvent : styles.drug;
  if (
    (targetType === "drug" && niceId === "s-16587") ||
    (targetType === "drug" && niceId === "s-16572")
  ) {
    classType = styles.true__solvent;
  }

  const classIncomp = targetIncomp === "no_info" ? styles.noInfo : styles.incompatible;
  const classCompat =
    targetIncomp === "compatible" ? styles.compatible : styles.incompatible;

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  let idIncomp;

  if (targetIncomp === "no_info") {
    idIncomp = `rampe-no-info-${niceId}`;
  } else if (targetIncomp === "compatible") {
    idIncomp = `rampe-compatible-${niceId}`;
  } else {
    idIncomp = `rampe-incompatible-${niceId}`;
  }

  let tooltipLayout;

  if (window.innerWidth < 768) {
    tooltipLayout = (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={openTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            // eslint-disable-next-line no-nested-ternary
            targetIncomp === "compatible"
              ? `${targetName} compatible`
              : targetIncomp !== "no_info"
              ? "Plus d'informations"
              : `Information inconnue ${targetName}`
          }
          followCursor
        >
          <div
            className={`${styles.main} ${classType} ${classIncomp} ${classCompat}`}
            onClick={() => handleTooltipOpen()}
            id={`${idIncomp}+${niceId}`}
          />
        </Tooltip>
      </ClickAwayListener>
    );
  } else {
    tooltipLayout = (
      <Tooltip
        title={targetIncomp === "compatible" ? `${targetName} compatible` : targetName}
        followCursor
      >
        <div
          className={`${styles.main} ${classType} ${classIncomp} ${classCompat}`}
          id={`${idIncomp}+${targetType}`}
        />
      </Tooltip>
    );
  }

  return <> {tooltipLayout}</>;
}
