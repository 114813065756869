import axios from "axios";
import { API_URL_OCR_PRESCRIPTIONS_ORAL } from "../constants";

export default async function sendOcrOral(
  image: File | null,
  token: string | null,
  sessionId: string | null,
): Promise<string[] | null> {
  if (!token || !sessionId || !image) return null;

  const url = API_URL_OCR_PRESCRIPTIONS_ORAL;
  const formData = new FormData();

  formData.append("image", image);

  try {
    const response = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      const ocrData = response.data;
      return ocrData;
    }

    return null;
  } catch (err) {
    return null;
  }
}
