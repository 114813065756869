import React, { useContext, forwardRef } from "react";
import AppContext from "../../../AppContext";
import styles from "./IncompatibilitiesViewOther.module.css";
import DrugIncompatibilitiesOther from "../DrugIncompatibilitiesOther/DrugIncompatibilitiesOther";

const IncompatibilitiesViewOther = forwardRef<HTMLDivElement>((props, ref) => {
  const { selMedsOraux, medsOrauxInfos, removeMedOral } = useContext(AppContext);

  const selItems: any[] = [];
  const addedNiceIds: string[] = [];

  for (const item of medsOrauxInfos) {
    if (selMedsOraux.includes(item.nice_id) && !addedNiceIds.includes(item.nice_id)) {
      addedNiceIds.push(item.nice_id);
      selItems.push(item);
    }
  }

  return (
    <div ref={ref} className={styles.main}>
      <div className={styles.incompatibilities}>
        {selItems.map((medOralInfos) => (
          <DrugIncompatibilitiesOther
            medOralInfos={medOralInfos}
            key={medOralInfos.nice_id}
            removeMedOral={removeMedOral}
          />
        ))}
      </div>
    </div>
  );
});

export default IncompatibilitiesViewOther;
