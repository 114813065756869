import axios from "axios";
import { API_URL_TRACK_RESTART_DRUGS } from "../constants";

export default async function sendTrackRestart(
  selNiceIds: string[],
  token: string | null,
  sessionId: string | null,
  currPath: string,
  currSelMedsOraux: string[],
): Promise<boolean> {
  if (!token || !sessionId) {
    console.error("token or sessionId not defined", { token, sessionId });
    return false;
  }

  const url = API_URL_TRACK_RESTART_DRUGS;
  const bodyReq = {
    curr_nice_ids: selNiceIds,
    session_id: sessionId,
    curr_route: currPath,
    curr_sel_meds_oraux: currSelMedsOraux,
  };

  try {
    const response = await axios.post(url, bodyReq, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.status === 200;
  } catch (err) {
    console.error(err);
    return false;
  }
}
