import { useState, Suspense } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import styles from "./App.module.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import MainContent from "../../../screens/MainContentScreen/MainContentScreen";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00887d",
    },
    secondary: {
      main: "#F5F5F5",
    },
  },
});

function App() {
  const [legalNoticeOpen, setLegalNoticeOpen] = useState(false);
  const [rampShown, setRampShown] = useState(true);
  const [recompute, setRecompute] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.main}>
        <Header rampShown={rampShown} setRampShown={setRampShown} />
        <MainContent
          legalNoticeOpen={legalNoticeOpen}
          setLegalNoticeOpen={setLegalNoticeOpen}
          rampShown={rampShown}
          recompute={recompute}
          setRecompute={setRecompute}
        />
        <Footer setLegalNoticeOpen={setLegalNoticeOpen} />
      </div>
    </ThemeProvider>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="">
      <App />
    </Suspense>
  );
}
