import { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { defaultAdminGuide } from "../AppContext";
import { API_URL_IV_GUIDE_ADMIN } from "../constants";

const useFetchAdministration = (
  niceIdAdminGuide: string | null,
  token: string | null,
) => {
  const [data, setData] = useState<AdminGuideDataId>(defaultAdminGuide);
  const [isError, setIsError] = useState(false);
  const [isUnauthorizedAdminGuide, setIsUnauthorizedAdminGuide] = useState(false);
  const [isInternalErrAdminGuide, setIsInternalErrAdminGuide] = useState(false);

  const fetchData = async () => {
    setIsError(false);

    try {
      if (!token || !niceIdAdminGuide) {
        return;
      }

      const result = await axios.get<AdminGuideDataId>(
        `${API_URL_IV_GUIDE_ADMIN}/${niceIdAdminGuide}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setData(result.data);
    } catch (error) {
      setIsError(true);

      if (axios.isAxiosError(error)) {
        const responseStatus = (error as AxiosError).response?.status;

        if (responseStatus === 401) {
          setIsUnauthorizedAdminGuide(true);
        } else if (responseStatus === 500) {
          setIsInternalErrAdminGuide(true);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [token, niceIdAdminGuide]);

  return {
    data,
    isError,
    isUnauthorizedAdminGuide,
    isInternalErrAdminGuide,
  };
};

export default useFetchAdministration;
