import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
};

export default function ThanksFeedbackModal({ isOpen }: Props) {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{i18n.t("Menu.FeedbackModal.ThanksTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {i18n.t("Menu.FeedbackModal.ThanksMessage")}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
