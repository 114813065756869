import { useRef, useContext } from "react";
import styles from "./DrugsIncompatibilitiesView.module.css";
import FilterPrescriptions from "../FilterPrescriptions/FilterPrescriptions";
import FilterAutocomplete from "../FilterAutocomplete/FilterAutocomplete";
import IncompatibilitiesRampes from "../IncompatibilitiesRampes/IncompatibilitiesRampes";
import AppContext from "../../../AppContext";

type Props = {
  setRecompute: (recompute: boolean) => void;
};

export default function DrugsIncompatibilitiesView({ setRecompute }: Props) {
  const { rampShown } = useContext(AppContext);
  const searchInput = useRef<HTMLInputElement | null>(null);

  return (
    <div className={!rampShown ? styles.main : `${styles.main}, ${styles.hidden}`}>
      <div className={styles.container__filter}>
        <FilterAutocomplete searchInput={searchInput} setRecompute={setRecompute} />
      </div>
      <IncompatibilitiesRampes setRecompute={setRecompute} />
      <div className={styles.container__filter}>
        <FilterPrescriptions setRecompute={setRecompute} />
      </div>
    </div>
  );
}
