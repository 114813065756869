import env from "react-dotenv";

export const SOLVENT_NICE_IDS = JSON.parse(env.SOLVENT_NICE_IDS || "[]") as string[];
const INIT_INJ_DRUG_IDS_DEV = JSON.parse(env.INIT_INJ_DRUG_IDS_DEV || "[]") as number[];
const INIT_INJ_DRUG_IDS_MAIN = JSON.parse(env.INIT_INJ_DRUG_IDS_MAIN || "[]") as number[];
const INIT_OTHER_DRUG_IDS_DEV = JSON.parse(
  env.INIT_OTHER_DRUG_IDS_DEV || "[]",
) as number[];
const INIT_OTHER_DRUG_IDS_MAIN = JSON.parse(
  env.INIT_OTHER_DRUG_IDS_MAIN || "[]",
) as number[];

export const INIT_INJ_DRUG_IDS =
  env.CI_COMMIT_REF_NAME === "main" ? INIT_INJ_DRUG_IDS_MAIN : INIT_INJ_DRUG_IDS_DEV;

export const INIT_OTHER_DRUG_IDS =
  env.CI_COMMIT_REF_NAME === "main" ? INIT_OTHER_DRUG_IDS_MAIN : INIT_OTHER_DRUG_IDS_DEV;

export const INIT_INJ_NICE_IDS = INIT_INJ_DRUG_IDS.map((drugId) => `s-${drugId}`);
export const INIT_OTHERS_NICE_IDS = INIT_OTHER_DRUG_IDS.map((drugId) => `ms-${drugId}`);
export const DEFAULT_SOLVENT_ID = SOLVENT_NICE_IDS[0];
export const INIT_NUM_BLOCKS = Number(env.INIT_NUM_BLOCKS || "2");
export const MAX_NUM_BLOCKS = Number(env.MAX_NUM_BLOCKS || "2");
export const MAX_COMBS_OPT = Number(env.MAX_COMBS_OPT || "2000000");
export const CI_COMMIT_REF_NAME = env.CI_COMMIT_REF_NAME || "integration";
export const DISPLAY_ALL_ORAL_MOLECULES = env.DISPLAY_ALL_ORAL_MOLECULES as boolean;

export const API_URL_LOGIN = `${env.API_URL}/v0/auth/login`;
export const API_URL_USER_RULES = `${env.API_URL}/v0/user/user_rules`;
export const API_URL_COMMENT_FEEDBACK = `${env.API_URL}/v0/feedback/comment`;
export const API_URL_LIKE_DISLIKE_FEEDBACK = `${env.API_URL}/v0/feedback/like_dislike`;
export const API_URL_TRACK_BIBLIO_REF_INFO = `${env.API_URL}/v0/tracking/track_biblio_ref_info`;
export const API_URL_TRACK_BLOCKS = `${env.API_URL}/v0/tracking/track_blocks`;
export const API_URL_TRACK_DRUG_INFO = `${env.API_URL}/v0/tracking/track_drug_info`;
export const API_URL_TRACK_RESTART_DRUGS = `${env.API_URL}/v0/tracking/track_restart_drugs`;
export const API_URL_TRACK_SEL_ITEM = `${env.API_URL}/v0/tracking/track_selected_drugs`;
export const API_URL_IV_DRUG_SYNONYMS = `${env.API_URL}/v0/iv_drugs/list_iv_drug_synonyms`;
export const API_URL_IV_INCOMP_DRUGS = `${env.API_URL}/v0/iv_drugs/iv_drugs_pci`;
export const API_URL_IV_RCP_DATA = `${env.API_URL}/v0/iv_drugs/rcp_data`;
export const API_URL_IV_GUIDE_ADMIN = `${env.API_URL}/v0/iv_drugs/administration`;
export const API_URL_IV_PCI_BIBLIO_REF = `${env.API_URL}/v0/iv_drugs/pci_biblio_ref`;
export const API_URL_IV_PCI_BIBLIO = `${env.API_URL}/v0/iv_drugs/iv_pci_biblio`;
export const API_URL_ORAL_DRUGS_INFO = `${env.API_URL}/v0/oral_drugs/oral_drug_infos`;
export const API_URL_ORAL_DRUG_SYNONYMS = `${env.API_URL}/v0/oral_drugs/list_oral_drug_synonyms`;
export const API_URL_PRESCRIPTIONS_INJECTABLE = `${env.API_URL}/v0/prescriptions/injectable`;
export const API_URL_PRESCRIPTIONS_ORAL = `${env.API_URL}/v0/prescriptions/oral`;
export const API_URL_OCR_PRESCRIPTIONS_INJECTABLE = `${env.API_URL}/v0/prescriptions/scan_ocr?type=injectable`;
export const API_URL_OCR_PRESCRIPTIONS_ORAL = `${env.API_URL}/v0/prescriptions/scan_ocr?type=oral`;
export const API_URL_SCAN_PRESCRIPTIONS_TEXT = `${env.API_URL}/v0/prescriptions/scan_prescription_text`;
export const API_URL_BIGS_FIRST = `${env.API_URL}/v0/algorithms/bigs_first_algorithm`;

export const BLOCK_RULES: RuleDefinition[] = [
  {
    id: "1-if-possible",
    description:
      "Règle 1 : Administrer les médicaments via cette voie si aucune incompatibilité n'est créée.",
  },
  {
    id: "2-alone-if-present",
    description: "Règle 2 : Administrer uniquement les médicaments via cette voie.",
  },
  {
    id: "3-reserve-if-present",
    description:
      "Règle 3 : Si les médicaments sont prescrits, réserver et administrer uniquement via cette voie.",
  },
  {
    id: "4-reserve-always",
    description:
      "Règle 4 : Voie réservée pour les médicaments, qu'ils soient prescrits ou non.",
  },
];
