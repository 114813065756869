import axios from "axios";
import { API_URL_TRACK_BIBLIO_REF_INFO } from "../constants";

export default async function sendTrackBiblioRefInfo(
  token: string | null,
  sessionId: string | null,
  niceIdA: string | null,
  niceIdB: string | null,
): Promise<boolean> {
  if (!token || !sessionId) return false;

  const url = API_URL_TRACK_BIBLIO_REF_INFO;
  const bodyReq = {
    session_id: sessionId,
    curr_route: "/injectables",
    nice_id_a: niceIdA,
    nice_id_b: niceIdB,
  };

  try {
    const response = await axios.post(url, bodyReq, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.status === 200;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return false;
  }
}
