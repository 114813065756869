import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import AppContext from "../../../AppContext";
import styles from "./ListePresciptionsInjectables.module.css";
import deleteInjectablePrescriptions from "../../../hooks/deleteInjectablePrescriptions";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function PrescriptionsDialog({ isOpen, setIsOpen }: Props) {
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(true);
  const [checkboxStates, setCheckboxStates] = useState<boolean[]>([]);

  const { ivPrescriptionsData, token, sessionId, setUpdateIvPrescriptionsData } =
    useContext(AppContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsButtonsDisabled(true);

    if (checkboxStates.length > 0) {
      const isAnyChecked = checkboxStates.some((state) => state);
      setIsButtonsDisabled(!isAnyChecked);
    }
  }, [checkboxStates]);

  useEffect(() => {
    setCheckboxStates(ivPrescriptionsData.map(() => false));
  }, [ivPrescriptionsData]);

  useEffect(() => {
    const allChecked = checkboxStates.every((state) => state);
    setIsChecked(allChecked);
  }, [checkboxStates]);

  const handleDelete = () => {
    setOpen(false);
    const selectedPrescriptions: number[] = [];

    checkboxStates.forEach((state, index) => {
      if (state) {
        selectedPrescriptions.push(ivPrescriptionsData[index].id);
      }
    });

    if (ivPrescriptionsData) {
      selectedPrescriptions.forEach((prescriptionId) => {
        deleteInjectablePrescriptions(prescriptionId, token, sessionId);
      });
      setUpdateIvPrescriptionsData(true);
    }

    setIsButtonsDisabled(true);
  };

  const handleCheckboxChange = (index: number) => {
    const updatedStates = [...checkboxStates];
    updatedStates[index] = !updatedStates[index];
    setCheckboxStates(updatedStates);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const updatedStates = checkboxStates.map(() => checked);
    setCheckboxStates(updatedStates);

    setIsChecked(checked);
    setIsButtonsDisabled(!checked || updatedStates.length === 0);
  };

  return (
    <>
      <div className={styles.container__listes}>
        <h2 className={styles.title}>
          {i18n.t("Menu.ListeOralPresciptionsInjectables.title")}
        </h2>
        <div className={styles.scroll__section}>
          <div className={styles.liste__prescriptions} id="no-prescription-iv">
            {ivPrescriptionsData.length === 0 ? (
              <p>
                {i18n.t("Menu.ListeOralPresciptionsInjectables.noPrescriptionMessage")}
              </p>
            ) : (
              ivPrescriptionsData.map(
                (prescription: InjectablePrescriptions, index: number) => (
                  <div key={`${prescription.id}_${prescription.name}`}>
                    {" "}
                    <input
                      type="checkbox"
                      id={`${prescription.id}`}
                      name={prescription.name}
                      className="prescription-checkbox"
                      checked={checkboxStates[index]}
                      onChange={() => handleCheckboxChange(index)}
                    />
                    <label htmlFor={`${prescription.id}`}>{prescription.name}</label>
                  </div>
                ),
              )
            )}
          </div>
        </div>
        <div className={styles.liste__all__prescriptions}>
          <div>
            <input
              type="checkbox"
              id="selectAllIv"
              name="select-all"
              checked={isChecked}
              onChange={handleSelectAll}
            />
            <label htmlFor="selectAll">
              {i18n.t("Menu.ListeOralPresciptionsInjectables.selectAllLabel1")}{" "}
              {ivPrescriptionsData.length}{" "}
              {i18n.t("Menu.ListeOralPresciptionsInjectables.selectAllLabel2")}
            </label>
          </div>
        </div>
      </div>

      <DialogActions
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          id="delete-prescription-iv"
          sx={{
            border: "2px solid var(--red)",
            width: "120px",
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "var(--red)",
            color: "var(--white)",
            "&:hover": {
              border: "2px solid var(--red)",
              color: "var(--red)",
              backgroundColor: "transparent",
            },
          }}
          disabled={isButtonsDisabled || ivPrescriptionsData.length === 0}
          onClick={handleOpen}
        >
          {i18n.t("Menu.ListeOralPresciptionsInjectables.deleteButton")}
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n.t("Menu.ListeOralPresciptionsInjectables.deleteConfirmationTitle")}
          </DialogTitle>
          <DialogActions>
            <Button id="confirm-delete" onClick={handleDelete}>
              {i18n.t("Menu.ListeOralPresciptionsInjectables.deleteConfirmationConfirm")}
            </Button>
            <Button onClick={handleClose}>
              {i18n.t("Menu.ListeOralPresciptionsInjectables.deleteConfirmationCancel")}
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          sx={{
            border: "2px solid var(--dark-grey)",
            width: "120px",
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "var(--dark-grey)",
            color: "var(--white)",
            "&:hover": {
              border: "2px solid var(--dark-grey)",
              color: "var(--dark-grey)",
              backgroundColor: "transparent",
            },
          }}
          onClick={() => setIsOpen(false)}
        >
          Annuler
        </Button>
      </DialogActions>
    </>
  );
}
