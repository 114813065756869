import { useContext } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AppContext from "../../../AppContext";
import styles from "./CompSwitch.module.css";
import i18n from "../../../i18n";

type Props = {
  setRecompute: (recompute: boolean) => void;
};

export default function CompSwitch({ setRecompute }: Props) {
  const {
    showNoInfoIncomp,
    setShowNoInfoIncomp,
    showCompatibilities,
    setShowCompatibilities,
    showCompMaterial,
    setShowCompMaterial,
  } = useContext(AppContext);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowNoInfoIncomp(event.target.checked);
    const compSettings = {
      showCompatibilities,
      showNoInfoIncomp: event.target.checked,
      showCompMaterial,
    };
    localStorage.setItem("compSettings", JSON.stringify(compSettings));
  };
  const handleSwitchChangeComp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowCompatibilities(event.target.checked);
    const compSettings = {
      showCompatibilities: event.target.checked,
      showNoInfoIncomp,
      showCompMaterial,
    };
    localStorage.setItem("compSettings", JSON.stringify(compSettings));
  };
  const handleSwitchMaterial = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowCompMaterial(event.target.checked);
    const compSettings = {
      showCompatibilities,
      showNoInfoIncomp,
      showCompMaterial: event.target.checked,
    };
    localStorage.setItem("compSettings", JSON.stringify(compSettings));
  };

  return (
    <div className={styles.toggles}>
      <div className={styles.injectable}>
        <h3>{i18n.t("general.setting.comp-switch.title")}</h3>
        <div className={styles.compatible}>
          <FormGroup className={styles.switch}>
            <FormControlLabel
              sx={{ display: "flex", justifyContent: "start" }}
              control={
                <Switch
                  checked={showCompatibilities}
                  onChange={handleSwitchChangeComp}
                  id="compatible-switch"
                />
              }
              label={i18n.t("general.setting.comp-switch.compatible-switch")}
              className={styles.label}
            />
          </FormGroup>
        </div>
        <div className={styles.notKnown}>
          <FormGroup className={styles.switch}>
            <FormControlLabel
              sx={{ display: "flex", justifyContent: "start" }}
              control={
                <Switch
                  checked={showNoInfoIncomp}
                  onChange={handleSwitchChange}
                  id="no-info-switch"
                />
              }
              label={i18n.t("general.setting.comp-switch.no-info-switch")}
              className={styles.label}
            />
          </FormGroup>
        </div>
        <div className={styles.material}>
          <FormGroup className={styles.switch}>
            <FormControlLabel
              sx={{ display: "flex", justifyContent: "start" }}
              control={
                <Switch checked={showCompMaterial} onChange={handleSwitchMaterial} />
              }
              label={i18n.t("general.setting.comp-switch.material-switch")}
              className={styles.label}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
}
