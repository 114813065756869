import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CreateOrUpdateOcrDialog from "../CreateOrUpdateOcrDialog/CreateOrUpdateOcrDialog";
import styles from "./FilterAutocomplete.module.css";
import AppContext from "../../../AppContext";
import i18n from "../../../i18n";

type Props = {
  searchInput: React.MutableRefObject<HTMLInputElement | null>;
  setRecompute: (recompute: boolean) => void;
};

export default function FilterAutocomplete({ searchInput, setRecompute }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [createOrUpdateOcrDialogIsOpen, setCreateOrUpdateOcrDialogIsOpen] =
    useState(false);

  const handleOcrButtonClick = () => {
    setCreateOrUpdateOcrDialogIsOpen(true);
  };

  const handleCreateOrUpdateOcrCancelButtonClick = () => {
    setCreateOrUpdateOcrDialogIsOpen(false);
  };

  const {
    selDrugIds,
    modifyFilterText,
    allItems,
    addSelItem,
    selNiceIds,
    resetSelItems,
  } = useContext(AppContext);

  const [inputValue, setInputValue] = useState("");
  const allSynonyms = allItems.filter((item) => item.type === "synonym");
  const allOptions = allSynonyms.map((item) => ({
    nice_id: item.nice_id,
    name: item.name,
    drug_id: item.type === "drug" ? item.id : (item as Synonym).drug_id,
  }));

  const options = allOptions;

  // Visual parameters

  /**
   *
   * @param text user input
   * Modifies the AppContext and display to take into account user input
   */
  const handleChangeText = (text: string) => {
    modifyFilterText(text);
    setInputValue(text);
    setRecompute(true);
  };

  /**
   *
   * @param newValue the name of an item that has been clicked on in the dropdown menu
   *
   * Checks if the name is null.
   * Checks whether to display the pop up if more than 20 drugs are selected.
   * Checks if the drug named newValue is already in the selected drugs.
   *
   * Adds newValue to the selected drugs if the tests succeed.
   * Resets filter and displayed user input value to "".
   */
  const onClickItem = (newValue: string | null) => {
    if (newValue === null) return;
    if (selNiceIds.length === 20) {
      setDialogOpen(true);
      return;
    }

    // Allow reoptimization:
    setRecompute(true);

    // Add the drug to the selected drugs list
    const selItem = options.find((item) => item.name === newValue);
    if (selItem) {
      if (selDrugIds.includes(selItem.drug_id)) {
        // eslint-disable-next-line no-alert
        alert("Ce médicament est déjà sélectionné avec un nom différent");
      } else {
        addSelItem(selItem.nice_id);
      }
    }

    if (searchInput.current) {
      searchInput.current.focus();
    }

    modifyFilterText("");
    setInputValue("");
  };

  return (
    <div className={styles.main}>
      {/* Actual display, autocomplete and selection mechanism */}
      <Autocomplete
        onChange={(event, newValue) => {
          // Selection mechanism
          onClickItem(newValue?.name || "");
        }}
        disablePortal
        clearOnEscape
        size="small"
        options={options}
        getOptionDisabled={(option) => selDrugIds.includes(option.drug_id)}
        id="search-input"
        sx={{
          width: "100%",
          borderRadius: "0 !important",
          border: "0px solid #ccc",
          marginTop: 3,
          marginBottom: 3,
          "@media (max-width: 1024px)": {
            border: "0px solid var(--grey)",
            backgroundColor: "var(--white)",
            color: "var(--black)",
            borderRadius: "5px !important",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "var(--black)",
            marginTop: "3px",
            "@media (min-width: 769px) and (max-width: 1024px)": {
              color: "var(--white)",
            },
          },
          "@media screen and (max-width: 768px)": {
            width: "100%",
            borderRadius: "0 !important",
            border: "0px solid #ccc",
            marginTop: 0,
            marginBottom: 0,
            padding: 3,
            paddingTop: 1,
          },
        }}
        getOptionLabel={(option) => {
          return option.name;
        }}
        isOptionEqualToValue={(option, value) => {
          return option.drug_id === value.drug_id;
        }}
        renderInput={(params) => {
          // Display of the search bar
          params.inputProps.value = inputValue;
          return (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              className={styles.searchBar}
              label={i18n.t("formesOrales.DrugFilterAutocomplete.label")}
              onChange={(e) => handleChangeText(e.target.value)}
            />
          );
        }}
      />

      {/* ocr */}
      <IconButton
        sx={{
          width: "38px",
          height: "38px",
          marginLeft: "10px",
          backgroundColor: "var(--green)",
          borderRadius: "5px !important",
          color: "var(--white)",
          border: "3px solid var(--green)",
          transition: "all 0.2s ease-out",
          "&:hover": {
            backgroundColor: "transparent",
            color: "var(--green)",
          },
        }}
        onClick={handleOcrButtonClick}
        id="ocr-button"
      >
        <Tooltip placement="top" title={i18n.t("general.ocr.scan")}>
          <PhotoCameraIcon />
        </Tooltip>
      </IconButton>

      <CreateOrUpdateOcrDialog
        open={createOrUpdateOcrDialogIsOpen}
        handleCreateOrUpdateOcrCancelButtonClick={
          handleCreateOrUpdateOcrCancelButtonClick
        }
      />

      {/* Reinitialiser */}
      <IconButton
        sx={{
          width: "38px",
          height: "38px",
          marginLeft: "10px",
          backgroundColor: "var(--red)",
          borderRadius: "5px !important",
          color: "var(--white)",
          border: "3px solid var(--red)",
          transition: "all 0.2s ease-out",
          "&:hover": {
            backgroundColor: "transparent",
            color: "var(--red)",
          },
        }}
        onClick={() => resetSelItems()}
      >
        <Tooltip placement="top" title={i18n.t("general.resetDrug")}>
          <RestartAltIcon />
        </Tooltip>
      </IconButton>

      {/* Dialog to inform the user that he has selected too many drugs */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous avez atteint la limite de 20 médicaments sélectionnés
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
