import axios from "axios";
import { API_URL_COMMENT_FEEDBACK } from "../constants";

export default async function sendCommentFeedback(
  sessionId: string | null,
  token: string | null,
  selNiceIds: string[],
  radioChoice: number,
  comment: string,
  name: string | null,
  email: string | null,
  currPath: string,
  currSelMedsOraux: string[],
) {
  if (!token || !sessionId) return false;

  const url = API_URL_COMMENT_FEEDBACK;

  const bodyReq = {
    curr_nice_ids: selNiceIds,
    satisfaction_level: radioChoice,
    comment,
    name,
    email,
    session_id: sessionId,
    curr_route: currPath,
    curr_sel_meds_oraux: currSelMedsOraux,
  };

  try {
    const response = await axios.post(url, bodyReq, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.status === 200;
  } catch (err) {
    console.error(err);
    return false;
  }
}
