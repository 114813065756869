import { useState, useEffect, useContext } from "react";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import AppContext from "../../../AppContext";
import styles from "./BlockDisplay.module.css";
import Block from "../../../classes/Block";
import BlockDrugDisplay from "../BlockDrugDisplay/BlockDrugDisplay";
import { SOLVENT_NICE_IDS } from "../../../constants";
import sendTrackBlocks from "../../../hooks/sendTrackBlocks";
import i18n from "../../../i18n";

type Props = {
  block: Block;
  numBlock: number;
  onMoveDrug: (drug: DrugOrSynonym, direction: "left" | "right") => void;
};

export default function BlockDisplay({ block, numBlock, onMoveDrug }: Props) {
  const { allItems, token, sessionId } = useContext(AppContext);
  const [solventVal, setSolventVal] = useState(block.solventId);

  // Change solventVal if block changes
  useEffect(() => {
    setSolventVal(block.solventId);
  }, [block]);

  // Create select for solvent
  let solventOptions = null;
  if (allItems.length > 0) {
    solventOptions = SOLVENT_NICE_IDS.map((niceId) => {
      const item = allItems.filter((i) => i.nice_id === niceId)[0];
      return (
        <MenuItem value={niceId} key={`menuitem-${niceId}`} className={styles.menu}>
          {" "}
          {item.name}
        </MenuItem>
      );
    });
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selNiceId = event.target.value;
    block.solventId = selNiceId;
    setSolventVal(selNiceId);

    const selNiceIds = block.drugNiceIds.concat(selNiceId);
    const action = `select_solvent_block_${numBlock}`;

    sendTrackBlocks(selNiceIds, selNiceId, action, token, sessionId);
  };

  return (
    <div className={styles.main} id={`rampe-${numBlock}`}>
      <div className={styles.title}>
        {i18n.t("general.BlockDisplay.lumen")} {numBlock}
      </div>
      <div className={styles.drugs} id={`block-display-${numBlock}`}>
        {block.drugs
          .filter((drug) => drug.is_solvent === false)
          .map((drug) => (
            <BlockDrugDisplay
              drug={drug}
              incompatibilities={block.incompatibilities[drug.nice_id]}
              key={`blockdrugdisplay-${drug.nice_id}`}
              onMoveDrug={onMoveDrug}
            />
          ))}
      </div>
      <div className={styles.selectSolvent}>
        <Select value={solventVal} onChange={handleSelectChange} variant="standard">
          {solventOptions}
        </Select>
      </div>
    </div>
  );
}
