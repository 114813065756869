import { useState, useContext } from "react";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import AppContext from "../../../AppContext";
import styles from "./IncompatibleDrugItemRampes.module.css";
import IncompDetailsRampes from "../IncompDetailsRampes/IncompDetailsRampes";
import sendTrackBiblioRefInfo from "../../../hooks/sendTrackBiblioRefInfo";

type Props = {
  incompDrug: IncompatibleDrug;
};

export default function IncompatibleDrugItemRampes({ incompDrug }: Props) {
  const { token, sessionId, setBiblioRefsId } = useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);

  const handleSendTrackBiblioRefInfo = (niceIdA: string, niceIdB: string) => {
    sendTrackBiblioRefInfo(token, sessionId, niceIdA, niceIdB);
  };

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  let tooltipLayout;
  let idIncomp;

  if (incompDrug.type_incomp === "no_info") {
    idIncomp = `no-info-${incompDrug.nice_id}`;
  } else if (incompDrug.type_incomp === "compatible") {
    idIncomp = `compatible-${incompDrug.nice_id}`;
  } else {
    idIncomp = `incompatible-${incompDrug.nice_id}`;
  }

  if (window.innerWidth < 768) {
    tooltipLayout = (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={openTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            // eslint-disable-next-line no-nested-ternary
            incompDrug.type_incomp === "compatible"
              ? `${incompDrug.name} compatible`
              : incompDrug.type_incomp !== "no_info"
              ? "Plus d'informations"
              : `Information inconnue ${incompDrug.name}`
          }
          followCursor
        >
          <div
            className={`${styles.main} ${
              incompDrug.is_drugoptimal === true ? styles.drugOptimal : ""
            }   ${incompDrug.type_incomp === "no_info" ? styles.no_info : ""} ${
              incompDrug.type_incomp === "compatible" ? styles.compatible : ""
            } ${incompDrug.type_incomp === "no_info" ? styles.layout : ""} ${
              incompDrug.type_incomp === "incomp_material" ? styles.material : ""
            } 
            ${incompDrug.type_incomp === "contradictory" ? styles.contradictory : ""}
            `}
            id={idIncomp}
            onClick={() => {
              if (incompDrug.type_incomp === "incomp_material") {
                handleSendTrackBiblioRefInfo(
                  incompDrug.material_name,
                  incompDrug.nice_id_target,
                );
              } else {
                handleSendTrackBiblioRefInfo(
                  incompDrug.nice_id,
                  incompDrug.nice_id_target,
                );
              }
              setOpenModal(true);
              handleTooltipOpen();
            }}
          >
            {incompDrug.type_incomp === "incomp_material" && (
              <DoDisturbIcon sx={{ width: "16px", height: "16px" }} />
            )}

            {incompDrug.type_incomp === "contradictory" && (
              <img
                className={styles.logo__svg}
                src="/assets/icons/incomp-icon.svg"
                alt="contradictory-logo"
              />
            )}

            {incompDrug.type_incomp === "incomp_material"
              ? [incompDrug.material_name].join(" ")
              : incompDrug.type_incomp !== "no_info" &&
                incompDrug.type_incomp !== "compatible" &&
                [incompDrug.name].join(" ")}
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  } else {
    tooltipLayout = (
      <Tooltip
        title={
          // eslint-disable-next-line no-nested-ternary
          incompDrug.type_incomp === "compatible"
            ? `${incompDrug.name} compatible`
            : incompDrug.type_incomp !== "no_info"
            ? "Plus d'informations"
            : `Information inconnue ${incompDrug.name}`
        }
        followCursor
      >
        <div
          className={`${styles.main} ${
            incompDrug.is_drugoptimal === true ? styles.drugOptimal : ""
          } ${incompDrug.type_incomp === "no_info" ? styles.no_info : ""} ${
            incompDrug.type_incomp === "compatible" ? styles.compatible : ""
          }${incompDrug.type_incomp === "incomp_material" ? styles.material : ""}
          ${incompDrug.type_incomp === "contradictory" ? styles.contradictory : ""}
          
          `}
          id={idIncomp}
          onClick={() => {
            if (incompDrug.type_incomp === "incomp_material") {
              handleSendTrackBiblioRefInfo(
                incompDrug.material_name,
                incompDrug.nice_id_target,
              );
            } else {
              handleSendTrackBiblioRefInfo(incompDrug.nice_id, incompDrug.nice_id_target);
            }
            setOpenModal(true);
            if (incompDrug.type_incomp !== "incomp_material") {
              setBiblioRefsId(incompDrug.incomp_id);
            } else {
              setBiblioRefsId(incompDrug.biblio_refs);
            }
          }}
        >
          {incompDrug.type_incomp === "incomp_material" && (
            <DoDisturbIcon sx={{ width: "16px", height: "16px" }} />
          )}
          {incompDrug.type_incomp === "contradictory" && (
            <img
              className={styles.logo__svg}
              src="/assets/icons/incomp-icon.svg"
              alt="contradictory-logo"
            />
          )}
          {incompDrug.type_incomp === "incomp_material"
            ? [incompDrug.material_name].join(" ")
            : incompDrug.type_incomp !== "no_info" &&
              incompDrug.type_incomp !== "compatible" &&
              [incompDrug.name].join(" ")}
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      {tooltipLayout}
      {openModal && incompDrug.type_incomp !== "no_info" ? (
        <IncompDetailsRampes
          isOpen={openModal}
          handleClose={() => {
            setOpenModal(false);
          }}
          incompatibility={incompDrug}
          // biblioRefs={biblioRefs}
        />
      ) : null}
    </>
  );
}
