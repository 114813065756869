import Link from "@mui/material/Link";
import styles from "./Footer.module.css";
import i18n from "../../../i18n";

type Props = {
  setLegalNoticeOpen: (open: boolean) => void;
};

export default function Footer({ setLegalNoticeOpen }: Props) {
  return (
    <div className={styles.main}>
      <div className={styles.copyright}>Copyright © 2024 | DrugOptimal</div>
      <Link
        onClick={() => setLegalNoticeOpen(true)}
        underline="hover"
        color="secondary"
        style={{ cursor: "pointer" }}
      >
        {i18n.t("footer.legal")}
      </Link>
    </div>
  );
}
