import React, { useEffect, useContext } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppContext from "../../../AppContext";
import styles from "./BlocksMainView.module.css";
import BlockDisplay from "../BlockDisplay/BlockDisplay";

export default function BlocksMainView() {
  // Hook to make the app rsponsive:
  const phoneScreen = useMediaQuery("(max-width: 768px)");
  const {
    settingSet,
    onOptimizeBlocks,
    incompApiData,
    numBlocks,
    blocksSet,
    updateBlockSet,
    currentSetting,
    setErrorMessage,
    setIsErrorDialogOpen,
    applyBigsFirst,
    setApplyBigsFirst,
    removeExactOptResults,
  } = useContext(AppContext);

  useEffect(() => {
    if (applyBigsFirst) {
      onOptimizeBlocks("bigs_first_algorithm", settingSet.getActiveSettingId(numBlocks));
      removeExactOptResults();
      setApplyBigsFirst(false); // Reset the flag after optimization is performed
    }
  }, [applyBigsFirst]);

  // Function to move drugs
  const onMoveDrug = (drug: DrugOrSynonym, direction: "left" | "right") => {
    if (!blocksSet) return;
    try {
      if (direction === "left") {
        blocksSet.moveDrugLeft(drug, currentSetting);
      } else if (direction === "right") {
        blocksSet.moveDrugRight(drug, currentSetting);
      }
    } catch (e) {
      setErrorMessage((e as Error).message);
      setIsErrorDialogOpen(true);
    }
    updateBlockSet();
  };

  // Change numBlocks
  useEffect(() => {
    if (!blocksSet) return;
    blocksSet.numBlocks = numBlocks;
    updateBlockSet();
    setApplyBigsFirst(true);
  }, [numBlocks]);

  // Update when api-dependent props change
  useEffect(() => {
    if (!blocksSet) return;
    blocksSet.incompApiData = incompApiData;
    updateBlockSet();
  }, [incompApiData]);

  const gridStyle: React.CSSProperties = {};

  // A function to render blocks on a computer screen
  function bigScreenSettings() {
    if (numBlocks <= 3) {
      gridStyle.gridTemplateColumns = `repeat(${numBlocks}, 1fr)`;
      gridStyle.gridTemplateRows = "100%";
    } else if (numBlocks === 4) {
      gridStyle.gridTemplateColumns = "repeat(2, 1fr)";
      gridStyle.gridTemplateRows = "repeat(2, 50%)";
    } else {
      gridStyle.gridTemplateColumns = "repeat(3, 1fr)";
      gridStyle.gridTemplateRows = "repeat(2, 50%)";
    }

    if (window.innerWidth <= 1024 && numBlocks <= 3) {
      gridStyle.gridTemplateColumns = `repeat(${numBlocks}, 1fr)`;
      gridStyle.gridTemplateRows = "100%";
    } else if (window.innerWidth <= 1024 && numBlocks === 4) {
      gridStyle.gridTemplateColumns = "repeat(2, 1fr)";
      gridStyle.gridTemplateRows = "repeat(2, 50%)";
    } else if (window.innerWidth <= 1024) {
      gridStyle.gridTemplateColumns = "repeat(2, 1fr)";
      gridStyle.gridTemplateRows = "repeat(3, 50%)";
    }
  }

  // A function to render blocks on a phone screen
  function smallScreenSettings() {
    gridStyle.display = "flex";
  }

  // Actual choice of display depending on screen size:
  switch (phoneScreen) {
    case true:
      smallScreenSettings();
      break;
    default:
      bigScreenSettings();
  }

  return (
    <div className={styles.main} style={gridStyle} id="container-rampes">
      {blocksSet
        ? blocksSet.blocks.map((block, idx) => (
            <BlockDisplay
              block={block}
              numBlock={idx + 1}
              // eslint-disable-next-line react/no-array-index-key
              key={`block-${idx}`}
              onMoveDrug={onMoveDrug}
            />
          ))
        : null}
    </div>
  );
}
