import axios from "axios";
import { API_URL_LIKE_DISLIKE_FEEDBACK } from "../constants";

export default async function sendLikeDislikeFeedback(
  sessionId: string | null,
  didLike: boolean,
  selNiceIds: string[],
  token: string | null,
  currPath: string,
  currSelMedsOraux: string[],
) {
  if (!token || !sessionId) return false;

  const url = API_URL_LIKE_DISLIKE_FEEDBACK;

  const bodyReq = {
    curr_nice_ids: selNiceIds,
    did_like: didLike,
    session_id: sessionId,
    curr_route: currPath,
    curr_sel_meds_oraux: currSelMedsOraux,
  };

  try {
    const response = await axios.post(url, bodyReq, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.status === 200;
  } catch (err) {
    console.error(err);
    return false;
  }
}
