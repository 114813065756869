/* eslint-disable no-nested-ternary */
import { useState, useContext, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import DoNotDisturbOnTwoToneIcon from "@mui/icons-material/DoNotDisturbOnTwoTone";
import AppContext from "../../../AppContext";
import styles from "./IncompDetailsRampes.module.css";
import usePostDataPci from "../../../hooks/usePostDataPci";
import { API_URL_IV_PCI_BIBLIO } from "../../../constants";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  incompatibility: IncompatibleDrug;
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 4,
  pr: 4,
  pb: 1,
  pl: 4,
};

export default function IncompDetails({ isOpen, handleClose, incompatibility }: Props) {
  const { token, biblioRefsId } = useContext(AppContext);
  const { postDataPci, postResult } = usePostDataPci(biblioRefsId, token);

  const [pciBiblio, setPciBiblio] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL_IV_PCI_BIBLIO}/${biblioRefsId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPciBiblio(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (incompatibility.type_incomp !== "incomp_material") {
      fetchData();
    }

    // Clean up function
    return () => {
      // You can perform cleanup here if needed
    };
  }, [biblioRefsId, token]);

  const biblioFields: (keyof BiblioRef)[] = [
    "reference",
    "title",
    "authors",
    "url",
    "comments",
  ];

  const [transformedData, setTransformedData] = useState<BiblioRef[]>([]);

  useEffect(() => {
    postDataPci();
  }, [biblioRefsId]);

  useEffect(() => {
    if (pciBiblio || postResult) {
      if (incompatibility.type_incomp !== "incomp_material") {
        const transformedResult = Object.keys(pciBiblio).map((key, index) => {
          const item = pciBiblio[key];
          return {
            ...item,
            id: index + 1,
          };
        });

        setTransformedData(transformedResult);
      } else {
        const transformedResult = Object.keys(postResult).map((key, index) => {
          const item = postResult[key];
          return {
            ...item,
            id: index + 1,
          };
        });
        setTransformedData(transformedResult);
      }
    }
  }, [postResult, pciBiblio]);

  const [tabRefId, setTabRefId] = useState<number | null>(null);

  useEffect(() => {
    if (transformedData.length > 0) {
      setTabRefId(transformedData[0].id);
    }
  }, [transformedData]);

  function capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  // Generate biblio lines
  const drugsTableLines =
    incompatibility.type_incomp === "incomp_material"
      ? [
          <tr
            key={`line-${incompatibility.name}-${incompatibility.name_target}-name-drugs`}
          >
            <td>
              <strong>{i18n.t("general.IncompDetails.material")}</strong>
            </td>
            <td>{incompatibility.material_name}</td>
          </tr>,
        ]
      : [
          <tr
            key={`line-${incompatibility.name}-${incompatibility.name_target}-name-drugs`}
          >
            <td>
              <strong>{i18n.t("general.IncompDetails.drugs")}</strong>
            </td>
            <td>
              {incompatibility.name_target} - {incompatibility.name}
            </td>
          </tr>,
        ];

  function getBiblioTableLines(biblioRef: BiblioRef): JSX.Element[] {
    return biblioFields.map((fieldName) => {
      const val = (biblioRef[fieldName] || "") as string;

      let tableVal: JSX.Element | string;

      if (fieldName === "url" && val.length > 0) {
        tableVal = (
          <a
            href={val.replace("https//", "https://").replace("http//", "http://")}
            target="_blank"
            rel="noreferrer"
          >
            Link
          </a>
        );
      } else if (fieldName === "title") {
        tableVal = <em>{val}</em>;
      } else {
        tableVal = val;
      }
      const translatedFieldName =
        fieldName !== "url" ? i18n.t(`general.IncompDetails.${fieldName}`) : fieldName;
      return (
        <tr
          key={`${fieldName}-${incompatibility.name}-${incompatibility.name_target}-${biblioRef.id}`}
        >
          <td>
            <strong>{capitalize(translatedFieldName)}</strong>
          </td>
          <td>{tableVal}</td>
        </tr>
      );
    });
  }

  function getBiblioTab(biblioRef: BiblioRef): JSX.Element {
    return (
      <div
        role="tabpanel"
        className={styles.tabpanel}
        hidden={tabRefId !== biblioRef.id}
        id={`tabpanel-${biblioRef.id}`}
        key={`tab-${incompatibility.name}-${incompatibility.name_target}-${biblioRef.id}`}
      >
        <table>
          <tbody>{[...drugsTableLines, ...getBiblioTableLines(biblioRef)]}</tbody>
        </table>
      </div>
    );
  }

  function getTabs(): JSX.Element {
    return (
      <div className={styles.container_reference}>
        <Tabs value={tabRefId} onChange={(_, newValue) => setTabRefId(newValue)}>
          {transformedData.map((biblioRef, counter) => (
            <Tab
              className={styles.reference}
              label={
                <div>
                  {i18n.t("general.IncompDetails.reference")}&nbsp;
                  {counter + 1}
                  {biblioRef.is_compatible ? (
                    <CircleTwoToneIcon
                      style={{ color: "var(--green)", fontSize: "16px" }}
                    />
                  ) : (
                    <DoNotDisturbOnTwoToneIcon
                      style={{ color: "red", fontSize: "16px" }}
                    />
                  )}
                </div>
              }
              id={`tabpanel-${biblioRef.id}`}
              key={`tab-${biblioRef.id}`}
              value={biblioRef.id}
            />
          ))}
        </Tabs>
        {transformedData.map((b) => getBiblioTab(b))}
      </div>
    );
  }
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        className={styles.box}
        sx={boxStyle}
        id={`incomp-detail-${incompatibility.nice_id}`}
      >
        <div className={styles.main}>
          <div className={styles.title}>
            <div>
              {incompatibility.type_incomp === "compatible"
                ? i18n.t("general.IncompDetails.title-comp")
                : incompatibility.type_incomp === "incompatible"
                ? i18n.t("general.IncompDetails.title-incomp")
                : i18n.t("general.IncompDetails.title-material")}
            </div>
          </div>
          {getTabs()}

          <Button
            variant="text"
            className={styles.closeButton}
            onClick={() => handleClose()}
            id="close-incomp-details"
          >
            {i18n.t("general.IncompDetails.close")}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
