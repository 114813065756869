import { BLOCK_RULES } from "../constants";

export default class Settings {
  numberBlocks: number;
  rules: BlockRule[];
  id: number | null;
  isActive: boolean;
  hasChanged: boolean; // To know whether the activation has changed

  constructor(numberBlocks: number, rules: BlockRule[]) {
    this.numberBlocks = numberBlocks;
    this.rules = rules;
    this.id = null;
    const storedIsActive = localStorage.getItem("isActive");
    this.isActive = storedIsActive !== null ? JSON.parse(storedIsActive) : true;
    this.hasChanged = true;
  }

  resetHasChanged() {
    this.hasChanged = false;
  }

  setActive(cond: boolean) {
    if (this.isActive !== cond) {
      this.hasChanged = !this.hasChanged;
    }
    this.isActive = cond;
  }

  get activeId() {
    if (this.isActive) {
      return this.id;
    }
    return null;
  }

  /**
   * A function to update the settings based on new info.
   * Checks nothing.
   * @param rules the new rules of the setting
   * @param numberBlocks the new number of blocks of the setting.
   */
  updateSetting(rules: BlockRule[], numberBlocks: number) {
    this.rules = rules;
    this.numberBlocks = numberBlocks;
  }

  static fromJSON(settingJson: JSON, allItems: DrugSynonymArray): Settings {
    /**
     * A helper function to get working items from nice_ids.
     * @param niceId the id stored in the database.
     * @returns A DrugOrSynonym item referring to the actual element.
     */
    function getItem(niceId: string): DrugOrSynonym | null {
      if (allItems) {
        return allItems.filter((i) => i.nice_id === niceId)[0];
      }
      return null;
    }

    // Construction of block_rules:
    const blockRules: BlockRule[] = (settingJson as any).blocks.map((block: JSON) => {
      const newBlock = {
        blockId: (block as any).id,
        blockNumber: (block as any).block_number,
        ruleId: (block as any).rule,
        ruleDesc: BLOCK_RULES.filter((item) => item.id === (block as any).rule)[0]
          .description,
        drugItems: (block as any).nice_ids.map((id: string) => {
          return getItem(id);
        }),
      };
      return newBlock;
    });

    // Construction of settings:
    const retSetting = new Settings((settingJson as any).number_blocks, blockRules);
    retSetting.id = (settingJson as any).id;
    return retSetting;
  }

  /**
   * Returns a JSON containing the informations in the setting for API use
   */
  toJSON(): object {
    // Getting a JSON for blockrules:
    const rulesJSON: object[] = [];
    this.rules.forEach((rule) => {
      // Get the list of nice_ids:
      const niceIdList: string[] = [];
      rule.drugItems.forEach((drug) => {
        niceIdList.push(drug.nice_id);
      });

      // Add the block to the setting JSON:
      if (rule.blockId === null) {
        rulesJSON.push({
          nice_ids: niceIdList,
          rule: rule.ruleId,
          block_number: rule.blockNumber,
        });
      } else {
        rulesJSON.push({
          id: rule.blockId,
          nice_ids: niceIdList,
          rule: rule.ruleId,
          block_number: rule.blockNumber,
        });
      }
    });

    return {
      number_blocks: this.numberBlocks,
      blocks_rules: rulesJSON,
    };
  }

  updateId(json: object) {
    this.id = (json as any).id;
  }

  isEmpty() {
    return this.rules.length === 0;
  }

  /**
   * A helper function modifying the available blocks for the function getAvailableBlocks.
   *
   */
  private rule4Blocks(rule: BlockRule, availableIndexes: boolean[], drug: DrugOrSynonym) {
    // Rule doesn't apply to drug:
    if (!rule.drugItems.map((d) => d.drug_id).includes(drug.drug_id)) {
      availableIndexes[rule.blockNumber - 1] = false; // -1 because blocks start at 1 for the user

      // Rule applies to drug:
    } else {
      for (let i = 0; i < this.numberBlocks; i += 1) {
        availableIndexes[i] = false;
      }
      availableIndexes[rule.blockNumber - 1] = true;
    }
  }

  /**
   * A function that gets the available blocks a drug can go to.
   * It returns the list of valid block indexes.
   *
   * Only takes into account rules 3 and 4.
   *
   * @param drug
   */
  getAvailableBlocks(drug: DrugOrSynonym | null, selectedDrugIds: number[]) {
    // Initialize available indexes:
    const availableIndexes: boolean[] = [];
    for (let i = 0; i < this.numberBlocks; i += 1) {
      availableIndexes.push(true);
    }

    if (drug) {
      this.rules.forEach((rule) => {
        switch (rule.ruleId) {
          // RULE 3:
          case BLOCK_RULES[2].id: {
            // Check whether the rule applies:
            for (let i = 0; i < rule.drugItems.length; i += 1) {
              if (selectedDrugIds.includes(rule.drugItems[i].drug_id)) {
                // Same as rule 4:
                this.rule4Blocks(rule, availableIndexes, drug);
                break;
              }
            }
            break;
          }
          case BLOCK_RULES[3].id:
            this.rule4Blocks(rule, availableIndexes, drug);
            break;

          // RULE 2:
          case BLOCK_RULES[1].id:
            if (rule.drugItems.map((d) => d.drug_id).includes(drug.drug_id)) {
              for (let i = 0; i < this.numberBlocks; i += 1) {
                availableIndexes[i] = false;
              }
              availableIndexes[rule.blockNumber - 1] = true;
            }
            break;
          // RULE 1 and unimplemented:
          default:
        }
      });
    }
    return availableIndexes;
  }
}
