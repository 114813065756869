import { useContext } from "react";
import styles from "./BlocksView.module.css";
import BlocksMenu from "../BlocksMenu/BlocksMenu";
import BlocksMainView from "../BlocksMainView/BlocksMainView";
import AppContext from "../../../AppContext";

type Props = {
  setRecompute: (recompute: boolean) => void;
};

export default function BlocksView({ setRecompute }: Props) {
  const { rampShown } = useContext(AppContext);

  return (
    <div className={rampShown ? styles.main : `${styles.main}, ${styles.hidden}`}>
      <BlocksMenu setRecompute={setRecompute} />
      <BlocksMainView />
    </div>
  );
}
