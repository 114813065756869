import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import RcpDataRampes from "../RcpDataRampes/RcpDataRampes";
import HugDataRampes from "../HugDataRampes/HugDataRampes";
import styles from "./OngletInfoRampes.module.css";

type Props = {
  incompApiData: IncompApiResp;
  isSelected: boolean;
  drugOrSynonym: Drug | Synonym;
};

export default function OngletInfo({ isSelected, incompApiData, drugOrSynonym }: Props) {
  const [selTab, setSelTab] = useState<"rcp" | "hug">("rcp");

  const hugText =
    "Il s'agit d'informations institutionnelles des Hôpitaux Universitaires de Genève pour la population adulte à visée informative uniquement. Il se peut que certaines informations soient inexactes, merci d'en parler à votre pharmacien.";

  return (
    <div className={styles.main}>
      <div className={styles.header_main}>
        <div className={styles.onglet}>
          <IconButton
            onClick={() => setSelTab("rcp")}
            className={`${styles.btn_rcp} ${
              selTab === "rcp" ? styles.selected : styles.notSelected
            }`}
            type="button"
            id="rcp-onglet"
          >
            RCP
          </IconButton>
          <IconButton
            onClick={() => setSelTab("hug")}
            className={`${styles.btn_hug} ${
              selTab === "hug" ? styles.selected : styles.notSelected
            }`}
            type="button"
            id="hug-onglet"
            // disabled
          >
            HUG
          </IconButton>
        </div>
      </div>
      {selTab === "rcp" ? (
        <RcpDataRampes
          isSelected={isSelected}
          incompApiData={incompApiData}
          drugOrSynonym={drugOrSynonym}
        />
      ) : (
        <div>
          <HugDataRampes />
          <div className={styles.hug_text}>{hugText}</div>
        </div>
      )}
    </div>
  );
}
