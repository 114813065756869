import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import OngletInfoOther from "../OngletInfoOther/OngletInfoOther";
import styles from "./DialogInfoOther.module.css";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  setIsOpen: (open: boolean) => void;
  medOralInfos: MedOralInfos;
};

export default function DialogInfo({
  isOpen,
  handleClose,
  setIsOpen,
  medOralInfos,
}: Props) {
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={handleClose}
      className={styles.main}
      id={`other-info-${medOralInfos.nice_id}`}
    >
      <DialogTitle className={styles.title}>
        {medOralInfos.informations.map((info) => (
          <div key={medOralInfos.nice_id}>{info.principe_actif} </div>
        ))}
      </DialogTitle>
      <DialogContent className={styles.content}>
        <OngletInfoOther medOralInfos={medOralInfos} />
      </DialogContent>
      <DialogActions className={styles.buttonContainer}>
        <Button onClick={() => setIsOpen(false)} id="other-info-close">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
