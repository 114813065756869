import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL_IV_DRUG_SYNONYMS } from "../constants";

export default function useFetchDrugsSynonym(token: string | null): {
  data: DrugSynonymArray;
  isLoading: boolean;
  isError: boolean;
  isUnauthorizedDrugSynonym: boolean;
  isInternalErrDrugSynonym: boolean;
} {
  const [data, setData] = useState<DrugSynonymArray>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isUnauthorizedDrugSynonym, setIsUnauthorizedDrugSynonym] = useState(false);
  const [isInternalErrDrugSynonym, setIsInternalErrDrugSynonym] = useState(false);

  const url = API_URL_IV_DRUG_SYNONYMS;

  useEffect(() => {
    const fetchData = async () => {
      if (!token) return;
      setIsError(false);
      setIsLoading(true);

      try {
        const result = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setData(result.data);
      } catch (error: any) {
        setIsError(true);
        if (error.response.status === 401) {
          setIsUnauthorizedDrugSynonym(true);
        } else if (error.response.status === 500) {
          setIsInternalErrDrugSynonym(true);
        }
      }

      setIsLoading(false);
    };

    fetchData();
  }, [token]);

  return {
    data,
    isLoading,
    isError,
    isUnauthorizedDrugSynonym,
    isInternalErrDrugSynonym,
  };
}
