import { useState, useContext } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AppContext from "../../../AppContext";
import DialogInfoRampes from "../DialogInfoRampes/DialogInfoRampes";
import styles from "./DrugItemIncompatibilitiesRampes.module.css";
import sendTrackDrugInfo from "../../../hooks/sendTrackDrugInfo";

type Props = {
  drugOrSynonym: Drug | Synonym;
  isSelected: boolean;
  item: DrugOrSynonym;
  removeSelItem: SetNiceIds;
  incompApiData: IncompApiResp;
  setRecompute: (recompute: boolean) => void;
};

export default function DrugItemIncompatibilitiesRampes({
  drugOrSynonym,
  isSelected,
  item,
  removeSelItem,
  incompApiData,
  setRecompute,
}: Props) {
  const { token, sessionId, setNiceId, setNiceIdAdminGuide } = useContext(AppContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const drugId =
    drugOrSynonym.type === "drug" ? drugOrSynonym.id : (drugOrSynonym as Synonym).drug_id;
  const hasInfo =
    drugOrSynonym.nice_id in incompApiData.guide_administration ||
    drugOrSynonym.nice_id in incompApiData.rcp_data;

  const handleSendTrackDrugInfo = () => {
    sendTrackDrugInfo(`d-${drugId}`, token, sessionId);
  };

  const handleDialogOpen = () => {
    setNiceId(`d-${drugId}`);
    setNiceIdAdminGuide(`d-${drugId}`);
    handleSendTrackDrugInfo();
    setIsDialogOpen(true);
  };
  let drugItem;

  if (drugOrSynonym.is_solvent === true) {
    drugItem = (
      <div
        className={`${styles.main} ${isSelected ? styles.selected : styles.notSelected}`}
        item-id={drugOrSynonym.nice_id}
        drug-id={drugId}
      >
        <div
          className={styles.nameSolvent}
          item-id={drugOrSynonym.nice_id}
          drug-id={drugId}
        >
          <p>{drugOrSynonym.name}</p>
        </div>
      </div>
    );
  } else {
    drugItem = (
      <div
        className={`${styles.main} ${isSelected ? styles.selected : styles.notSelected}`}
        item-id={drugOrSynonym.nice_id}
        drug-id={drugId}
        id={`drug-display-${drugOrSynonym.nice_id}`}
        type-id={drugOrSynonym.type}
      >
        <div className={styles.name} item-id={drugOrSynonym.nice_id} drug-id={drugId}>
          <p>{drugOrSynonym.name}</p>
        </div>
        <div className={styles.btns}>
          <IconButton
            className={styles.btn_add}
            type="button"
            onClick={handleDialogOpen}
            disabled={!hasInfo}
            id={`info-drug-${drugOrSynonym.nice_id}`}
          >
            <Tooltip title="Information Administration">
              <InfoOutlinedIcon
                sx={{
                  width: "1.3rem",
                  height: "1.3rem",
                  "@media (max-width: 1380px)": {
                    width: "1.1rem",
                    height: "1.1rem",
                  },
                  "@media (max-width: 1024px)": {
                    width: "1.4rem",
                    height: "1.4rem",
                  },
                }}
              />
            </Tooltip>
          </IconButton>
          <IconButton
            className={styles.btn_clear}
            type="button"
            onClick={() => {
              setRecompute(true);
              removeSelItem(item.nice_id);
            }}
            id={`remove-drug-${drugOrSynonym.nice_id}`}
          >
            <Tooltip title="Enlever Médicament">
              <ClearIcon
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                  "@media (max-width: 1380px)": {
                    width: "1.2rem",
                    height: "1.2rem",
                  },
                  "@media (max-width: 1024px)": {
                    width: "1.4rem",
                    height: "1.4rem",
                  },
                }}
              />
            </Tooltip>
          </IconButton>
        </div>
      </div>
    );
  }
  return (
    <>
      <Tooltip title={isSelected ? "" : "Ajouter"} followCursor>
        {drugItem}
      </Tooltip>
      <DialogInfoRampes
        isOpen={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        setIsOpen={setIsDialogOpen}
        drugOrSynonym={item}
        incompApiData={incompApiData}
        isSelected={isSelected}
      />
    </>
  );
}
