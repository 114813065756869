import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import styles from "./LegalNoticeModal.module.css";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export default function LegalNoticeModal({ isOpen, setIsOpen }: Props) {
  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={styles.main}>
        <iframe
          src="Mentions_legales.html"
          title={i18n.t("general.Footer.mentionLegales")}
          className={styles.content}
        />
        <Button
          variant="text"
          className={styles.closeButton}
          onClick={() => setIsOpen(false)}
        >
          Fermer
        </Button>
      </div>
    </Modal>
  );
}
