import env from "react-dotenv";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function NewsDialog({ isOpen, setIsOpen }: Props) {
  // Change this variable when modifying this dialog.
  // Used to know wether to display the newsDialog.
  const changeId = 4;
  const lastChangeId = localStorage.getItem("changeID");
  const hasChanged = changeId.toString() !== lastChangeId && lastChangeId !== null;
  if (hasChanged) {
    setIsOpen(true);
  }
  if (lastChangeId === null) {
    localStorage.setItem("changeID", changeId.toString());
  }

  const date = new Date(env.CI_COMMIT_TIMESTAMP);
  const dateStr = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

  return (
    <Dialog open={isOpen && hasChanged}>
      <DialogTitle sx={{ textAlign: "center", padding: "2rem" }}>
        Mise à jour effectuée le {dateStr}
      </DialogTitle>

      <DialogContent sx={{ textAlign: "left", padding: "2rem" }}>
        <h3>Modèle de prédiction pour les IPC des injectables</h3>
        <ul>
          <li>Ajout de 4 400 nouveaux couples de médicaments incompatibles</li>
          <li>
            Soit environ le double des connaissances compilées dans la littérature
            (environ 2 300 couples différents incompatibles référencés) !
          </li>
          <li>Fiabilité à 96%</li>
        </ul>

        <h3>Ajout des incompatibilités avec les matériaux et l’environnement</h3>
        <ul>
          <li>Incompatibilités avec le PVC, le verre, etc.</li>
          <li>Incompatibilités avec la filtration</li>
          <li>Incompatibilités avec la lumière</li>
        </ul>

        <h3>Choix des préférences d’affichage</h3>
        <ul>
          <li>À sélectionner depuis la roue crantée à droite ⚙</li>
        </ul>

        <h3>Ajout de 307 médicaments oraux</h3>

        <h3>Ajout des interactions médicaments-aliments pour les formes orales</h3>
        <ul>
          <li>Moment de prise par rapport au repas</li>
          <li>Aliments à éviter/favoriser</li>
        </ul>

        <h3>A venir</h3>
        <ul>
          <li>Septembre : Ajout des médicaments oraux manquants</li>
          <li>
            Septembre : Validation du nouveau modèle de prédiction : 20 à 30 000 couples
            incompatibles et non-incompatibles prédits
          </li>
          <li>Octobre : Mise à jour de la base de données issue de la littérature</li>
          <li>
            Octobre : Proposition d’un indice de confiance/gravité sur l’incompatibilité
          </li>
          <li>
            Octobre-Novembre : Reconnaissance de l’ordonnance sur votre DPI via une
            capture d’écran
          </li>
          <li>Octobre-Novembre : Simplification de la sauvegarde d’ordonnance</li>
        </ul>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            // The screen has been displayed, update the localStorage
            localStorage.setItem("changeID", changeId.toString());
            setIsOpen(false);
          }}
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
