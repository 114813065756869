import { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import styles from "./HugDataRampes.module.css";
import AppContext from "../../../AppContext";
import i18n from "../../../i18n";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function HugDataRampes() {
  const { adminGuideId, niceIdAdminGuide } = useContext(AppContext);

  const guideData = adminGuideId[niceIdAdminGuide];
  // Local state to know which specialty to display on a small screen
  let init: string = "";
  if (guideData) {
    init = guideData[0].guide_name;
  }
  const [specialty, setSpecialty] = useState(init);

  // Construction of the table to display on desktop version:
  let table = <div style={{ minWidth: "800px" }}>Pas de données HUG</div>;
  if (guideData) {
    table = (
      <TableContainer component={Paper} className={styles.tableauHug}>
        <Table sx={{ minWidth: 800 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={styles.tableCell}>
                {i18n.t("general.hugDataRampes.specialty")}
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} align="right">
                {i18n.t("general.hugDataRampes.storage")}
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} align="right">
                {i18n.t("general.hugDataRampes.reconstitution")}
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} align="right">
                {i18n.t("general.hugDataRampes.dilution")}
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} align="right">
                {i18n.t("general.hugDataRampes.administration")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {guideData.map((element: AdminGuideData) => (
              <StyledTableRow key={`element-${element.guide_name}`}>
                <StyledTableCell
                  className={styles.tableCell_row}
                  component="th"
                  scope="row"
                >
                  {element.guide_name}
                </StyledTableCell>
                <StyledTableCell className={styles.tableCell_row} align="right">
                  {element.conditionnement}
                </StyledTableCell>
                <StyledTableCell className={styles.tableCell_row} align="right">
                  {element.reconstitution}
                </StyledTableCell>
                <StyledTableCell className={styles.tableCell_row} align="right">
                  {element.dilution}
                </StyledTableCell>
                <StyledTableCell className={styles.tableCell_row} align="right">
                  {element.administration}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  // Construction of the view to show on a small screen:
  const smallView = !guideData ? (
    <div style={{ width: "100%" }}>Pas de données HUG</div>
  ) : (
    <div className={styles.smallView}>
      {/* Menu to click on a specialty */}
      <div className={styles.interaction}>
        {guideData.map((data: AdminGuideData) => (
          <IconButton
            key={`data-${data.guide_name}`}
            type="button"
            className={
              data.guide_name === specialty
                ? `${styles.selected} ${styles.button}`
                : `${styles.unselected} ${styles.button}`
            }
            onClick={() => setSpecialty(data.guide_name)}
          >
            {data.guide_name}
          </IconButton>
        ))}
      </div>
      <div className={styles.displayedInfo}>
        {guideData.map((data: AdminGuideData) => (
          <div
            className={specialty === data.guide_name ? styles.shown : styles.hidden}
            key={`element-${data.guide_name}`}
          >
            <h2 className={styles.title}>CONDITIONNEMENT CONSERVATION</h2>
            <div className={styles.text}> {data.conditionnement} </div>
            <h2 className={styles.title}>
              RECONSTITUTION STABILITE APRES RECONSTITUTION
            </h2>
            <div className={styles.text}> {data.reconstitution} </div>
            <h2 className={styles.title}> DILUTION STABILITE APRES DILUTION </h2>
            <div className={styles.text}> {data.dilution} </div>
            <h2 className={styles.title}> MODE D&apos;ADMINISTRATION </h2>
            <div className={styles.text}> {data.administration} </div>
          </div>
        ))}
      </div>
    </div>
  );
  return (
    <div className={styles.content}>
      <div className={styles.largeView}>{table}</div>
      {smallView}
    </div>
  );
}
