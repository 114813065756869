import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleLogout: () => void;
};

export default function UnauthorizedDialog({ isOpen, setIsOpen, handleLogout }: Props) {
  if (isOpen) {
    setTimeout(() => handleLogout(), 3000);
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle sx={{ textAlign: "center" }}>
        Veuillez vous re-authentifier
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center" }}>
          Vous n&apos;êtes plus authentifié(e). Vous serez redirigé(e) vers la page de
          connexion.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleLogout();
          }}
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
