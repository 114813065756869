import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import OngletInfoRampes from "../OngletInfoRampes/OngletInfoRampes";
import styles from "./DialogInfoRampes.module.css";

type Props = {
  drugOrSynonym: Drug | Synonym;
  isOpen: boolean;
  handleClose: () => void;
  setIsOpen: (open: boolean) => void;
  incompApiData: IncompApiResp;
  isSelected: boolean;
};

export default function DialogInfo({
  isOpen,
  handleClose,
  setIsOpen,
  drugOrSynonym,
  incompApiData,
  isSelected,
}: Props) {
  const drugId =
    drugOrSynonym.type === "drug" ? drugOrSynonym.id : (drugOrSynonym as Synonym).drug_id;

  return (
    <Dialog open={isOpen} onClose={handleClose} drug-id={drugId} className={styles.main}>
      <DialogTitle className={styles.title}>{drugOrSynonym.name}</DialogTitle>
      <DialogContent className={styles.content}>
        <OngletInfoRampes
          incompApiData={incompApiData}
          isSelected={isSelected}
          drugOrSynonym={drugOrSynonym}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)} id="close-button-dialog-info-rampes">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
