import axios from "axios";
import { API_URL_TRACK_BLOCKS } from "../constants";

export default async function sendTrackBlocks(
  selNiceIds: string[],
  selNiceId: string | null,
  action: string,
  token: string | null,
  sessionId: string | null,
): Promise<boolean> {
  if (!token || !sessionId) return false;

  const url = API_URL_TRACK_BLOCKS;
  const bodyReq = {
    curr_nice_ids: selNiceIds,
    session_id: sessionId,
    action,
    drug_id: selNiceId,
  };

  try {
    const response = await axios.post(url, bodyReq, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.status === 200;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return false;
  }
}
