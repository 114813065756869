/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-alert */
import { useState } from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import styles from "./CreateOrUpdateOcrDialog.module.css";
import OcrDialog from "../OcrDialog/OcrDialog";
import AddOcrDialog from "../AddOcrDialog/AddOcrDialog";
import i18n from "../../../i18n";

type Props = {
  open: boolean;
  handleCreateOrUpdateOcrCancelButtonClick: () => void;
};

export default function CreateOrUpdateOcrDialog({
  open,
  handleCreateOrUpdateOcrCancelButtonClick,
}: Props) {
  const [ocrDialogIsOpen, setOcrDialogIsOpen] = useState(false);
  const [addOcrDialogIsOpen, setAddOcrDialogIsOpen] = useState(false);

  const handleOcrDialogIsOpen = () => {
    setOcrDialogIsOpen(true);
  };
  const handleAddOcrDialogIsOpen = () => {
    setAddOcrDialogIsOpen(true);
  };
  const handleOcrCancelButtonClick = () => {
    setOcrDialogIsOpen(false);
  };
  const handleAddOcrCancelButtonClick = () => {
    setAddOcrDialogIsOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCreateOrUpdateOcrCancelButtonClick}
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center !important",
        justifyContent: "center",
        "& > *:nth-of-type(3)": {
          "& > :first-of-type": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center !important",
            justifyContent: "center",
            padding: "20px !important",
            gap: "20px",
          },
        },
      }}
      id="ocr-dialog"
    >
      <DialogTitle
        sx={{
          width: "80%",
          textAlign: "center",
          color: "var(--dark-grey)",
          fontSize: "1.3rem",
          lineHeight: "1.5rem",
          "& span": {
            color: "var(--green)",
            fontWeight: "bold",
          },
        }}
      >
        {i18n.t("general.ocr.title")}
      </DialogTitle>

      <div className={styles.interaction}>
        <Button
          sx={{
            width: "220px",
            height: "130px",
            backgroundColor: "var(--green)",
            color: "var(--white)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: "1rem",
            "&:hover": {
              backgroundColor: "var(--dark-green)",
            },
            // media screen
            "@media screen and (max-width: 768px)": {
              width: "250px",
              height: "110px",
            },
          }}
          id="ocr-file-button"
          onClick={handleOcrDialogIsOpen}
        >
          <EditIcon sx={{ width: "2.3rem", height: "2.3rem", marginBottom: "20px" }} />
          {i18n.t("general.ocr.new")}
        </Button>
        <Button
          sx={{
            width: "220px",
            height: "130px",
            backgroundColor: "var(--green)",
            color: "var(--white)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: "1rem",

            "&:hover": {
              backgroundColor: "var(--dark-green)",
            },
            // media screen
            "@media screen and (max-width: 768px)": {
              width: "250px",
              height: "110px",
            },
          }}
          onClick={handleAddOcrDialogIsOpen}
        >
          <AddIcon sx={{ width: "3.1rem", height: "3.1rem", marginBottom: "10px" }} />
          {i18n.t("general.ocr.add")}
        </Button>
      </div>

      <OcrDialog
        open={ocrDialogIsOpen}
        handleOcrCancelButtonClick={handleOcrCancelButtonClick}
        handleCreateOrUpdateOcrCancelButtonClick={
          handleCreateOrUpdateOcrCancelButtonClick
        }
      />
      <AddOcrDialog
        open={addOcrDialogIsOpen}
        handleAddOcrCancelButtonClick={handleAddOcrCancelButtonClick}
        handleCreateOrUpdateOcrCancelButtonClick={
          handleCreateOrUpdateOcrCancelButtonClick
        }
      />

      <DialogActions>
        <Button
          sx={{
            border: "2px solid var(--red)",
            width: "110px",
            height: "33px",
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "var(--red)",
            color: "var(--white)",
            borderRadius: "2px",

            "&:hover": {
              border: "2px solid var(--red)",
              color: "var(--red)",
              backgroundColor: "transparent",
            },
          }}
          onClick={handleCreateOrUpdateOcrCancelButtonClick}
        >
          {i18n.t("general.ocr.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
