import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "./DrugFilterAutocomplete.module.css";
import AppContext from "../../../AppContext";
import i18n from "../../../i18n";

type Props = {
  searchInput: React.MutableRefObject<HTMLInputElement | null>;
};

export default function DrugFilterAutocomplete({ searchInput }: Props) {
  const { selMedsOraux, allMedsOraux, addMedOral, filterText, modifyFilterText } =
    useContext(AppContext);

  // Hooks and functions to keep track of the state of the dialog menu:
  const [openDialog20, setOpenDialog20] = useState(false);
  const handleClose = () => {
    setOpenDialog20(false);
  };
  useEffect(() => {
    setOpenDialog20(selMedsOraux.length >= 20);
  }, [selMedsOraux.length]);

  // Function to deal with changing user input:
  function onTextChange(text: string): void {
    modifyFilterText(text);
  }

  const lastSelMedsOraux = selMedsOraux[selMedsOraux.length - 1];

  const options = allMedsOraux.filter((item) => {
    const isNiceIdIncluded = lastSelMedsOraux === item.nice_id;

    if (isNiceIdIncluded) {
      return true;
    }

    return !isNiceIdIncluded;
  });

  // Function to deal with the selection of a drug in the dropdown menu:
  function onClick(newValue: OralDrugSynonym | null) {
    if (newValue === null) return;

    if (selMedsOraux.length > 20) {
      setOpenDialog20(true);
      return;
    }

    addMedOral(newValue.nice_id);
    if (searchInput.current) {
      searchInput.current.focus();
    }
  }
  options.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div className={styles.main} id="search-input">
      <Autocomplete
        onChange={(event, newValue) => {
          onClick(newValue === null ? null : newValue);
        }}
        clearOnEscape
        disablePortal
        sx={{
          width: "400px",
          borderRadius: "0 !important",
          border: "0px solid #ccc",
          marginTop: 0,
          marginBottom: 0,
          padding: "0px 0px 0px 0",
          "@media (max-width: 1160px)": {
            width: "300px",
          },
          "@media (max-width: 768px)": {
            width: "95%",
            marginBottom: "14px",
          },
        }}
        options={options}
        getOptionDisabled={(option) => selMedsOraux.includes(option.nice_id)}
        getOptionLabel={(option) => {
          return option.search_name;
        }}
        renderOption={(props, option) => {
          return (
            <li
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              key={option.nice_id}
            >
              {option.name}
            </li>
          );
        }}
        isOptionEqualToValue={(option, value) => {
          return (
            option.search_name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/[^a-z0-9]/gi, "") ===
            value.search_name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/[^a-z0-9]/gi, "")
          );
        }}
        renderInput={(params) => {
          params.inputProps.value = filterText;
          return (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              inputRef={searchInput}
              className={styles.textField}
              label={i18n.t("formesOrales.DrugFilterAutocomplete.label")}
              variant="standard"
              onChange={(e) => onTextChange(e.target.value)}
              value={filterText}
            />
          );
        }}
      />

      <Dialog open={openDialog20} onClose={handleClose}>
        <DialogTitle>{i18n.t("formesOrales.DrugFilterAutocomplete.warning")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {i18n.t("formesOrales.DrugFilterAutocomplete.DialogContentText")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {i18n.t("formesOrales.DrugFilterAutocomplete.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
