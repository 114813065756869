import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import i18n from "../../../i18n";

type Props = {
  message: string;
  isOpen: boolean;
  onClose: () => void;
};

export default function ErrorDialog({ message, isOpen, onClose }: Props) {
  return (
    <Dialog open={isOpen} onClose={onClose} id="dialog-error">
      <DialogTitle sx={{ textAlign: "center" }}>
        {i18n.t("Menu.ErrorDialog.Alert")}
      </DialogTitle>
      <DialogContent>
        <div>{message}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} id="accept-button-dialog-error">
          {i18n.t("Menu.ErrorDialog.Accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
