import { useState, useEffect, useContext } from "react";
import DOMPurify from "dompurify";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import styles from "./RcpDataRampes.module.css";
import "./RcpStyles.css";
import AppContext from "../../../AppContext";
import i18n from "../../../i18n";

type Props = {
  isSelected: boolean;
  incompApiData: IncompApiResp;
  drugOrSynonym: Drug | Synonym;
};

export default function RcpData({ isSelected, incompApiData, drugOrSynonym }: Props) {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      width: "100%",
      height: "100%",
      border: "none",
      display: "flex",
      color: "var(--green)",
      fontWeight: "400",
    },
    "& .MuiInputBase-input": {
      borderRadius: 0,
      position: "relative",
      backgroundColor: theme.palette.background.paper,

      fontSize: 16,
      padding: "10px 26px 10px 12px",
      color: "var(--green)",
      fontWeight: "400",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));

  const { rcpDataId, niceIdState } = useContext(AppContext);

  const rcpData = rcpDataId[niceIdState];
  const [selTab, setSelTab] = useState<keyof RCPDrugData>("manipulation_elimination");
  const [selRcpId, setSelRcpId] = useState<number | string>("");
  useEffect(() => {
    if (rcpData && rcpData.length > 0) {
      setSelRcpId(rcpData[0].rcp_id);
    } else {
      setSelRcpId("");
    }
  }, [rcpData]);

  let element;

  if (selRcpId && rcpData) {
    [element] = rcpData.filter((e: RCPDrugData) => e.rcp_id === selRcpId);
  }
  let mySafeHTML = `No data for ${drugOrSynonym.name}`;
  if (element !== undefined) {
    mySafeHTML = DOMPurify.sanitize(String(element[selTab]) || "");
  }
  let dropdown = null;
  let link = null;
  if (rcpData !== undefined) {
    dropdown = (
      <Box className={styles.btn_auto}>
        <FormControl fullWidth>
          <Select
            className={styles.label}
            value={selRcpId === null ? undefined : selRcpId}
            label="Medicaments"
            onChange={(event) => setSelRcpId(Number(event.target.value))}
            input={<BootstrapInput />}
          >
            {rcpData.map((e: RCPDrugData) => (
              <MenuItem className={styles.menuItem} value={e.rcp_id} key={e.rcp_id}>
                {e.rcp_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );

    if (element) {
      link = (
        <a
          className={styles.link_rcp_site}
          href={`https://base-donnees-publique.medicaments.gouv.fr/affichageDoc.php?specid=${element.rcp_id}&typedoc=R`}
          target="_blank"
          rel="noreferrer"
        >
          <OpenInNewIcon className={styles.link_rcp_site_logo} />
        </a>
      );
    }
  }

  return (
    <div>
      <div className={styles.interaction}>
        <div className={styles.selection_rcp}>
          <IconButton
            onClick={() => setSelTab("manipulation_elimination")}
            className={`${styles.btn_selection_rcp} ${styles.btn_selection_rcp_admin}
            ${
              selTab === "manipulation_elimination" ? styles.selected : styles.notSelected
            }`}
            type="button"
            id="btn_manipulation_elimination"
          >
            {i18n.t("general.rcpDataRampes.handling")}
          </IconButton>
          <IconButton
            onClick={() => setSelTab("stockage_conservation")}
            className={`${styles.btn_selection_rcp} ${styles.btn_selection_rcp_stock} `}
            type="button"
            id="btn_stockage_conservation"
          >
            {i18n.t("general.rcpDataRampes.storage")}
          </IconButton>
          <IconButton
            onClick={() => setSelTab("posologie_administration")}
            className={`${styles.btn_selection_rcp} ${styles.btn_selection_rcp_poso}`}
            type="button"
            id="btn_posologie_administration"
          >
            {i18n.t("general.rcpDataRampes.administration")}
          </IconButton>
        </div>

        <div className={styles.container_select}>
          {dropdown}
          {link}
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.rcp_name}>
          <div
            className={`${styles.manipulation}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: mySafeHTML }}
          />
        </div>
      </div>
    </div>
  );
}
