import { useContext, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";

import styles from "./BlocksMenu.module.css";
import AppContext from "../../../AppContext";
import sendTrackBlocks from "../../../hooks/sendTrackBlocks";
import DialogOptimizeExact from "../DialogOptimizeExact/DialogOptimizeExact";
import { MAX_COMBS_OPT } from "../../../constants";
import i18n from "../../../i18n";

type Props = {
  setRecompute: (recompute: boolean) => void;
};

export default function BlocksMenu({ setRecompute }: Props) {
  const {
    numBlocks,
    setNumBlocks,
    isOptLoading,
    selExactOptVal,
    setSelExactOptVal,
    exactOptResult,
    token,
    sessionId,
    selNiceIds,
    setExactOptResult,
    setApplyBigsFirst,
    blocksSet,
    settingSet,
  } = useContext(AppContext);

  const [isOptExactModalOpen, setIsOptExactModalOpen] = useState(false);

  const handleOnClick = async (algoName: string) => {
    await sendTrackBlocks(selNiceIds, null, algoName, token, sessionId);
    setApplyBigsFirst(true);
  };

  const tooManyCombinations = blocksSet !== null && blocksSet.totalCombs > MAX_COMBS_OPT;
  // let optimalTooltip = "Trouver toutes les solutions optimales";
  let optimalTooltip = i18n.t("general.BlocksMenu.tooltipOptimal");

  if (tooManyCombinations) {
    optimalTooltip = `${i18n.t(
      "general.BlocksMenu.erreurOptimal1",
    )} ${blocksSet.totalCombs.toLocaleString()} ${i18n.t(
      "general.BlocksMenu.erreurOptimal2",
    )}`;
  }

  return (
    <div className={styles.main}>
      <div className={styles.interaction}>
        <div className={styles.menu_rampes}>
          <h2>{i18n.t("general.BlocksMenu.numberlumen")}</h2>
          <Select
            className={styles.select}
            sx={{
              width: 70,
              height: 30,
              boxSizing: "border-box",
              borderRadius: "5px",
              padding: "0px 10px",
              backgroundColor: "var(--green)",
              color: "var(--white)",
              border: "3px solid var(--green)",
              boxShadow: "none",
              transition: "all 0.2s ease",
              "& .MuiSlider-thumb": {
                borderRadius: "0px",
              },
              "&:hover": {
                backgroundColor: "var(--white)",
                color: "var(--green)",
                border: "3px solid var(--green)",
              },
              "&::before": {
                display: "none",
              },
              "&::after": {
                display: "none",
              },
            }}
            value={numBlocks}
            variant="standard"
            onChange={(e) => {
              sendTrackBlocks(
                selNiceIds,
                null,
                `change_num_blocks_${e.target.value}`,
                token,
                sessionId,
              );
              setNumBlocks(Number(e.target.value));
              setExactOptResult(null);
            }}
            id="rampes-select"
          >
            {[1, 2, 3, 4, 5, 6].map((val) => (
              <MenuItem
                value={val}
                key={val}
                sx={{
                  width: 70,
                }}
              >
                {val}
              </MenuItem>
            ))}
          </Select>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            title={
              settingSet.getActiveSettingId(numBlocks) !== null
                ? i18n.t("general.BlocksMenu.regleActive")
                : i18n.t("general.BlocksMenu.regleInactive")
            }
          >
            <div
              className={
                settingSet.getActiveSettingId(numBlocks) !== null
                  ? styles.indicateur_act
                  : styles.indicateur_inact
              }
            />
          </Tooltip>
        </div>

        {/* All buttons division */}
        <div className={styles.buttons}>
          {/* Optimize bigs first */}

          <Tooltip title={i18n.t("general.BlocksMenu.tooltippRest")}>
            <LoadingButton
              loading={isOptLoading}
              className={styles.btn_algo}
              sx={{
                width: 80,
                height: 40,
                backgroundColor: "var(--green)",
                color: "var(--white)",
                transition: "all 0.2s ease",
                "& .MuiSlider-thumb": {
                  borderRadius: "1px",
                },
                "&:hover": {
                  backgroundColor: "var(--white)",
                  color: "var(--green)",
                },
              }}
              type="button"
              onClick={() => {
                setRecompute(true);
                handleOnClick("bigs_first_algorithm");
              }}
              id="bigs_first_algorithm"
            >
              Reset
            </LoadingButton>
          </Tooltip>

          {/* Button toutes les solution */}
          <Tooltip title={optimalTooltip}>
            <span>
              <LoadingButton
                loading={isOptLoading}
                className={styles.btn_algo}
                // disabled={tooManyCombinations}
                disabled
                sx={{
                  width: 80,
                  height: 40,
                  backgroundColor: "var(--light-green)",
                  color: "var(--white)",
                  transition: "all 0.2s ease",
                  "& .MuiSlider-thumb": {
                    borderRadius: "1px",
                  },
                  "&:hover": {
                    backgroundColor: "var(--white)",
                    color: "var(--green)",
                  },
                }}
                type="button"
                onClick={() => {
                  sendTrackBlocks(
                    selNiceIds,
                    null,
                    "click_optimize_exact",
                    token,
                    sessionId,
                  );
                  setIsOptExactModalOpen(true);
                }}
                id="optimize_exact"
              >
                Optimal
              </LoadingButton>
            </span>
          </Tooltip>
        </div>

        {/* slider with exact solutions */}
        <Box sx={{ width: 300, height: "100%" }}>
          {Array.isArray(exactOptResult) && (
            <Slider
              value={selExactOptVal || 0}
              step={1}
              marks
              min={0}
              max={exactOptResult.length - 1}
              onChange={(_, newValue) => setSelExactOptVal(Number(newValue))}
              valueLabelDisplay="auto"
            />
          )}
        </Box>
      </div>
      <DialogOptimizeExact
        isOpen={isOptExactModalOpen}
        setIsOpen={setIsOptExactModalOpen}
      />
    </div>
  );
}
