import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function InternalErrDialog({ isOpen, setIsOpen }: Props) {
  return (
    <Dialog open={isOpen}>
      <DialogTitle sx={{ textAlign: "center" }}>Erreur serveur</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Oups, quelque chose s&apos; est mal passé. N&apos; hésitez pas à nous contacter
          si le problème persiste
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
