import Tooltip from "@mui/material/Tooltip";
import styles from "./EcrasableInfo.module.css";

const ecrasableText = {
  E: "Ecrasement possible",
  NE: "Ne pas ecraser ni croquer",
  O: "Ouverture possible",
  NO: "Ne pas ouvrir",
  D: "Délitement possible",
};

type Props = {
  ecrasableCode: string;
  sourceModalites: string;
};

export default function EcrasableInfo({ ecrasableCode, sourceModalites }: Props) {
  let text = "";
  if (ecrasableCode in ecrasableText) {
    const code = ecrasableCode as keyof typeof ecrasableText;
    text = ecrasableText[code];
  }
  return (
    <Tooltip title={`Source: ${sourceModalites || "?"}`} followCursor>
      <div className={styles.main}>{text}</div>
    </Tooltip>
  );
}
