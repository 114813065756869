import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Button, DialogActions } from "@mui/material";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

export default function ThanksFeedbackModal({ isOpen, setIsOpen }: Props) {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{i18n.t("Menu.FeedbackModalJSON.JsonErrorTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {i18n.t("Menu.FeedbackModalJSON.JsonErrorMessage")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)}>
          {i18n.t("Menu.FeedbackModalJSON.CloseButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
