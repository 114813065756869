import Tooltip from "@mui/material/Tooltip";
import styles from "./AlternativeInfo.module.css";

type Props = {
  sourceModalites: string;
  alternative: string;
};

export default function AlternativeInfo({ sourceModalites, alternative }: Props) {
  return (
    <Tooltip title={`Source: ${sourceModalites || "?"}`} followCursor>
      <div className={styles.main}>
        Alternative : {alternative || "Pas d'information"}
      </div>
    </Tooltip>
  );
}
