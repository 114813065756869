import { useRef, useState, useContext, ChangeEvent, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
// import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";
import axios from "axios";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PrintIcon from "@mui/icons-material/Print";
import AppContext from "../../../AppContext";
import DialogSavePrescriptions from "../DialogSavePrescriptions/DialogSavePrescriptions";
import DrugFilterAutocomplete from "../DrugFilterAutocomplete/DrugFilterAutocomplete";
import CreateOrUpdateOcrDialog from "../CreateOrUpdateOcrDialog/CreateOrUpdateOcrDialog";
import styles from "./PanelOther.module.css";
import { API_URL_PRESCRIPTIONS_ORAL } from "../../../constants";
import sendOralPrescriptions from "../../../hooks/sendOralPrescriptions";
import i18n from "../../../i18n";

interface PanelOtherProps {
  printRef: React.RefObject<HTMLDivElement>;
}

export default function PanelOther({ printRef }: PanelOtherProps) {
  const {
    sessionId,
    selMedsOraux,
    addMedOral,
    removeMedOral,
    token,
    oralPrescriptionsData,
    setOralPrescriptionsData,
    updateOralPrescriptionsData,
    setUpdateOralPrescriptionsData,
    setIsUnauthorizedOralPrescriptions,
    setIsInternalOralPrescriptions,
  } = useContext(AppContext);
  const searchInput = useRef<HTMLInputElement | null>(null);

  const [createOrUpdateOcrDialogIsOpen, setCreateOrUpdateOcrDialogIsOpen] =
    useState(false);

  // Hooks
  const [saveDialogIsOpen, setSaveDialogIsOpen] = useState(false);
  const [patientFirstName, setPatientFirstName] = useState("");
  const [soignantName, setSoignantName] = useState("");

  // Getting the current date to the correct format:
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = currentDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;

  // Getting stored prescriptions:
  // Getting the current prescriptions
  const url = API_URL_PRESCRIPTIONS_ORAL;

  const fetchData = async () => {
    if (!token) return;

    try {
      const result = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setOralPrescriptionsData(result.data.oral_prescriptions);
    } catch (error: any) {
      if (error.response.status === 401) {
        setIsUnauthorizedOralPrescriptions(true);
      } else if (error.response.status === 500) {
        setIsInternalOralPrescriptions(true);
      }
    }
    setUpdateOralPrescriptionsData(false);
  };

  useEffect(() => {
    fetchData();
  }, [token, updateOralPrescriptionsData]);

  let prescriptionNames: string[] = [];
  if (Array.isArray(oralPrescriptionsData)) {
    prescriptionNames = oralPrescriptionsData.map(
      (prescription: OralPrescriptions) => prescription.name,
    );
  }

  // Buttons related to the dialog state:
  const handleSaveButtonClick = () => {
    setSaveDialogIsOpen(true);
  };

  const handleCancelButtonClick = () => {
    setSaveDialogIsOpen(false);
  };

  /**
   * A function which saves the prescription by adding it to the db
   */
  const handleConfirmSaveClick = async () => {
    // Initializing the prescription based on user input:
    const nameValue =
      newPrescriptionName !== prescriptionName ? newPrescriptionName : prescriptionName;
    const patientNameValue = patientFirstName || "";
    const prescriberNameValue = soignantName || "";
    const selItems = selMedsOraux;
    await sendOralPrescriptions(
      nameValue,
      patientNameValue,
      prescriberNameValue,
      selItems,
      token,
      sessionId,
    );

    // Reseting field values
    setPatientFirstName("");
    setSoignantName("");
    setSaveDialogIsOpen(false);

    // Fetch data after sending the prescription
    await fetchData();
  };

  /**
   * Deal with user input on the PatientName field
   */
  const handlePatientFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPatientFirstName(event.target.value);
  };

  /**
   * Deal with user input on the SoignantName field
   */
  const handleSoignantNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSoignantName(event.target.value);
  };

  const generateNewPrescriptionName = () => {
    // Get existing prescription names:
    const existingNames = new Set(prescriptionNames);
    const existingPrescriptions = oralPrescriptionsData || [];
    if (existingPrescriptions.length > 0) {
      existingPrescriptions.forEach((prescription) => {
        existingNames.add(prescription.name);
      });
    }

    // Deal with conflictiong names:
    let newPrescriptionName = prescriptionName;
    let counter = 2;
    while (existingNames.has(newPrescriptionName)) {
      newPrescriptionName = `${prescriptionName}_${counter}`;
      counter += 1;
    }
    return newPrescriptionName;
  };

  const formattedFirstName = patientFirstName.replace(/\s+/g, "_");
  const formattedPrescriberName = soignantName.replace(/\s+/g, "_");

  const prescriptionName = `${formattedDate}_${
    patientFirstName ? formattedFirstName : "ordonnance"
  }${soignantName ? `_${formattedPrescriberName}` : ""}`;

  const newPrescriptionName = generateNewPrescriptionName();

  const handleSelection = (selectedPrescription: OralPrescriptions | null) => {
    // Click on nothing:
    if (!selectedPrescription) {
      return;
    }

    // Click on the current prescription:
    // remove all items from the prescription:
    selMedsOraux.forEach((item) => {
      removeMedOral(item);
    });
    selectedPrescription.sel_items.forEach((item) => {
      addMedOral(item);
    });
  };

  const handleOcrButtonClick = () => {
    setCreateOrUpdateOcrDialogIsOpen(true);
  };

  const handleCreateOrUpdateOcrCancelButtonClick = () => {
    setCreateOrUpdateOcrDialogIsOpen(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${formattedDate}_ordonnance`,
  });
  return (
    <div className={styles.main}>
      <div className={styles.menu__desktop}>
        <div className={styles.container_medicament}>
          {/* Recherche médicaments */}
          <DrugFilterAutocomplete searchInput={searchInput} />
        </div>

        <div className={styles.container_ordonnances}>
          {/* Recherche ordonnances */}
          <Autocomplete
            onChange={(event, newValue) => {
              handleSelection(newValue);
            }}
            clearOnEscape
            disablePortal
            filterSelectedOptions
            sx={{
              width: "400px",
              borderRadius: "0 !important",
              border: "0px solid #ccc",
              marginTop: 0,
              marginBottom: 0,
              padding: "0px 0px 0px 0px",
              "@media (max-width: 1160px)": {
                width: "300px",
              },
              "@media (max-width: 768px)": {
                width: "100%",
                padding: "24px 0",
              },
            }}
            id="search-prescription"
            options={oralPrescriptionsData}
            getOptionLabel={(option) => {
              return option.name;
            }}
            isOptionEqualToValue={(option, value) => {
              return option.name === value.name;
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label={i18n.t("formesOrales.PanelOther.label")}
                variant="standard"
              />
            )}
          />
          {/* Save */}
          <IconButton
            id="save-prescription-button"
            sx={{
              width: "38px",
              height: "38px",
              marginRight: "0px",
              backgroundColor: "var(--green)",
              borderRadius: "5px !important",
              color: "var(--white)",
              border: "3px solid var(--green)",
              transition: "all 0.2s ease-out",
              "&:hover": {
                backgroundColor: "transparent",
                color: "var(--green)",
              },
            }}
            onClick={handleSaveButtonClick}
          >
            <Tooltip
              placement="top"
              title={i18n.t("formesOrales.PanelOther.savePrescriptionTooltip")}
            >
              <SaveIcon />
            </Tooltip>
          </IconButton>
          {/* ocr */}
          <IconButton
            sx={{
              width: "38px",
              height: "38px",
              marginLeft: "0px",
              backgroundColor: "var(--green)",
              borderRadius: "5px !important",
              color: "var(--white)",
              border: "3px solid var(--green)",
              transition: "all 0.2s ease-out",
              "&:hover": {
                backgroundColor: "transparent",
                color: "var(--green)",
              },
            }}
            onClick={handleOcrButtonClick}
            id="ocr-button"
          >
            <Tooltip
              placement="top"
              title={i18n.t("formesOrales.PanelOther.addPrescription")}
            >
              <PhotoCameraIcon />
            </Tooltip>
          </IconButton>
          {/* print */}
          <IconButton
            sx={{
              width: "38px",
              height: "38px",
              backgroundColor: "var(--green)",
              borderRadius: "5px !important",
              color: "var(--white)",
              border: "3px solid var(--green)",
              transition: "all 0.2s ease-out",
              "&:hover": {
                backgroundColor: "transparent",
                color: "var(--green)",
              },
            }}
            onClick={handlePrint}
          >
            <Tooltip
              placement="top"
              title={i18n.t("formesOrales.PanelOther.printPrescription")}
            >
              <PrintIcon />
            </Tooltip>
          </IconButton>

          {/* component DialogSavePrescriptions */}
          <DialogSavePrescriptions
            open={saveDialogIsOpen}
            patientFirstName={patientFirstName}
            soignantName={soignantName}
            handleCancelButtonClick={handleCancelButtonClick}
            handleConfirmSaveClick={handleConfirmSaveClick}
            newPrescriptionName={newPrescriptionName}
            handlePatientFirstNameChange={handlePatientFirstNameChange}
            handleSoignantNameChange={handleSoignantNameChange}
          />
        </div>
      </div>

      <div className={styles.menu__mobile}>
        <DrugFilterAutocomplete searchInput={searchInput} />
        <div className={styles.container__btns}>
          {/* Save */}
          <IconButton
            id="save-prescription-button"
            sx={{
              width: "38px",
              height: "38px",
              marginRight: "0px",
              backgroundColor: "var(--green)",
              borderRadius: "5px !important",
              color: "var(--white)",
              border: "3px solid var(--green)",
              transition: "all 0.2s ease-out",
              "&:hover": {
                backgroundColor: "transparent",
                color: "var(--green)",
              },
            }}
            onClick={handleSaveButtonClick}
          >
            <Tooltip
              placement="top"
              title={i18n.t("formesOrales.PanelOther.savePrescriptionTooltip")}
            >
              <SaveIcon />
            </Tooltip>
          </IconButton>
          {/* ocr */}
          <IconButton
            sx={{
              width: "38px",
              height: "38px",
              marginLeft: "0px",
              backgroundColor: "var(--green)",
              borderRadius: "5px !important",
              color: "var(--white)",
              border: "3px solid var(--green)",
              transition: "all 0.2s ease-out",
              "&:hover": {
                backgroundColor: "transparent",
                color: "var(--green)",
              },
            }}
            onClick={handleOcrButtonClick}
            id="ocr-button"
          >
            <Tooltip placement="top" title="Ajouter une ordonnance">
              <PhotoCameraIcon />
            </Tooltip>
          </IconButton>
          {/* print */}
          <IconButton
            sx={{
              width: "38px",
              height: "38px",
              backgroundColor: "var(--green)",
              borderRadius: "5px !important",
              color: "var(--white)",
              border: "3px solid var(--green)",
              transition: "all 0.2s ease-out",
              "&:hover": {
                backgroundColor: "transparent",
                color: "var(--green)",
              },
            }}
            onClick={handlePrint}
          >
            <PrintIcon />
          </IconButton>
        </div>
      </div>

      <div className={styles.container__autocomplete__ordonnance}>
        <Autocomplete
          onChange={(event, newValue) => {
            handleSelection(newValue);
          }}
          clearOnEscape
          disablePortal
          filterSelectedOptions
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "#fff",
              "& fieldset": {
                borderColor: "#fff", // Couleur des bordures
              },
            },
            width: "80%",
            borderRadius: "0 !important",
            marginTop: 0,
            marginBottom: 1,
            padding: "0px 0px 0px 0px",
            "@media (max-width: 1160px)": {
              width: "90%",
              color: "#fff",
              borderColor: "#fff",
            },
            "@media (max-width: 846px)": {
              width: "90%",
              padding: "12px 0",
              borderColor: "#fff",
            },
          }}
          id="search-prescription"
          options={oralPrescriptionsData}
          getOptionLabel={(option) => {
            return option.name;
          }}
          isOptionEqualToValue={(option, value) => {
            return option.name === value.name;
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label={i18n.t("formesOrales.PanelOther.label")}
              variant="standard"
            />
          )}
        />
      </div>
      <CreateOrUpdateOcrDialog
        open={createOrUpdateOcrDialogIsOpen}
        handleCreateOrUpdateOcrCancelButtonClick={
          handleCreateOrUpdateOcrCancelButtonClick
        }
      />
    </div>
  );
}
