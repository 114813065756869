import { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DescriptionIcon from "@mui/icons-material/Description";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import Tooltip from "@mui/material/Tooltip";
import { useMediaQuery } from "@mui/material";
import SettingMainDialog from "../SettingMainDialog/SettingMainDialog";
import PrescriptionsDialog from "../PrescriptionsDialog/PrescriptionsDialog";
import AppContext from "../../../AppContext";
import styles from "./MainMenu.module.css";
import CommentFeedbackModal from "../CommentFeedbackModal/CommentFeedbackModal";
import ThanksFeedbackModal from "../ThanksFeedbackModal/ThanksFeedbackModal";
import ErrorFeedbackModal from "../ErrorFeedbackModal/ErrorFeedbackModal";
import HelpDialog from "../HelpDialog/HelpDialog";
import sendCommentFeedback from "../../../hooks/sendCommentFeedback";
import sendLikeDislikeFeedback from "../../../hooks/sendLikeDislikeFeedback";
import ErrorJsonModal from "../ErrorJsonModal/ErrorJsonModal";
import i18n from "../../../i18n";

type Props = {
  handleLogout: () => void;
  areThumbsDisabled: boolean;
  setAreThumbsDisabled: (isDisabled: boolean) => void;
  setRecompute: (recompute: boolean) => void;
};

export default function MainMenu({
  handleLogout,
  areThumbsDisabled,
  setAreThumbsDisabled,
  setRecompute,
}: Props) {
  const {
    selMedsOraux,
    selNiceIds,
    token,
    sessionId,
    setIsHelpDialogOpen,
    isPrescriptionsDialogOpen,
    setIsPrescriptionsDialogOpen,
  } = useContext(AppContext);
  const location = useLocation();
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isThanksModalOpen, setIsThanksModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMainSettingsDialogOpen, setIsMainSettingsDialogOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);

  async function onThumbsClick(didLike: boolean) {
    const isDataSent = await sendLikeDislikeFeedback(
      sessionId,
      didLike,
      selNiceIds,
      token,
      location.pathname,
      selMedsOraux,
    );
    setAreThumbsDisabled(true);

    if (isDataSent) {
      setIsThanksModalOpen(true);
      setTimeout(() => setIsThanksModalOpen(false), 3500);
    } else {
      setIsErrorModalOpen(true);
      setTimeout(() => setIsErrorModalOpen(false), 3500);
    }
  }

  async function onSendMessage(
    comment: string,
    name: string | null,
    email: string | null,
    radioChoice: number,
  ): Promise<boolean> {
    const isDataSent = await sendCommentFeedback(
      sessionId,
      token,
      selNiceIds,
      radioChoice,
      comment,
      name,
      email,
      location.pathname,
      selMedsOraux,
    );

    if (isDataSent) {
      setIsThanksModalOpen(true);
      setTimeout(() => setIsThanksModalOpen(false), 3500);
    } else {
      setIsErrorModalOpen(true);
      setTimeout(() => setIsErrorModalOpen(false), 3500);
    }

    return isDataSent;
  }

  // UseMediaQuery to responsively set direction of tooltip
  const dir = useMediaQuery("(max-width: 768px)") ? "top" : "left";

  return (
    <div className={styles.main}>
      {/* Thumbs Up Button */}
      <IconButton
        className={styles.button}
        color="primary"
        onClick={() => onThumbsClick(true)}
        disabled={areThumbsDisabled}
      >
        <Tooltip placement={dir} title={i18n.t("Menu.Tooltips.ThumbsUp")}>
          <ThumbUpOffAltOutlinedIcon />
        </Tooltip>
      </IconButton>

      {/* Thumbs Down Button */}
      <IconButton
        className={styles.button}
        color="primary"
        disabled={areThumbsDisabled}
        onClick={() => onThumbsClick(false)}
      >
        <Tooltip placement={dir} title={i18n.t("Menu.Tooltips.ThumbsDown")}>
          <ThumbDownOffAltOutlinedIcon />
        </Tooltip>
      </IconButton>

      {/* Message Button */}
      <IconButton
        className={styles.button}
        color="primary"
        onClick={() => setIsMessageModalOpen(true)}
      >
        <Tooltip placement={dir} title={i18n.t("Menu.Tooltips.Message")}>
          <MessageOutlinedIcon />
        </Tooltip>
      </IconButton>

      {/* Settings button */}
      <IconButton
        className={styles.button}
        color="primary"
        onClick={() => setIsMainSettingsDialogOpen(true)}
        id="preferences-button"
      >
        <Tooltip placement={dir} title={i18n.t("Menu.Tooltips.Settings")}>
          <SettingsIcon />
        </Tooltip>
      </IconButton>

      {/* Instructions */}
      <IconButton
        className={styles.button}
        color="primary"
        onClick={() => setIsHelpDialogOpen(true)}
      >
        <Tooltip placement={dir} title={i18n.t("Menu.Tooltips.Instructions")}>
          <HelpOutlineIcon />
        </Tooltip>
      </IconButton>

      {/* Gérer */}
      <IconButton
        className={styles.button}
        color="primary"
        onClick={() => setIsPrescriptionsDialogOpen(true)}
        id="prescription-icon"
      >
        <Tooltip placement={dir} title={i18n.t("Menu.Tooltips.Manage")}>
          <DescriptionIcon />
        </Tooltip>
      </IconButton>

      {/* Modals */}
      <CommentFeedbackModal
        isOpen={isMessageModalOpen}
        setIsOpen={setIsMessageModalOpen}
        // eslint-disable-next-line react/jsx-no-bind
        onSendMessage={onSendMessage}
      />

      {/* Logout */}
      <IconButton
        className={styles.logout}
        onClick={() => handleLogout()}
        color="primary"
        id="logout-button"
      >
        <Tooltip placement={dir} title={i18n.t("Menu.Tooltips.Logout")}>
          <LogoutIcon />
        </Tooltip>
      </IconButton>

      <ThanksFeedbackModal isOpen={isThanksModalOpen} />
      <ErrorFeedbackModal isOpen={isErrorModalOpen} />
      <SettingMainDialog
        isOpen={isMainSettingsDialogOpen}
        setIsOpen={(open: boolean) => setIsMainSettingsDialogOpen(open)}
        setRecompute={setRecompute}
      />
      <PrescriptionsDialog
        isOpen={isPrescriptionsDialogOpen}
        setIsOpen={(open: boolean) => setIsPrescriptionsDialogOpen(open)}
      />
      <ErrorJsonModal isOpen={warningOpen} setIsOpen={setWarningOpen} />

      <HelpDialog />
    </div>
  );
}
