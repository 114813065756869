import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ClearIcon from "@mui/icons-material/Clear";

import DialogTitle from "@mui/material/DialogTitle";
import styles from "./PrescriptionsDialog.module.css";
import ListePresciptionsInjectables from "../ListePresciptionsInjectables/ListePresciptionsInjectables";
import ListeOralPresciptionsInjectables from "../ListeOralPresciptionsInjectables/ListeOralPresciptions";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function PrescriptionsDialog({ isOpen, setIsOpen }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={() => setIsOpen(false)} open={isOpen}>
      <div className={styles.clear}>
        <Button
          id="close"
          sx={{
            width: "40px",
            height: "40px",
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "var(--red)",
            "&:hover": {
              color: "var(--white)",
              backgroundColor: "var(--red)",
            },
          }}
          onClick={() => setIsOpen(false)}
        >
          <ClearIcon />
        </Button>
      </div>
      <DialogTitle sx={{ textAlign: "center" }}>
        {/* Utilisez la balise de traduction pour le titre */}
        {i18n.t("Menu.PrescriptionsDialog.title")}
      </DialogTitle>
      <ListePresciptionsInjectables isOpen={open} setIsOpen={setOpen} />
      <hr />
      <ListeOralPresciptionsInjectables isOpen={open} setIsOpen={setOpen} />
    </Dialog>
  );
}
