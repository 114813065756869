import { useContext } from "react";
import AppContext from "../../../AppContext";
import styles from "./DrugIncompatibilitiesRampes.module.css";
import IncompatibleDrugItemRampes from "../IncompatibleDrugItemRampes/IncompatibleDrugItemRampes";
import DrugItemIncompatibilitiesRampes from "../DrugItemIncompatibilitiesRampes/DrugItemIncompatibilitiesRampes";

type Props = {
  item: DrugOrSynonym;
  incompatibilities: IncompatibleDrug[];
  materialIncomps: IncompatibleDrug[];
  removeSelItem: SetNiceIds;
  incompApiData: IncompApiResp;
  setRecompute: (recompute: boolean) => void;
};

export default function DrugIncompatibilitiesRampes({
  item,
  incompatibilities,
  materialIncomps,
  removeSelItem,
  incompApiData,
  setRecompute,
}: Props) {
  const { showNoInfoIncomp, showCompatibilities, showCompMaterial } =
    useContext(AppContext);

  let incompFiltered = incompatibilities.concat(materialIncomps);
  if (!showNoInfoIncomp) {
    incompFiltered = incompFiltered.filter((incomp) => incomp.type_incomp !== "no_info");
  }
  if (!showCompatibilities) {
    incompFiltered = incompFiltered.filter(
      (incomp) => incomp.type_incomp !== "compatible",
    );
  }
  if (!showCompMaterial) {
    incompFiltered = incompFiltered.filter(
      (incomp) => incomp.type_incomp !== "incomp_material",
    );
  }
  const incompOrdered = incompFiltered.sort((a, b) => {
    const order = [
      "incompatibilities",
      "contradictory",
      "compatible",
      "no_info",
      "incomp_material",
    ];

    const aIndex = order.indexOf(a.type_incomp);
    const bIndex = order.indexOf(b.type_incomp);

    if (aIndex !== bIndex) {
      return aIndex - bIndex;
    }
    if (a.is_drugoptimal === true && b.is_drugoptimal !== true) {
      return -1;
    }
    if (b.is_drugoptimal === true && a.is_drugoptimal !== true) {
      return 1;
    }
    return 0;
  });

  let incompatibilitiesLayout;
  if (incompOrdered.length > 0) {
    incompatibilitiesLayout = incompOrdered.map((incompDrug) => {
      if (
        materialIncomps.length > 0 &&
        incompDrug.type_incomp === "incomp_material" &&
        showCompMaterial
      ) {
        return (
          <IncompatibleDrugItemRampes
            incompDrug={incompDrug}
            key={`material_${incompDrug.nice_id_target}_${incompDrug.material_id}`}
          />
        );
      }
      if (
        incompDrug.type_incomp === "no_info" &&
        (incompDrug.nice_id === "s-16587" || // d-532
          incompDrug.nice_id === "s-16572" || // d-517
          incompDrug.nice_id_target === "s-16587" || // d-532
          incompDrug.nice_id_target === "s-16572") // d-517
      ) {
        return null;
      }
      return (
        <IncompatibleDrugItemRampes incompDrug={incompDrug} key={incompDrug.nice_id} />
      );
    });
  } else {
    incompatibilitiesLayout = (
      <div className={styles.noIncompMessage}>
        Aucune incompatibilité trouvée pour {item.name}
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.drug}>
        <DrugItemIncompatibilitiesRampes
          removeSelItem={removeSelItem}
          item={item}
          drugOrSynonym={item}
          incompApiData={incompApiData}
          isSelected
          setRecompute={setRecompute}
        />
      </div>
      <div className={styles.incompatibilities}>{incompatibilitiesLayout}</div>
    </div>
  );
}
