import { useState, useContext, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { API_URL_USER_RULES, MAX_NUM_BLOCKS } from "../../../constants";
import styles from "./SettingMainDialog.module.css";
import AppContext from "../../../AppContext";
import SettingsDialog from "../SettingsDialog/SettingsDialog";
import SettingItemDisplay from "../SettingItemDisplay/SettingItemDisaplay";
import CompSwitch from "../CompSwitch/CompSwitch";
import OralSwitch from "../OralSwitch/OralSwitch";
import RuleSwitch from "../RuleSwitch/RuleSwitch";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  setRecompute: (recompute: boolean) => void;
};

const locales: { [key: string]: { title: string } } = {
  fr: { title: "FR" },
  en: { title: "EN" },
  // es: { title: "ES" },
  // it: { title: "IT" },
  // de: { title: "DE" },
};

export default function SettingMainDialog({ isOpen, setIsOpen, setRecompute }: Props) {
  // The ramp number of the setting to modify:
  const [modifySetting, setModifySetting] = useState<number | null>(null);
  const [hasBeenClosed, setHasBeenClosed] = useState(false);

  // Optimisation of requests: only post if has changed is true:
  const [hasChanged, setHasChanged] = useState(false);

  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (isOpen) {
      setHasChanged(false);
      setHasBeenClosed(false);
    }
  }, [isOpen]);

  const [isSettingDialogOpen, setIsSettingDialogOpen] = useState(false);
  const {
    settingSet,
    updateSettingSet,
    token,
    removeExactOptResults,
    setApplyBigsFirst,
  } = useContext(AppContext);

  const [rules, setRules] = useState<BlockRule[]>([]);

  const avaliableBlocks = Array.from(
    { length: MAX_NUM_BLOCKS - 1 },
    (_, index) => index + 2,
  );

  // A function which handles communication with the API when the close button is clicked.
  async function handleClose() {
    if (!hasBeenClosed) {
      if (hasChanged) {
        try {
          const response = await axios.post(API_URL_USER_RULES, settingSet.toJSON(), {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.status === 200) {
            settingSet.updateId(response.data);
            updateSettingSet();
          }
        } catch (e) {
          console.error(e);
        }
      }
      if (settingSet.changedOccured() || hasChanged) {
        setApplyBigsFirst(true);
      }
      removeExactOptResults();
      setIsOpen(false);
    }
  }

  useEffect(() => {
    const currentSetting = settingSet.selectByRamps(modifySetting);
    setRules(currentSetting ? currentSetting.rules : []);
  }, [modifySetting]);

  const regleLayout = (
    <div className={styles.regleArea}>
      <SettingItemDisplay
        setModifySetting={setModifySetting}
        setIsSettingDialogOpen={setIsSettingDialogOpen}
        setHasChanged={setHasChanged}
      />
    </div>
  );

  const [selectedOption, setSelectedOption] = useState("FR");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
    setDropdownOpen(false);

    i18n.changeLanguage(value);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        PaperProps={{
          sx: {
            height: "fit-content",
            width: isSmallScreen ? "100%" : "70%",
            maxWidth: isSmallScreen ? "100vw" : "600px",
            overflow: "auto",
            alignItems: "center",
            padding: 2,
            backgroundColor: "var(--light-grey)",
            margin: 1,
          },
        }}
        maxWidth={false}
      >
        <div className={styles.selectContainer}>
          <div className={styles.customSelect} onClick={toggleDropdown}>
            <div className={styles.selectedOption}>{selectedOption}</div>
            <div className={`${styles.arrow} ${isDropdownOpen ? styles.rotate : ""}`}>
              &#9660;
            </div>
          </div>
          {isDropdownOpen && (
            <ul className={styles.dropdownList}>
              {Object.keys(locales).map((locale) => (
                <li key={locale}>
                  <button
                    type="button"
                    className={
                      i18n.resolvedLanguage === locale
                        ? styles.option__active
                        : styles.option
                    }
                    onClick={() => handleOptionClick(locale)}
                  >
                    {locales[locale].title}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>

        <DialogTitle
          sx={{
            textAlign: "center",
          }}
        >
          {i18n.t("general.setting.title")}
        </DialogTitle>
        <DialogContent className={styles.main} id="setting-div">
          <div className={styles.content}>
            <CompSwitch setRecompute={setRecompute} />
            <RuleSwitch />
            <div className={styles.regleArea}>{regleLayout}</div>
            <Button
              onClick={() => {
                setModifySetting(null);
                setIsSettingDialogOpen(true);
              }}
              startIcon={<AddIcon />}
              variant="text"
              sx={{ alignSelf: "center" }}
              id="new-rule"
            >
              {i18n.t("general.setting.add-rule")}
            </Button>

            <OralSwitch setRecompute={setRecompute} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose()}
            color="warning"
            id="close-button-preferences"
          >
            {i18n.t("general.setting.close")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add or Modify Setting Dialog */}
      <SettingsDialog
        isOpen={isSettingDialogOpen}
        setIsOpen={setIsSettingDialogOpen}
        avaliableBlocks={avaliableBlocks.filter(
          (num) => !settingSet.rampNumbers.includes(num) || num === modifySetting,
        )}
        rules={rules}
        setRules={setRules}
        setHasChanged={setHasChanged}
        setting={settingSet.selectByRamps(modifySetting)}
      />
    </>
  );
}
