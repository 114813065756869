import axios from "axios";
import { API_URL_PRESCRIPTIONS_ORAL } from "../constants";

export default async function sendOralPrescriptions(
  nameValue: string | null,
  patientNameValue: string | null,
  prescriberNameValue: string | null,
  selItems: string[] | null,
  token: string | null,
  sessionId: string | null,
): Promise<boolean> {
  if (!token || !sessionId) return false;

  const url = API_URL_PRESCRIPTIONS_ORAL;
  const bodyReq = {
    name: nameValue,
    patient_name: patientNameValue,
    prescriber_name: prescriberNameValue,
    sel_items: selItems,
  };

  try {
    const response = await axios.post(url, bodyReq, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.status === 200;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return false;
  }
}
