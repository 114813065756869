import { useContext } from "react";
import { Tooltip } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AppContext from "../../../AppContext";
import styles from "./RuleSwitchIndividual.module.css";
import Settings from "../../../classes/Settings";

type Props = {
  setting: Settings;
};

export default function RuleSwitchIndividual({ setting }: Props) {
  const { settingSet, updateSettingSet } = useContext(AppContext);
  const handleSwitchChange = () => {
    setting.setActive(!setting.isActive);
    updateSettingSet();
    settingSet.saveActiveState();
  };

  return (
    <Tooltip
      placement="top"
      title={
        setting.isActive ? "Désactiver ce jeu de règles" : "Activer ce jeu de règles"
      }
    >
      <FormGroup className={styles.switch}>
        <FormControlLabel
          sx={{ display: "flex", justifyContent: "start", margin: 0 }}
          control={<Switch checked={setting.isActive} onChange={handleSwitchChange} />}
          label=""
          className={styles.label}
        />
      </FormGroup>
    </Tooltip>
  );
}
