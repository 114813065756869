import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function OfflineDialog({ isOpen, setIsOpen }: Props) {
  return (
    <Dialog open={isOpen}>
      <DialogTitle sx={{ textAlign: "center" }}>Pas de connexion Internet.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Connexion Internet lente ou inexistante. Vérifiez votre connexion et réessayez.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
