import IconButton from "@mui/material/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
import logo from "../../../images/logo_drugoptimal.png";
import i18n from "../../../i18n";

type Props = {
  rampShown: boolean;
  setRampShown: any;
};

export default function Header({ rampShown, setRampShown }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  // functions and state used to simulate a navigation for the mobile device:
  function handleClickResearch() {
    setRampShown(false);
    navigate("/");
  }

  function handleClickRamps() {
    setRampShown(true);
    navigate("/");
  }

  return (
    <div className={styles.main}>
      {/* Navigation buttons for a big screen hidden on mobile */}
      <div className={styles.button_list}>
        <IconButton
          onClick={() => navigate("/")}
          className={`${styles.btn}
          ${location.pathname === "/" ? styles.selected : styles.notSelected}`}
          type="button"
        >
          {i18n.t("general.Header.navInjectable")}
        </IconButton>

        <IconButton
          onClick={() => navigate("/others")}
          className={`${styles.btn}
          ${location.pathname === "/others" ? styles.selected : styles.notSelected}`}
          type="button"
          id="others"
        >
          {i18n.t("general.Header.navOral")}
        </IconButton>
      </div>
      <div className={styles.title}>e-Harvis</div>

      <img className={styles.logo} src={logo} alt="" />
      {/* Navigation buttons for a small screen hidden on laptop */}
      <div className={styles.navbuttons}>
        {/* Research button */}
        <IconButton
          onClick={() => handleClickResearch()}
          className={`${styles.btn}
          ${
            location.pathname === "/" && !rampShown ? styles.selected : styles.notSelected
          }`}
          type="button"
        >
          Injectables
        </IconButton>

        {/* Ramps button */}
        <IconButton
          onClick={() => handleClickRamps()}
          className={`${styles.btn}
          ${
            location.pathname === "/" && rampShown ? styles.selected : styles.notSelected
          }`}
          type="button"
        >
          Rampes
        </IconButton>

        {/* Other forms button */}
        <IconButton
          onClick={() => navigate("/others")}
          className={`${styles.btn}
          ${location.pathname === "/others" ? styles.selected : styles.notSelected}
          ${styles.btn_other}`}
          type="button"
          id="others"
        >
          Formes Orales
        </IconButton>
      </div>
    </div>
  );
}
