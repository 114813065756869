import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useRef } from "react";
import axios, { AxiosError } from "axios";
import styles from "./AuthModal.module.css";
import { API_URL_LOGIN } from "../../../constants";

type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  setToken: (token: string) => void;
  setSessionId: (token: string) => void;
  setSettingList: (liste: any[]) => void;
};

export default function AuthModal({
  isOpen,
  setIsOpen,
  setToken,
  setSessionId,
  setSettingList,
}: Props) {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);
  const sendBtnRef = useRef<HTMLButtonElement | null>(null);
  const [userInput, setUserInput] = useState("");
  const [pswdInput, setPswdInput] = useState("");

  async function handleSendAuth() {
    // Disable button
    if (!sendBtnRef.current) {
      return;
    }
    // Disable button
    sendBtnRef.current.disabled = true;
    // Send request
    const url = API_URL_LOGIN;

    // If response 401 then add error message
    try {
      const response = await axios.post(url, {
        user: userInput,
        password: pswdInput,
      });
      if (response.status === 200) {
        setSettingList(response.data.settings);
        setToken(response.data.token);
        localStorage.setItem("token", response.data.token);
        setSessionId(response.data.session_id);
        localStorage.setItem("session_id", response.data.session_id);
        setIsOpen(false);
      }
    } catch (e) {
      const error = e as AxiosError<{ detail: string }>;
      setIsError(true);
      if (error.response?.data?.detail) {
        setErrorMsg(error.response.data.detail);
      } else {
        setErrorMsg("Erreur serveur");
      }
    }
    // Enable button
    sendBtnRef.current.disabled = false;
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleSendAuth();
    }
  };

  return (
    <Dialog open={isOpen} onKeyDown={handleKeyPress}>
      <DialogTitle>Veuillez vous authentifier</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <FormControl>
          <FormLabel>Utilisateur</FormLabel>
          <TextField
            error={isError}
            type="text"
            variant="standard"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            id="username"
          />
        </FormControl>
        <FormControl style={{ marginTop: "20px" }}>
          <FormLabel>Mot de Passe</FormLabel>
          <TextField
            type="password"
            variant="standard"
            value={pswdInput}
            onChange={(e) => setPswdInput(e.target.value)}
            error={isError}
            helperText={errorMsg}
            id="password"
          />
        </FormControl>
        <DialogActions>
          <Button ref={sendBtnRef} onClick={() => handleSendAuth()} id="send-auth-button">
            Envoyer
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
