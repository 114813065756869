import axios from "axios";
import { API_URL_SCAN_PRESCRIPTIONS_TEXT } from "../constants";

export default async function sendScanPresctiptionText(
  type: "injectable" | "oral",
  text: string | null,
  token: string | null,
  sessionId: string | null,
): Promise<string[] | null> {
  if (!token || !sessionId) return null;

  const url = API_URL_SCAN_PRESCRIPTIONS_TEXT;
  const bodyReq = {
    type,
    text,
  };

  try {
    const response = await axios.post(url, bodyReq, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      const responseData = response.data; // Les données de réponse que vous souhaitez récupérer
      return responseData;
    }
    return null;
  } catch (err) {
    return null;
  }
}
