import axios from "axios";
import { API_URL_TRACK_SEL_ITEM } from "../constants";

export default async function sendTrackSelItem(
  selNiceIds: string[],
  filterTextStack: string[],
  selNiceId: string,
  token: string | null,
  sessionId: string | null,
): Promise<boolean> {
  if (!token || !sessionId) return false;

  const url = API_URL_TRACK_SEL_ITEM;
  const bodyReq = {
    curr_nice_ids: selNiceIds,
    typing_history: filterTextStack,
    selected_nice_id: selNiceId,
    session_id: sessionId,
  };

  try {
    const response = await axios.post(url, bodyReq, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.status === 200;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return false;
  }
}
